import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { EducationalComponent } from './educational.component';
import { EducationalRoutingModule } from './educational-routing.module';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  declarations: [
    EducationalComponent
  ],
  imports: [
    TranslateModule,
    CommonModule,
    EducationalRoutingModule,
    MatTabsModule
  ],
})
export class EducationalModule { }
