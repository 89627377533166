export const seoSitemap: ISitemapTag[] = [
  {
    customUrl: '/',
    title: 'Blacksmith Italia - Attrezzi per la mascalcia equina',
    description: 'Blacksmith è uno dei principali distributori di ferri per cavalli, forge e altri utensili e punto di riferimento per la formazione del maniscalco.',
    image: 'https://blacksmithitalia.it/assets/img/FondoBg.jpg',
    keywords: 'Ferri da cavallo, attrezzatura per maniscalco, ferratura cavalli, attrezzatura per mascalcia, Mustad, ferri per cavalli'
  },
  {
    customUrl: '/home',
    title: 'Blacksmith Italia - Attrezzi per la mascalcia equina',
    description: 'Blacksmith è uno dei principali distributori di ferri per cavalli, forge e altri utensili e punto di riferimento per la formazione del maniscalco.',
    image: 'https://blacksmithitalia.it/assets/img/FondoBg.jpg',
    keywords: 'Ferri da cavallo, attrezzatura per maniscalco, ferratura cavalli, attrezzatura per mascalcia, Mustad, ferri per cavalli'
  },
  {
    customUrl: '/chi-siamo',
    title: 'Blacksmith Italia - Passione per i cavalli dal 1996',
    description: 'L’avventura ha inizio nel 1996 quando i due proprietari Umberto Erba e Marco Pellegrinelli effettuano un viaggio negli USA... leggi tutta la storia.',
    image: 'https://blacksmithitalia.it/assets/img/FondoBg.jpg',
    keywords: 'Ferri da cavallo, attrezzatura per maniscalco, ferratura cavalli, attrezzatura per mascalcia, Mustad, ferri per cavalli'
  },
  {
    customUrl: '/prodotti',
    title: 'Blacksmith - Attrezzi per maniscalco, ferri da cavallo',
    description: 'Blacksmith è uno dei principali distributori di ferri per cavalli, forge e utensili: scopri i prodotti sul nostro sito e acquistali in pochi clic.',
    image: 'https://blacksmithitalia.it/assets/img/FondoBg.jpg',
    keywords: 'Ferri da cavallo, attrezzatura per maniscalco, ferratura cavalli, attrezzatura per mascalcia, Mustad, ferri per cavalli'
  },
  {
    customUrl: '/marche',
    title: 'Blacksmith Italia - Scopri i nostri rivenditori',
    description: 'Rivendita di utensili e attrezzi per mascalcia e per la cura del cavallo. Scopri tutti i rivenditori.',
    image: 'https://blacksmithitalia.it/assets/img/FondoBg.jpg',
    keywords: ''
  },
  {
    customUrl: '/contatti',
    title: 'Blacksmith Italia - I nostri riferimenti e dove siamo',
    description: 'Blacksmith ha sede a Ciserano, in provincia di Bergamo, contattaci per avere maggiori informazioni sui nostri prodotti!',
    image: 'https://blacksmithitalia.it/assets/img/FondoBg.jpg',
    keywords: 'Ferri da cavallo, attrezzatura per maniscalco, ferratura cavalli, attrezzatura per mascalcia, Mustad, ferri per cavalli'
  },
  {
    customUrl: '/educational',
    title: 'Blacksmith Educational - Tutorial e video di formazione',
    description: 'Video corsi per maniscalchi forniti da Blacksmith Italia, dal 1996 punto di riferimento per la formazione di questo mestiere duraturo.',
    image: 'https://blacksmithitalia.it/assets/img/FondoBg.jpg',
    keywords: 'Ferri da cavallo, attrezzatura per maniscalco, ferratura cavalli, attrezzatura per mascalcia, Mustad, ferri per cavalli'
  }
  ,
  {
    customUrl: '/news',
    title: 'Blacksmith News - Ultime novità del settore, comunicati',
    description: 'Resta aggiornato con tutte le novità dal mondo Blacksmith e dal settore della mascalcia equina, leggi gli articoli online sul nostro sito.',
    image: 'https://blacksmithitalia.it/assets/img/FondoBg.jpg',
    keywords: 'Ferri da cavallo, attrezzatura per maniscalco, ferratura cavalli, attrezzatura per mascalcia, Mustad, ferri per cavalli'
  }

];

export interface ISitemapTag {
  customUrl: string;
  title: string | null;
  description: string | null;
  keywords: string | null;
  image: string | null;
}
