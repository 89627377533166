<div class="result-page">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 offset-lg-3">
        <div class="main-carrello">
          <h4 class="title text-danger">{{ "ResultOrder.section1.titleError" | translate }}</h4>
          <p class="my-4"><b class="text-danger">{{ "ResultOrder.section1.texterror1" | translate }}</b><br>
            <!-- {{ "ResultOrder.section1.texterror2" | translate }} -->
            </p>
          <a class="btn btn-bg" routerLink="/">{{ "ResultOrder.section1.btn" | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
