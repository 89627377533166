import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderRoutingModule } from './header-routing.module';
import { HeaderComponent } from './header.component';
import { MenuModule } from '../menu/menu.module';
import { FormsModule } from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [
    HeaderComponent
  ],
  imports: [
    CommonModule,
    HeaderRoutingModule,
    MenuModule,
    FormsModule,
    TranslateModule,
    LazyLoadImageModule,
  ],
  exports:[
    HeaderComponent
  ]
})
export class HeaderModule { }
