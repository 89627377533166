<section class="bg-section-grey account" style="min-height: 700px;">
  <div class="container">
    <div class="row">
      <div class="col-lg-10 offset-lg-1">
        <h2 class="title">{{'Account.section1.title' | translate}}</h2>
        <div class="row mt-4">
          <div class="col-lg-6">
            <div class="main-carrello p-4 p-xl-5">
              <div class="product-carrello" *ngIf="recPass==false && IsTemporary==false">
                <h5 class="mt-0 mb-4">{{'Account.section1.login' | translate}}</h5>
                <input type="text" class="mb-4" placeholder="Email" [(ngModel)]="username" autocomplete="new-password"
                  required>
                <div class="position-relative mb-3">
                  <input [type]="fieldTextType ? 'text' : 'password'" placeholder="Password" [(ngModel)]="password"
                    required (keydown.enter)="p=true;Login()" autocomplete="new-password">
                  <mat-icon class="aps-icon" (click)="fieldTextType = !fieldTextType;icon=!icon"
                    style="cursor: pointer">
                    {{icon?'visibility_off':'visibility'}}</mat-icon>
                </div>
                <a class="m-3" *ngIf="logindisabled==false" (click)="recPass=true">{{'Account.section1.password' |
                  translate}}</a>
                <div class="d-flex flex-column">
                  <button type="submit" class="btn btn-bg mt-4" (click)="p=true;Login()"
                    [disabled]="username=='' || password==''">
                    <ng-container *ngIf="p==false">LOGIN</ng-container>
                    <mat-spinner *ngIf="p==true" class="mx-auto" [diameter]="30"></mat-spinner>
                  </button>
                  <div class="alert alert-danger mt-3" role="alert" *ngIf="loginError==true">
                    {{'Account.section1.alert' | translate}}
                  </div>
                  <div class="alert alert-danger mt-3" role="alert" *ngIf="logindisabled==true">
                    {{'Account.disable' | translate}}
                  </div>
                </div>
              </div>
              <div class="product-carrello" *ngIf="recPass==true && IsTemporary==false">
                <h5 class="mt-0 mb-4">{{'Account.section1.password' | translate}}</h5>
                <div *ngIf="recPassMsg==false">
                  <p>{{'Account.section1.recpass' | translate}}</p>
                  <form>
                    <div class="form-group">
                      <input type="email" name="emailR" [(ngModel)]="emailR" placeholder="Email*" required>
                    </div>
                    <div class="d-flex flex-column mt-4">
                      <button type="submit" class="btn btn-bg" (click)="k=true;recePass()" [disabled]="emailR==''">
                        <span *ngIf="k==false">{{'Account.section1.invia' | translate}}</span>
                        <mat-spinner *ngIf="k==true" class="mx-auto" [diameter]="30"></mat-spinner>
                      </button>
                      <div class="alert alert-danger mt-3" role="alert" *ngIf="errorRecePAss.length!=0">
                        {{errorRecePAss.error?.Error}}
                      </div>
                      <button type="submit" class="btn btn-w-border mt-3"
                        (click)="recPass=false">{{'Account.section1.annulla' | translate}}
                      </button>
                    </div>
                  </form>
                </div>
                <h4 *ngIf="recPassMsg==true">{{'Account.section1.resultrecupero' | translate}}</h4>
                <mat-spinner *ngIf="recPassMsg==true" class="mx-auto mt-3 recpassloader" [diameter]="30"></mat-spinner>
              </div>
              <div class="product-carrello" *ngIf="IsTemporary==true">
                <p class="mt-4">{{'Account.section1.accessoeffettuato' | translate}}</p>
                <div class="form-group mt-4">
                  <div class="position-relative mb-0">
                    <input [type]="fieldTextType ? 'text' : 'password'" [(ngModel)]="Pass" required
                      placeholder="Nuova Password*" autocomplete="new-password"
                      pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9]).{6,}"
                      (input)="checkPass();RipetiPass!=''?checkPassReapet():1==1">
                    <small *ngIf="errorPass!=''" class="text-danger">{{errorPass}}</small>
                    <mat-icon class="aps-icon" (click)="fieldTextType = !fieldTextType;icon=!icon"
                      style="cursor: pointer">
                      {{icon?'visibility_off':'visibility'}}</mat-icon>
                  </div>
                </div>
                <div class="form-group mt-3">
                  <div class="position-relative mb-0">
                    <input [type]="fieldTextType2 ? 'text' : 'password'" [(ngModel)]="RipetiPass" required
                      placeholder="Ripeti la Password*" autocomplete="new-password" (input)="checkPassReapet()">
                    <small *ngIf="errorPassReapet!=''" class="text-danger">{{errorPassReapet}}</small>
                    <mat-icon class="aps-icon" (click)="fieldTextType2 = !fieldTextType2;icon2=!icon2"
                      style="cursor: pointer">
                      {{icon2?'visibility_off':'visibility'}}</mat-icon>
                  </div>
                    <button type="submit" class="btn btn-bg mt-4" (click)="SaveCredential()">
                      {{'Account.section1.conferma' | translate}}
                    </button>
                    <button type="submit" class="btn btn-w-border mt-3" (click)="logout()()">
                      {{'Account.section1.annulla' | translate}}
                    </button>


                  <small class="text-danger text-center m-0 mt-3 d-block"
                    *ngIf="errorCr==true">{{'Account.section1.datiobbligatori' | translate}}</small>
                  <div class="alert alert-success mt-3" role="alert" *ngIf="msg==true">
                    {{'Register.section1.result' | translate}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6" *ngIf="IsTemporary!=true">
            <div class="main-carrello p-4 p-xl-5">
              <div class="product-carrello">
                <h5 class="m-0">{{'Account.section1.newcliente' | translate}}</h5>
                <button class="btn btn-bg mt-4" (click)="toRegister()">{{'Account.section1.registrati' |
                  translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
