import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  constructor(public service: ServiceService,private route: ActivatedRoute,private title: Title,private metaService: Meta) { }

  ngOnInit(): void {
          this.GetTotalblogs();
          this.GetCategoriesblogs();
  }

  totalArticle = 0;
  searchActivated: boolean = false;
  searchValue = '';
  dateFiltred='desc';
  idBlog=null;
  currentYear=new Date().getFullYear();
  yearSelected ='';

  search='';

  Listnews:any=[];
  NumBlogCategory:any=[];
  totalblogs=0;
  GetTotalblogs() {
    this.totalblogs=0;
    this.service.GetNumBlogCategory(this.blogCat,0,150,this.yearSelected,this.dateFiltred,this.searchValue).pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.NumBlogCategory = res;
        this.totalblogs=this.NumBlogCategory[0]?.TotalBlog;
        if ((this.totalblogs / this.pageSize) % 1 != 0) {
          this.pageLimit = Math.trunc(this.totalblogs / this.pageSize) + 1;
        } else {
          this.pageLimit = 1;
          this.pageIndex = 1;
        }
        this.GetNews();
        this.loader = false;
      }
    )
  }

  GetNews(){
    this.service.GetBlogs(this.blogCat,this.Offset, this.pageSize,this.yearSelected,this.dateFiltred,this.searchValue).pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.Listnews = res;
      }
    )
  }

  Categoriesblogs:any=[];
  blogCat='';
  GetCategoriesblogs() {
    this.service.GetCategoriesblogs().pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.Categoriesblogs = res;
      }
    )
  }



  pageIndex: number = 1;
  pageLimit: number = 0;
  pageSize: number = 8;
  Offset: number = 0;
  Limit: number = 8;
  loader:boolean=false;

  nextPage() {
    if (this.pageIndex < this.pageLimit) {
      this.pageIndex += 1;
      this.Offset = this.Offset + this.pageSize;
      console.log(this.Offset)
      this.loader = true;
      this.service.GetBlogs(this.blogCat,this.Offset, this.pageSize,this.yearSelected,this.dateFiltred,this.searchValue).pipe(
        catchError(error => {
          return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
        })
      ).subscribe(
        res => {
          this.Listnews = res;
          this.loader=false;
        }
      )
          window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
  }
  previousPage() {
    if (this.pageIndex > 1) {
      this.pageIndex -= 1
      this.Offset = this.Offset - this.pageSize;
      this.loader = true;
      this.service.GetBlogs(this.blogCat,this.Offset, this.pageSize,this.yearSelected,this.dateFiltred,this.searchValue).pipe(
        catchError(error => {
          return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
        })
      ).subscribe(
        res => {
          this.Listnews = res;
          this.loader=false;
        }
      )
          window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
  }

  changeSizePage(i) {
    this.pageSize = parseInt(i);
    this.pageIndex = 1;
    if ((this.totalblogs / this.pageSize) % 1 != 0) {
      this.pageLimit = Math.trunc(this.totalblogs / this.pageSize) + 1;
    }
    this.GetNews();
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }



}
