<section class="bg-section pt-4" *ngIf="!disponible">
  <div class="container">
    <div class="main-product mt-4">
      <div class="text-center">
        <img src="assets/img/404.png" alt="not found" height="90">
        <p class="mt-4 mb-2 fw-b">CATEGORIA</p>
        <h2>NON TROVATA</h2>
        <p class="fw-b">la categoria che stai cercando non è disponibile.</p>
      </div>
      <div class="separate my-4"></div>
      <div class="box">
        <h2 class="title">{{'Home.section2.title' | translate}}</h2>
        <!-- <img loading="lazy" class="d-block mt-4 mx-auto" width="160" height="160" *ngIf="Evidenza.length==0"
          src="assets/img/reload.gif" alt="loader horse"> -->
        <carousel [lightDOM]="'true'" [height]="389" [cellWidth]="290" [dots]="false" [arrows]="true"
          [arrowsOutside]="false" [loop]="true" class="mt-5 couselx" *ngIf="showcarousel">

          <ng-container *ngIf="Evidenza.length==0">
            <div class="carousel-cell pt-2" *ngFor="let item of fakelist">
              <a class="d-flex flex-column card-s" style="border:none !important">
                <div class="placeholderimg"></div>
                <div class="prod-desc bg-white">
                  <span class="placeholder sml col-4 mb-3"></span>
                  <span class="placeholder sml mb-1"></span>
                  <span class="placeholder sml"></span>
                  <div class="d-flex justify-content-between">
                    <span class="placeholder col-5"></span>
                    <span class="placeholder col-5"></span>
                  </div>
                </div>
              </a>
            </div>
          </ng-container>

          <div class="carousel-cell pt-2" *ngFor="let item of Evidenza">
            <a class="d-flex flex-column card-s mr-3 ml-1"
              routerLink="{{(service.currentLang=='en'?'/en':'') + '/prodotto/' +item.Id}}">
              <div class="ribbon" *ngIf="item.Discount!=null && item.Discount!=0">
                <span>{{item.Discount}}{{item.IsPercentage==true?'%':'€'}}
                  <small>{{ "CategorieDettagli.section1.discount" | translate}}</small></span>
              </div>
              <!-- <img  loading="lazy"  src="assets/img/b.png" class="imgb" alt="brand"> -->
              <img [lazyLoad]="'assets/img/new.svg'" class="imgnew" alt="new" *ngIf="item?.NewWeb==true">
              <img [lazyLoad]="item?.urlImages[0]" *ngIf="item.urlImages?.length!=0 && item.urlImages!=null"
                alt="{{item.Title}}">
              <img [lazyLoad]="'assets/no-image.png'"
                *ngIf="!item.urlImages || item.urlImages?.length==0 || item.urlImages==null" alt="blacksmith italia">
              <div class="prod-desc">
                <span class="cat">{{item.Code}}</span>
                <span class="desc">{{item.Title}}</span>
                <div class="mt-auto m-price">
                  <h5 *ngIf="!item.StopSellWeb">
                    <ng-container *ngIf="item.haveChildren"> {{ "CategorieDettagli.section1.da" | translate
                      }}</ng-container>
                    {{item.Price | currency : "EUR" : "symbol" : "1.2-2" : "it" }}
                    <small class="soldp" *ngIf="item.Discount!=null && item.Discount!=0">{{item.OriginalPrice | currency :
                      "EUR" : "symbol" :
                      "1.2-2" : "it" }}</small>
                    <span class="ml-3 text-nowrap">{{"CategorieDettagli.section1.iva" | translate }}</span>
                  </h5>
                  <h5 *ngIf="item.StopSellWeb">
                    {{'ProdDet.section1.richiediInformazioni' | translate}}
                  </h5>
                </div>
              </div>
              <div class="shop-btns">
                <button>
                  +
                </button>
              </div>
            </a>
          </div>
        </carousel>
      </div>
    </div>
  </div>
</section>

<div class="row align-items-stretch" style="background-color: #ebecf7" *ngIf="disponible">
  <div class="w-100 d-flex justify-content-center align-items-center img-h-c"
    [ngStyle]="{'background-image': 'url(' + mainPicture + ')'}">
    <h1 class="title-bg">{{title}}</h1>
  </div>
  <!-- <div class="col-md-6 col-lg-8 d-none d-md-block">
    <div class="descxx m-4" [innerHTML]="desc"></div>
  </div> -->
</div>
<section class="bg-section pt-2 pt-lg-4" style="min-height: 70vh;" *ngIf="disponible">
  <div class="container">
    <div class="d-flex flex-wrap">
      <div class="filter">
        <div class="infocollapse">
          <div class="d-flex align-items-center menu-cat mt-2 mb-3">
            <h3 routerLink="{{service.currentLang=='en'?'/en/prodotti':'/prodotti'}}" class="d-flex mb-0"><img loading="lazy"
                src="assets/img/arrow-l.svg" alt="arrow" class="mr-2" width="8">{{'menu.all' | translate}}</h3>
                <span class="result ml-auto d-block d-xl-none"><b>{{totalProducts}}</b> {{ "CategorieDettagli.section1.result" | translate}}
                </span>
          </div>
          <div class="mt-2 mt-lg-4 cat-s-responsive">
            <a class="s-c mr-2" style="font-weight: 600;"
              routerLink="{{(service.currentLang === 'en' ? '/en/categoria/' : '/categoria/') + IdCat }}">{{categoryLevel1[0]?.Description}}</a>
            <ul>
              <li *ngFor="let item of categoryLevel1[0]?.ListGroups" class="nav-item dropdown">
                <a class="s-c mr-2" [ngClass]="{'text-bold': item.Id==IdSousCat}"
                  routerLink="{{(service.currentLang === 'en' ? '/en/categoria/' : '/categoria/') + item.Id}}">{{item.Description}}</a>
                <div class="dropdown-menu dropdownmenux" aria-labelledby="navbarDropdownMenuLink"
                  *ngIf="item.ListGroups.length!=0">
                  <ul>
                    <li *ngFor="let soucat of item.ListGroups" class="dropdown">
                      <a class="dropdown-item navlinkx dropdown-toggle" id="navbarDropdownMenuLink2"
                        routerLink="{{(service.currentLang === 'en' ? '/en/categoria/' : '/categoria/') + soucat.Id}}">
                        {{soucat.Description}}</a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="content-p">
        <div class="d-flex flex-wrap align-items-center main-f">
          <span class="result mxw mr-auto d-none d-xl-block"><b>{{totalProducts}}</b> {{ "CategorieDettagli.section1.result" | translate}}
          </span>

          <div class="container-search mr-0 mr-md-3 d-none d-xl-block" (click)="searchActivated=true"
            [ngClass]="{'active': searchActivated==true}">
            <input type="text" placeholder="Search..." [(ngModel)]="searchValue" (keyup.enter)="GetProducts()">
            <span class="search" (click)="GetProducts();"></span>
          </div>

          <select (change)="goTo(toSousCat)" [(ngModel)]="toSousCat" class="s-c-list mt-0 mt-xl-2 mb-0">
            <option value="null">{{'CategorieDettagli.section1.select-sottoc' | translate}}</option>
            <option *ngFor="let item of categoryLevel1[0]?.ListGroups" value="/categoria/{{item.Id}}">
              {{item.Description}}</option>
          </select>

          <select class="w-auto mb-0 mr-0 mr-md-2 mt-2 mt-lg-0" [(ngModel)]="priceFilter" (ngModelChange)="filtri()">
            <option value="">{{'CategorieDettagli.section1.Filtri' | translate}}</option>
            <option value="asc">{{'CategorieDettagli.section1.przCresc' | translate}}</option>
            <option value="desc">{{'CategorieDettagli.section1.przDecresc' | translate}}</option>
          </select>

          <select class="w-auto mb-0 ml-1 ml-xl-0 mt-2 mt-lg-0" [(ngModel)]="brandSelected" *ngIf="Brands?.length!=0"
            (ngModelChange)="GetTotalProducts();GetProducts();">
            <option value="null">{{ "CategorieDettagli.section1.opt" | translate }}</option>
            <ng-container *ngFor="let b of Brands">
              <option [value]="b.Id" *ngIf="b.Disabled==false">{{b.Description}}</option>
            </ng-container>
          </select>
          <button class="ml-0 ml-lg-2 mt-3 mt-lg-0 btn btn-bg" *ngIf="brandSelected!='null' || searchValue!=''"
            (click)="orderPriceAsc=false;orderPriceDesc=false;brandSelected='null';searchValue='';GetTotalProducts();GetProducts()">{{
            "CategorieDettagli.section1.mostratutto" | translate }}</button>
        </div>

        <div class="d-flex flex-wrap my-4 justify-content-between justify-content-sm-start">
          <span *ngIf="products?.length==0 && loader==false">{{ "CategorieDettagli.section1.noprod" | translate }}</span>

          <ng-container *ngIf="loader">
            <a class="d-flex flex-column card-s" style="border:none !important" *ngFor="let item of fakelist">
              <div class="placeholderimg"></div>
              <div class="prod-desc bg-white">
                <span class="placeholder mt-3"></span>
                <span class="placeholder sml mt-2"></span>
                <div class="d-flex justify-content-between mt-3">
                  <span class="placeholder col-5"></span>
                  <span class="placeholder col-5"></span>
                </div>
              </div>
            </a>
          </ng-container>

          <a class="d-flex flex-column card-s" *ngFor="let item of products"
            routerLink="{{(service.currentLang=='en'?'/en':'') + '/prodotto/'+ item.Id}}"
            routerLinkActive="active" ariaCurrentWhenActive="page">
            <div class="ribbon" *ngIf="item.Discount!=null && item.Discount!=0">
              <span>{{item.Discount}}{{item.IsPercentage==true?'%':'€'}}
                <small>{{ "CategorieDettagli.section1.discount" | translate}}</small></span>
            </div>
            <img src="assets/img/new.svg" class="imgnew" alt="new" *ngIf="item?.NewWeb==true">
            <!-- <img loading="lazy" src="assets/img/b.png" class="imgb"> -->
            <img [lazyLoad]="item?.urlImages[0]" *ngIf="item.urlImages?.length!=0 && item.urlImages!=null"
              alt="{{item.Title}}">
            <img loading="lazy" src="assets/no-image.png"
              *ngIf="!item.urlImages || item.urlImages?.length==0 || item.urlImages==null" alt="blacksmith italia">
            <div class="prod-desc">
              <span class="desc">{{item.Title}}</span>
              <span class="p-cat">{{item.Code}} - {{title}}</span>
              <div class="m-price">
                <h5 *ngIf="!item.StopSellWeb">
                  <ng-container *ngIf="item.haveChildren"> {{ "CategorieDettagli.section1.da" | translate
                    }}</ng-container>
                  {{item.Price | currency : "EUR" : "symbol" : "1.2-2" : "it" }}
                  <small class="soldp" *ngIf="item.Discount!=null && item.Discount!=0">{{item.OriginalPrice | currency :
                    "EUR" : "symbol" :
                    "1.2-2" : "it" }}</small>
                  <span class="ml-3 text-nowrap">{{"CategorieDettagli.section1.iva" | translate }}</span>
                </h5>
                <h5 *ngIf="item.StopSellWeb">
                  {{'ProdDet.section1.richiediInformazioni' | translate}}
                </h5>
              </div>
            </div>
            <div class="shop-btns">
              <button>
                +
              </button>
            </div>
          </a>
        </div>

        <div class="d-flex flex-wrap justify-content-center">
          <img loading="lazy" class="d-block mb-4 mx-auto" width="90" height="90" *ngIf="loaderNext==true"
            src="assets/img/reload.gif" alt="loader horse">
        </div>

        <div class="d-flex flex-wrap justify-content-center">
          <button class="btn btn-w" (click)="nextPage()"
            *ngIf="pageIndex!=pageLimit && productleft!=0 && loaderNext==false && loader==false"
            style="background-color: #ebecf7;color:#000">{{"CategorieDettagli.section1.mostradipiu" | translate}}
            ({{productleft}}) <img src="assets/img/arrow-bottom.svg" class="ml-3" width="17" alt="arrow-bottom"></button>
        </div>


      </div>
    </div>
  </div>
</section>
