<section class="bg-section-grey" style="min-height: 65vh">
  <div class="container">
    <div class="row">
      <div class="col-lg-5">
        <div class="main-carrello p-4 px-xl-5">
          <div class="product-carrello">
            <h2 class="title mb-4">{{ "Pagamento.section1.title" | translate }}</h2>
            <h6 class="mt-0 mb-2">{{ "Pagamento.section1.dati" | translate }}</h6>
            <div class="row">
              <div class="col-6">
                <label>{{ "Pagamento.section1.nome" | translate }}:</label>
                <span> {{nome}}</span>
              </div>
              <div class="col-6">
                <label>{{ "Pagamento.section1.cognome" | translate }}:</label>
                <span> {{cognome}}</span>
              </div>
              <div class="col-6">
                <label>{{ "Pagamento.section1.email" | translate }}:</label>
                <span> {{email}}</span>
              </div>
              <div class="col-6">
                <label>{{ "Pagamento.section1.tel" | translate }}:</label>
                <span> {{tel}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="main-carrello tot">
          <div class="product-carrello totale">

            <div class="justify-content-between align-items-center mb-3 d-none d-md-flex" *ngFor="let item of carrello">
              <p class="text-lowercase mb-0" style="line-height: 17px !important">{{item.qte}} {{item.Title}}</p>
              <p class="mb-0">{{item.VatPrice * item.qte | currency : "EUR" : "symbol" : "1.2-2" : "it" }}</p>
            </div>
            <div class="separate d-none d-md-block"></div>

            <div class="d-flex justify-content-between mt-2">
              <p>{{ "Carrello.section1.subtotal" | translate }}</p>
              <p>{{TotalCarrello + TotalSconte | currency : "EUR" : "symbol" : "1.2-2" : "it" }}</p>
            </div>
            <div class="d-flex justify-content-between" *ngIf="TotalSconte!=0">
              <p>{{ "Carrello.section1.subtotalsconto" | translate }}</p>
              <p class="text-success">-{{TotalSconte | currency : "EUR" : "symbol" : "1.2-2" : "it" }}</p>
            </div>
            <div class="d-flex justify-content-between">
              <p>{{ "Carrello.section1.sped" | translate }}</p>
              <p>{{consegna | currency : "EUR" : "symbol" : "1.2-2" : "it" }}</p>
            </div>
            <div class="d-flex justify-content-between" *ngIf="paymentPrice!=0">
              <p>{{"Carrello.section1.mpagamento"|translate}}</p>
              <p>{{paymentPrice | currency : "EUR" : "symbol" : "1.2-2" : "it" }}</p>
            </div>
            <div class="separate mt-1"></div>
            <div class="d-flex justify-content-between align-items-center mb-3">
              <h6 class="m-0">{{ "Carrello.section1.total" | translate }}</h6>
              <b class="bolder">{{TotalCarrello + consegna + paymentPrice  | currency : "EUR" : "symbol" : "1.2-2" : "it" }}</b>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="main-carrello ssm p-4 px-xl-5">
          <div class="product-carrello">
            <mat-stepper orientation="vertical" #stepper  class="mb-3" *ngIf="!pay">
              <mat-step>
                <form>
                  <ng-template matStepLabel>{{ "Pagamento.fattura.bigtitle" | translate }}</ng-template>
                  <div class="row mt-2">
                    <div class="col-lg-12">
                      <!-- <mat-checkbox (change)="changeFattura()">{{ "Pagamento.fattura.title" | translate }}</mat-checkbox> -->
                      <form *ngIf="(fattura==true)">
                        <div class="row">
                          <div class="col-lg-12 mb-1">
                            <label for="" class="mb-3">{{ "Pagamento.fattura.tipCliente" | translate }}</label><br>
                            <mat-spinner *ngIf="CustomerTpes.length==0" class="mx-auto" [diameter]="30"></mat-spinner>
                            <mat-radio-group name="Tipologie" [(ngModel)]="Tipologie" required (change)="checkstatutField()"
                              class="d-flex flex-wrap w-100 justify-content-around">
                              <mat-radio-button *ngFor="let item of CustomerTpes" value="{{item.Code}}">
                                {{(item.Description=='B2B - AZIENDA'&&service.currentLang=='en')?'B2B - COMPANY':(item.Description=='IVA - PRIVATO'&&service.currentLang=='en')?'VAT - PRIVATE':(item.Description=='PA - PUBBLICA AMMINISTRAZIONE'&&service.currentLang=='en')?'PA - PUBLIC ADMINISTRATION':item.Description}}</mat-radio-button>
                            </mat-radio-group>
                          </div>
                          <div class="col-lg-12" *ngIf="Tipologie!='IVA'">
                            <label for="">{{ "Pagamento.fattura.ragSoc" | translate }}</label>
                            <input type="text" class="form-control" name="FragioneSociale" [(ngModel)]="FragioneSociale"
                              [required]="Tipologie=='PA' || (Fnome=='' && Fcognome=='')" [disabled]="update==false"
                              (change)="checkstatutField()">
                          </div>
                          <div class="col-lg-6" *ngIf="Tipologie!='PA'">
                            <label for="">{{ "Pagamento.section1.nome" | translate }}</label>
                            <input type="text" class="form-control" name="Fnome" [(ngModel)]="Fnome"
                              [required]="FragioneSociale=='' || Tipologie=='IVA'" [disabled]="update==false"
                              (change)="checkstatutField()">
                          </div>
                          <div class="col-lg-6" *ngIf="Tipologie!='PA'">
                            <label for="">{{ "Pagamento.section1.cognome" | translate }}</label>
                            <input type="text" class="form-control" name="Fcognome" [(ngModel)]="Fcognome"
                              [required]="FragioneSociale=='' || Tipologie=='IVA'" [disabled]="update==false"
                              (change)="checkstatutField()">
                          </div>
                          <div class="col-lg-8">
                            <label for="">{{ "Pagamento.section1.indir" | translate }}</label>
                            <input type="text" class="form-control" name="FIndirizzo" [(ngModel)]="FIndirizzo" required
                              (change)="checkstatutField()" *ngIf="FIndirizzo!=''">

                            <input matInput class="w-100 mb-2 pl-3" matGoogleMapsAutocomplete *ngIf="FIndirizzo==''"
                              placeholder="Via del Corso 100, Roma, RM"
                              (onGermanAddressMapped)="onGermanAddressMapped($event)"
                              (onChange)="onGermanAddressMapped($event)" required autocomplete="autocompleteOFf"
                              onfocus="this.setAttribute('autocomplete', 'autocompleteOFf');" #focusAddress>
                          </div>
                          <div class="col-lg-4">
                            <label for="">{{ "Pagamento.section1.civico" | translate }}</label>
                            <input type="text" class="form-control" name="Fcivico" [(ngModel)]="Fcivico" required
                              (change)="checkstatutField()">
                          </div>
                          <div class="col-lg-6">
                            <label for="">{{ "Pagamento.fattura.naz" | translate }}</label>
                            <select [(ngModel)]="FCountry" required name="FCountry" class="form-control" (ngModelChange)="checkstatutField();changeCountry();GettipologiaPagamento();">
                              <!-- <option [value]="null" selected>Italia</option> -->
                              <option value="{{item.Country}}" *ngFor="let item of CountryCodes">
                                {{item.Country}}
                              </option>
                            </select>
                          </div>
                          <div class="col-6">
                            <label for="">{{ "Pagamento.section1.prov" | translate }}</label>
                            <select class="form-control" name="FProvincia" [(ngModel)]="FProvincia" required
                              (change)="checkstatutField();FProvincia=='EE'?FCountry='':FCountry='Italia'">
                              <option *ngFor="let item of provinces" value="{{item.Acronym}}">
                                {{item.Acronym}} | {{item.Description}}
                              </option>
                            </select>
                          </div>
                          <div class="col-6">
                            <label for="">{{ "Pagamento.section1.citta" | translate }}</label>
                            <input type="text" class="form-control" name="FCitta" [(ngModel)]="FCitta" required
                              (change)="checkstatutField()">
                          </div>
                          <div class="col-lg-3">
                            <label for="">{{ "Pagamento.section1.CAP" | translate }}</label>
                            <input type="text" class="form-control" name="Fcap" [(ngModel)]="Fcap" required
                              (change)="checkstatutField()">
                          </div>
                        </div>
                        <hr>
                        <div class="row">
                          <div class="col-lg-6" *ngIf="Tipologie!='IVA'">
                            <label for="">{{ "Pagamento.fattura.piva" | translate }}</label>
                            <input type="text" class="form-control" name="Fiva" [(ngModel)]="Fiva"
                              [required]="Fcf==null || Fcf=='' && Tipologie!='IVA'" (change)="checkstatutField()"
                              [minlength]="(FCountry=='Italia' || FCountry==null)?11:0"
                              [maxlength]="(FCountry=='Italia' || FCountry==null)?11:100">
                            <small *ngIf="erreurFiva==true" class="text-danger">
                              {{ "Pagamento.fattura.pivaalert" | translate }}
                            </small>
                          </div>
                          <div class="col-lg-6">
                            <label for="">{{ "Pagamento.fattura.cf" | translate }}</label>
                            <input type="text" class="form-control" name="Fcf" [(ngModel)]="Fcf"
                              [required]="Fiva==null || Fiva==''" (change)="checkstatutField()"
                              [minlength]="Tipologie=='IVA'?16:0" [maxlength]="Tipologie=='IVA'?16:100">
                            <small *ngIf="erreurCodiceFs==true" class="text-danger">
                              {{ "Pagamento.fattura.cfalert" | translate }}
                            </small>
                          </div>
                          <div class="col-lg-6" *ngIf="Tipologie!='IVA'">
                            <label for="">{{ "Pagamento.fattura.sdi" | translate }}</label>
                            <input type="text" class="form-control" name="Fsdi" [(ngModel)]="Fsdi"
                              [required]="Fpec==null || Fpec=='' && Tipologie!='IVA'" (change)="checkstatutField()"
                              [maxlength]="Tipologie=='PA'?6:7" [minlength]="Tipologie=='PA'?6:7">
                            <small *ngIf="erreurSDI==true && Tipologie=='PA'" class="text-danger">
                              {{ "Pagamento.fattura.sdialert" | translate }}
                            </small>
                            <small *ngIf="erreurSDI==true && Tipologie!='PA'"
                              class="text-danger">
                              {{ "Pagamento.fattura.sdialert2" | translate }}
                            </small>
                          </div>
                          <div class="col-lg-6" *ngIf="Tipologie!='IVA'">
                            <label for="">Pec</label>
                            <input type="email" class="form-control" name="Fpec" [(ngModel)]="Fpec"
                              [required]="Fsdi==null || Fsdi=='' && Tipologie!='IVA'" (change)="checkstatutField()"
                              [ngClass]="{'required':emailErrorformat==true}">
                            <small *ngIf="emailErrorformat==true" class="text-danger"> {{ "Pagamento.fattura.pecalert" |
                              translate }} </small>
                          </div>
                          <div class="col-lg-6">
                            <label for="">{{ "Pagamento.fattura.esig" | translate }}</label>
                            <select class="form-control" name="Esigibilita" [(ngModel)]="Esigibilita" required
                              (change)="checkstatutField()">
                              <option *ngFor="let item of VatExigibility" value="{{item.Code}}">
                                {{item.Description}}
                              </option>
                            </select>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="px-3 mt-4 mb-3" *ngIf="datiFattErrorMsg!=''">
                    <h6 class="text-danger mx-auto d-block">{{datiFattErrorMsg}}</h6>
                  </div>
                  <div>
                    <button class="btn btn-bg small ml-0 mt-3" (click)="checkDatiFatturazione()">{{"Pagamento.next" | translate}}</button>
                  </div>
                </form>
              </mat-step>
              <mat-step>
                <form>
                  <ng-template matStepLabel>{{ "Pagamento.section1.indirizzo" | translate }}</ng-template>
                  <!-- <h6 class="title-s title1 mt-4">{{ "Pagamento.section1.indirizzo" | translate }}</h6> -->
                  <mat-spinner *ngIf="Loadaddress==true" class="mx-auto" [diameter]="30"></mat-spinner>
                  <!-- <h6 class="text-danger" *ngIf="GetAddressesList.length==0">{{ "Pagamento.section1.add-address" | translate }}</h6> -->
                  <carousel [lightDOM]="'true'" [height]="120" [cellWidth]="275" [dots]="false" [arrows]="true"
                    [arrowsOutside]="false" [loop]="true" class="mt-4 couselx" *ngIf="GetAddressesList.length!=0">
                    <div class="carousel-cell" *ngFor="let item of GetAddressesList">
                      <div class="box-rounded text-left mb-4 h-100"
                        [ngClass]="{'activeAdd': selectedAdress[0]?.$id==item?.$id || selectedAdress?.$id==item?.$id}"
                        (click)="selectAdress(item)">
                        <h4>{{item.ReferenceName}}</h4>
                        <span>{{item.Description}}</span><br>
                        <span>{{item.Address}},{{item.StreetNumber}}</span><br>
                        <span>{{item.City}},{{item.Prov}} {{item.Cap}}</span><br>
                        <span>{{item.Country}}</span>
                      </div>
                    </div>
                  </carousel>
                  <button class="btn btn-bg small mt-3 ml-0" [ngClass]="{'animated': selectedAdress.length == 0 && updateFattError!=''}" style="max-width: fit-content;" (click)="openAddAdr=true; this.useadd(this.GetAddressesList.length==0?true:false);">{{ (GetAddressesList.length!=0?"Pagamento.section1.newindirizzo":"Pagamento.section1.addindirizzo") | translate }}</button>

                  <div class="px-3 mt-4 mb-3" *ngIf="IndirizzospedErrorMsg!=''">
                    <h6 class="text-danger mx-auto d-block">{{IndirizzospedErrorMsg}}</h6>
                  </div>
                  <div class="d-flex">
                    <button class="btn btn-w-border small ml-0 mt-3" matStepperPrevious>{{"Pagamento.back" | translate}}</button>
                    <button class="btn btn-bg small ml-3 mt-3" (click)="checkIndirizzosped()">{{"Pagamento.next" | translate}}</button>
                  </div>
                </form>
              </mat-step>
              <mat-step>
                <ng-template matStepLabel>{{"Pagamento.section1.steper3"|translate}}</ng-template>
                <div class="row mt-3" *ngIf="selectedAdress[0]?.Country=='Italia' && selectedAdress[0]?.Prov!='EE'">
                  <div class="col-lg-12">
                    <h6 class="mt-0 mb-2">{{ "Pagamento.spedizione.title" | translate }}</h6>
                    <h5 class="p-desc">{{ "Pagamento.spedizione.description" | translate }}</h5>
                    <h5 class="p-desc" style="font-weight:500">{{ "Pagamento.spedizione.peso-tot" | translate }} <b>{{ totalePeso}} kg</b></h5>
                    <div class="flex-corrier mt-4">

                      <div class="item" *ngFor="let item of TipologieSpedizione">
                        <div class="card" [ngClass]="{'active': codeconsegna==item.Code}">
                          <!-- <mat-checkbox class="check-cor" [checked]="consegna===0" (change)="consegna=0">{{item.Description}}</mat-checkbox> -->
                          <mat-checkbox class="check-cor" [checked]="codeconsegna===item.Code"
                            (change)="changeSpedizione($event,item)">{{item.Description}}</mat-checkbox>
                          <!-- <img loading="lazy" class="card-img-top" src="assets/img/free.png" height="80"> -->
                          <div class="card-body pt-2">
                            <h6 class="card-text">
                              <ng-container
                                *ngIf="TotalCarrello>= item.FreePriceOver && item.FreePriceOver!=null">
                                {{ "Pagamento.spedizione.sped-grat" | translate }}</ng-container>
                              <ng-container *ngIf="(TotalCarrello<item.FreePriceOver || item.FreePriceOver==null) && item.FixedPrice!=null">
                                {{item.FixedPrice | currency : "EUR" : "symbol" : "1.2-2" : "it" }}
                              </ng-container>
                              <ng-container
                                *ngIf="(TotalCarrello<item.FreePriceOver || item.FreePriceOver==null) && item.FixedPrice==null && item.ListRates.length!=0">
                                €
                                <ng-container *ngFor="let list of item.ListRates">
                                  {{ (totalePeso>=list.FromValue && totalePeso < list.ToValue) ? list.Price :''}} {{
                                    (totalePeso>=list.FromValue && list.ToValue==null) ? list.Price :''}}
                                </ng-container>
                              </ng-container>

                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p *ngIf="selectedAdress[0]?.Country!='Italia'||selectedAdress[0]?.Prov=='EE'" class="my-2" style="color: red;max-width: 520px;font-weight: 300 !important;line-height: 23px !important;">
                  {{"Pagamento.spedizione.msgestero" | translate}}
                </p>
                <div class="row mt-3">
                  <div class="col-lg-12">
                    <h6 class="mt-0 mb-2">{{"Carrello.section1.tpagamento"|translate}}</h6>
                    <div class="flex-corrier mt-4">
                      <ng-container *ngFor="let item of Tipologiepagamento">
                        <div class="item"  *ngIf="selectedAdress[0]?.Country!='Italia'||selectedAdress[0]?.Prov=='EE'?item.Code==15:1==1">
                          <div class="card" [ngClass]="{'active': PaymentType==item.Code}">
                            <!-- <mat-checkbox class="check-cor" [checked]="consegna===0" (change)="consegna=0">{{item.Description}}</mat-checkbox> -->
                            <mat-checkbox class="check-cor" [checked]="PaymentType===item.Code" [disabled]="FCountry!='Italia'?true:false"
                              (change)="changePayment($event,item)">{{item.Description}}</mat-checkbox>
                            <!-- <img loading="lazy" class="card-img-top" src="assets/img/free.png" height="80"> -->
                            <div class="card-body pt-2">
                              <h6 class="card-text">
                                  {{item.ExtraAmount | currency : "EUR" : "symbol" : "1.2-2" : "it" }}
                              </h6>
                            </div>
                          </div>
                        </div>
                       </ng-container>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-lg-12">
                    <h6 class="mt-0">{{ "Pagamento.fattura.note" | translate }}</h6>
                    <textarea name="" class="form-control mt-3" id="" cols="30" rows="3" [(ngModel)]="note"
                      placeholder="Aiutaci a trovare il tuo indirizzo con eventuali indicazioni"></textarea>
                  </div>
                </div>
                <div class="px-3 mt-4 mb-3" *ngIf="updateFattError!=''">
                  <h6 class="text-danger mx-auto d-block">{{updateFattError}}</h6>
                </div>
              </mat-step>
            </mat-stepper>

            <ng-container *ngIf="!pay">
              <div class="d-flex justify-content-center align-items-center w-100">
                <button class="btn btn-bg mx-auto my-3" style="max-width: 360px !important"
                  (click)="SaveContact('Online')" [disabled]="updateFattError!='' || startOrder==true || TotalCarrello==0">
                  <ng-container *ngIf="startOrder==false">{{ (PayWeb == true?"Pagamento.fattura.btn":"Pagamento.fattura.btn2") | translate }}</ng-container>
                  <mat-spinner *ngIf="startOrder==true" class="mx-auto" [diameter]="30"></mat-spinner>
                </button>
              </div>
              <div class="alert alert-danger" *ngIf="ordiniError!=''">
                {{ordiniError}}
              </div>
            </ng-container>

           <div #paymentRef class="col-lg-6 offset-lg-3 mt-3" [ngClass]="{'d-none': !pay}"></div>


          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="modal" tabindex="-1" role="dialog" *ngIf="openAddAdr==true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ (GetAddressesList.length!=0?"Pagamento.section1.newindirizzo":"Pagamento.section1.addindirizzo") | translate }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="openAddAdr=false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <label class="containercheck">
            {{'Pagamento.section1.sameAdresse' | translate}}

            <input type="checkbox" [checked]="useAdressFatt" (change)="useadd($event.target.checked)">
            <span class="checkmark"></span>
          </label>
        <div class="row mt-1">
          <div class="col-lg-6">
            <label for="">{{ "Pagamento.section1.descr" | translate }}</label>
            <input type="text" name="Descrizione" [(ngModel)]="Descrizione" required placeholder="{{'Profile.indirizzi.descplaceholder' | translate}}">
          </div>
          <div class="col-lg-6">
            <label for="">{{ "Pagamento.section1.refer" | translate }}</label>
            <input type="text" name="nomeReferente" [(ngModel)]="nomeReferente" required>
          </div>
          <div class="col-lg-8">
            <label for="">{{ "Pagamento.section1.indir" | translate }}</label>
            <input type="text" name="Indirizzo" [(ngModel)]="Indirizzo" required>
          </div>
          <div class="col-lg-4">
            <label for="">{{ "Pagamento.section1.civico" | translate }}</label>
            <input type="text" name="cap" [(ngModel)]="StreetNumber" required>
          </div>
          <div class="col-lg-6">
            <label for="">{{ "Pagamento.section1.paese" | translate }}</label>
            <select [(ngModel)]="Paese" name="Paese" required (change)="Paese!='Italia'?provincia='EE':provincia=''">
              <option [selected]="true" [ngValue]="'Italia'">Italia
              </option>
              <option value="{{item.Country}}" *ngFor="let item of CountryCodes">
                {{item.Country}}
              </option>
            </select>
          </div>
          <div class="col-6">
            <label for="">{{ "Pagamento.section1.prov" | translate }}</label>
            <select name="provincia" [(ngModel)]="provincia" required (change)="provincia=='EE'?Paese='':Paese='Italia'">
              <option *ngFor="let item of provinces" value="{{item.Acronym}}">
                {{item.Acronym}} | {{item.Description}}
              </option>
            </select>
          </div>
          <div class="col-6">
            <label for="">{{ "Pagamento.section1.citta" | translate }}</label>
            <input type="text" name="Citta" [(ngModel)]="Citta" required>
          </div>
          <div class="col-6">
            <label for="">{{ "Pagamento.section1.CAP" | translate }}</label>
            <input type="text" name="cap" [(ngModel)]="cap" required>
          </div>
          <div class="col-lg-6">
            <label for="">{{ "Pagamento.section1.tel" | translate }}</label>
            <input type="text" matInput hidden>
            <ngx-intl-tel-input matInput [preferredCountries]="['it']" [enableAutoCountrySelect]="true" [separateDialCode]="true"
              [searchCountryFlag]="true" [selectFirstCountry]="true" [phoneValidation]="false"
              [(ngModel)]="Telefono" #phoneNGX required>
            </ngx-intl-tel-input>
          </div>
          <small class="text-danger" *ngIf="errorAddAdress!=''">{{errorAddAdress}}</small>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-bg" (click)="addAdress(Profilo.IdGestionale)">{{
          "Pagamento.section1.add" | translate }}</button>
        <button type="button" class="btn btn-w-border" data-dismiss="modal" (click)="openAddAdr=false">{{
          "Pagamento.section1.annM" | translate }}</button>
      </div>
    </div>
  </div>
</div>
