<section style="min-height: 75vh">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h1 class="title mb-4">Educational</h1>
        <h3 class="ml-3">2021</h3>
        <div class="d-flex flex-wrap ">
          <article>
            <figure>
              <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" width="560" height="315"
                  srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/qbAtXE-TJQY><img src=https://img.youtube.com/vi/qbAtXE-TJQY/hqdefault.jpg alt='AltTagContent'><span>▶</span></a>"
                  title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen=""></iframe>
              </div>
              <div class="sep20 hidden-xs hidden-sm  "></div>
            </figure>
            <section class="post-content">
              <div class="meta" style="height:26px">
                <ul>
                  <li class="grigio">04/05/2021</li>
                  <li class=""><i class=" icon-video blu font15"></i> </li>
                </ul>
              </div>
              <h2>Levigatrice cambio nastro</h2>
              <p>
                Cambiare il nastro della levigatrice Blacksmith in solo 43 secondi!</p>
            </section>
            <div class="clearfix"></div>
          </article>
        </div>
        <h3 class="my-3 ml-3">2013</h3>
        <div class="d-flex flex-wrap ">
          <article>
            <figure>
              <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" width="560" height="315"
                  srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/k1xujcRW554><img src=https://img.youtube.com/vi/k1xujcRW554/hqdefault.jpg alt='AltTagContent'><span>▶</span></a>><img src=https://img.youtube.com/vi/k1xujcRW554/hqdefault.jpg alt='AltTagContent'><span>▶</span></a>"
                  title="0" allowfullscreen=""></iframe>
              </div>
              <div class="sep20 hidden-xs hidden-sm  "></div>
            </figure>
            <section class="post-content">
              <div class="meta" style="height:26px">
                <ul>
                  <li class="grigio">08/11/2013</li>
                  <li class=""><i class=" icon-video blu font15"></i> </li>
                </ul>
              </div>
              <h2>Mustad LiBero
                shoe modifications: added lateral support</h2>
              <p>
                Inside this video, you will learn techniques to add lateral support to a Mustad</p>
            </section>
            <div class="clearfix"></div>
          </article>
          <article>
            <figure>
              <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" width="560" height="315"
                  srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/NgH8gAAJ5Oo><img src=https://img.youtube.com/vi/NgH8gAAJ5Oo/hqdefault.jpg alt='AltTagContent'><span>▶</span></a>"
                  title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen=""></iframe>
              </div>
              <div class="sep20 hidden-xs hidden-sm  "></div>
            </figure>
            <section class="post-content">
              <div class="meta" style="height:26px">
                <ul>
                  <li class="grigio">26/08/2013</li>
                  <li class=""><i class=" icon-video blu font15"></i> </li>
                </ul>
              </div>
              <h2>Aluminium Front Shoe Lateral
                Extension</h2>
              <p>
                Watch Grant Moon performing at his best to forge an aluminium front shoe with la</p>
            </section>
            <div class="clearfix"></div>
          </article>
          <article>
            <figure>
              <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" width="560" height="315"
                  srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/HsvRVfCBdqI><img src=https://img.youtube.com/vi/HsvRVfCBdqI/hqdefault.jpg alt='AltTagContent'><span>▶</span></a>"
                  title="0" allowfullscreen=""></iframe>
              </div>
              <div class="sep20 hidden-xs hidden-sm  "></div>
            </figure>
            <section class="post-content">
              <div class="meta" style="height:26px">
                <ul>
                  <li class="grigio">26/08/2013</li>
                  <li class=""><i class=" icon-video blu font15"></i> </li>
                </ul>
              </div>
              <h2>Caulk and Wedge Hunter Hind Pony
                shoe</h2>
              <p>
                Watch Grant Moon releasing his method to forge a Caulk and Wedge Hunter Hind Pon</p>
            </section>
            <div class="clearfix"></div>
          </article>
          <article>
            <figure>
              <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" width="560" height="315"
                  srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/7OeP_cukKoY><img src=https://img.youtube.com/vi/7OeP_cukKoY/hqdefault.jpg alt='AltTagContent'><span>▶</span></a>"
                  title="0" allowfullscreen=""></iframe>
              </div>
              <div class="sep20 hidden-xs hidden-sm  "></div>
            </figure>
            <section class="post-content">
              <div class="meta" style="height:26px">
                <ul>
                  <li class="grigio">23/08/2013</li>
                  <li class=""><i class=" icon-video blu font15"></i> </li>
                </ul>
              </div>
              <h2>Eggbar
                shoe</h2>
              <p>
                Watch Grant Moon forging an eggbar shoe with 1/2 of the shoe built in the first</p>
            </section>
            <div class="clearfix"></div>
          </article>
          <article>
            <figure>
              <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" width="560" height="315"
                  srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/v8r4nyfeBas><img src=https://img.youtube.com/vi/v8r4nyfeBas/hqdefault.jpg alt='AltTagContent'><span>▶</span></a>"
                  frameborder="0" allowfullscreen=""></iframe>
              </div>
              <div class="sep20 hidden-xs hidden-sm  "></div>
            </figure>
            <section class="post-content">
              <div class="meta" style="height:26px">
                <ul>
                  <li class="grigio">22/08/2013</li>
                  <li class=""><i class=" icon-video blu font15"></i> </li>
                </ul>
              </div>
              <h2>2013: let's go to Calgary!</h2>
              <p>See
                each of the WCBC shoes made by Grant for this year's World Championship Blac</p>
            </section>
            <div class="clearfix"></div>
          </article>
          <article>
            <figure>
              <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" width="560" height="315"
                  srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/tsoqSjm9CWs><img src=https://img.youtube.com/vi/tsoqSjm9CWs/hqdefault.jpg alt='AltTagContent'><span>▶</span></a>"
                  title="0" allowfullscreen=""></iframe>
              </div>
              <div class="sep20 hidden-xs hidden-sm  "></div>
            </figure>
            <section class="post-content">
              <div class="meta" style="height:26px">
                <ul>
                  <li class="grigio">22/08/2013</li>
                  <li class=""><i class=" icon-video blu font15"></i> </li>
                </ul>
              </div>
              <h2>Lateral Extension Bar shoe</h2>
              <p>
                Learn forging techniques and time saving steps presented by Grant Moon.</p>
            </section>
            <div class="clearfix"></div>
          </article>
          <article>
            <figure>
              <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" width="560" height="315"
                  srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/vlV-JRK-2tE><img src=https://img.youtube.com/vi/vlV-JRK-2tE/hqdefault.jpg alt='AltTagContent'><span>▶</span></a>"
                  title="0" allowfullscreen=""></iframe>
              </div>
              <div class="sep20 hidden-xs hidden-sm  "></div>
            </figure>
            <section class="post-content">
              <div class="meta" style="height:26px">
                <ul>
                  <li class="grigio">22/08/2013</li>
                  <li class=""><i class=" icon-video blu font15"></i> </li>
                </ul>
              </div>
              <h2>Forging Hunter Heels</h2>
              <p>
                Watch Grant Moon forging Hunter heels in this new episode of our Road to Calgary</p>
            </section>
            <div class="clearfix"></div>
          </article>
          <article>
            <figure>
              <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" width="560" height="315"
                  srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/LS_UemzaTwU><img src=https://img.youtube.com/vi/LS_UemzaTwU/hqdefault.jpg alt='AltTagContent'><span>▶</span></a>"
                  frameborder="0" allowfullscreen=""></iframe>
              </div>
              <div class="sep20 hidden-xs hidden-sm  "></div>
            </figure>
            <section class="post-content">
              <div class="meta" style="height:26px">
                <ul>
                  <li class="grigio">22/08/2013</li>
                  <li class=""><i class=" icon-video blu font15"></i> </li>
                </ul>
              </div>
              <h2>Heartbar shoe</h2>
              <p>
                Grant Moon walks us through the steps to forging the final of the Eliminator Cla</p>
            </section>
            <div class="clearfix"></div>
          </article>
          <article>
            <figure>
              <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" width="560" height="315"
                  srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/WFYm4QHFqgY><img src=https://img.youtube.com/vi/WFYm4QHFqgY/hqdefault.jpg alt='AltTagContent'><span>▶</span></a>"
                  title="0" allowfullscreen=""></iframe>
              </div>
              <div class="sep20 hidden-xs hidden-sm  "></div>
            </figure>
            <section class="post-content">
              <div class="meta" style="height:26px">
                <ul>
                  <li class="grigio">21/08/2013</li>
                  <li class=""><i class=" icon-video blu font15"></i> </li>
                </ul>
              </div>
              <h2>2 Persons, 3/4 Masselotte Clip shoe
              </h2>
              <p>
              </p>
            </section>
            <div class="clearfix"></div>
          </article>
          <article>
            <figure>
              <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" width="560" height="315"
                  srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/Tx5uHiWFkGw><img src=https://img.youtube.com/vi/Tx5uHiWFkGw/hqdefault.jpg alt='AltTagContent'><span>▶</span></a>"
                  title="0" allowfullscreen=""></iframe>
              </div>
              <div class="sep20 hidden-xs hidden-sm  "></div>
            </figure>
            <section class="post-content">
              <div class="meta" style="height:26px">
                <ul>
                  <li class="grigio">20/08/2013</li>
                  <li class=""><i class=" icon-video blu font15"></i>
                  </li>
                </ul>
              </div>
              <h2 class="lh18  " style="border: 0px solid red">Tool fullered hunter hind caulk
              </h2>
              <p class="hidden-xs hidden-sm   " style="height:58px; overflow:hidden; border: 0px solid blue">
                Road to Calgary - Tool fullered hunter hind caulk &amp; wedge</p>
            </section>
            <div class="clearfix"></div>
          </article>
          <article>
            <figure>
              <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" width="560" height="315"
                  srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/I7KmAgtwGa8><img src=https://img.youtube.com/vi/I7KmAgtwGa8/hqdefault.jpg alt='AltTagContent'><span>▶</span></a>"
                  title="0" allowfullscreen=""></iframe>
              </div>
              <div class="sep20 hidden-xs hidden-sm  "></div>
            </figure>
            <section class="post-content">
              <div class="meta" style="height:26px">
                <ul>
                  <li class="grigio">20/08/2013</li>
                  <li class=""><i class=" icon-video blu font15"></i>
                  </li>
                </ul>
              </div>
              <h2 class="lh18  " style="border: 0px solid red">Straight Bar shoe</h2>
              <p class="hidden-xs hidden-sm   " style="height:58px; overflow:hidden; border: 0px solid blue">
                Road to Calgary video series - Straight Bar</p>
            </section>
            <div class="clearfix"></div>
          </article>
          <article>
            <figure>
              <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" width="560" height="315"
                  srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/obb__TQGqnk><img src=https://img.youtube.com/vi/obb__TQGqnk/hqdefault.jpg alt='AltTagContent'><span>▶</span></a>"
                  frameborder="0" allowfullscreen=""></iframe>
              </div>
              <div class="sep20 hidden-xs hidden-sm  "></div>
            </figure>
            <section class="post-content">
              <div class="meta" style="height:26px">
                <ul>
                  <li class="grigio">09/07/2013</li>
                  <li class=""><i class=" icon-video blu font15"></i>
                  </li>
                </ul>
              </div>
              <h2 class="lh18  " style="border: 0px solid red">Four-man Draft shoeing</h2>
              <p class="hidden-xs hidden-sm   " style="height:58px; overflow:hidden; border: 0px solid blue">
                Close-up look at 2013 WCBC 4-men class</p>
            </section>
            <div class="clearfix"></div>
          </article>
          <article>
            <figure>
              <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" width="560" height="315"
                  srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/oxbKTUUK0nU><img src=https://img.youtube.com/vi/oxbKTUUK0nU/hqdefault.jpg alt='AltTagContent'><span>▶</span></a>"
                  title="0" allowfullscreen=""></iframe>
              </div>
              <div class="sep20 hidden-xs hidden-sm  "></div>
            </figure>
            <section class="post-content">
              <div class="meta" style="height:26px">
                <ul>
                  <li class="grigio">16/01/2013</li>
                  <li class=""><i class=" icon-video blu font15"></i>
                  </li>
                </ul>
              </div>
              <h2 class="lh18  " style="border: 0px solid red">Shoeing with Mustad
                Equi-Librium Steel (front)</h2>
              <p class="hidden-xs hidden-sm   " style="height:58px; overflow:hidden; border: 0px solid blue">
                Tips to get the best out of Olympics-winner Mustad Equi-Librium fronts - presented by Gran Moon
              </p>
            </section>
            <div class="clearfix"></div>
          </article>
          <article>
            <figure>
              <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" width="560" height="315"
                  srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/SqCm_XQDzTo><img src=https://img.youtube.com/vi/SqCm_XQDzTo/hqdefault.jpg alt='AltTagContent'><span>▶</span></a>"
                  title="0" allowfullscreen=""></iframe>
              </div>
              <div class="sep20 hidden-xs hidden-sm  "></div>
            </figure>
            <section class="post-content">
              <div class="meta" style="height:26px">
                <ul>
                  <li class="grigio">16/01/2013</li>
                  <li class=""><i class=" icon-video blu font15"></i>
                  </li>
                </ul>
              </div>
              <h2 class="lh18  " style="border: 0px solid red">Hoof
                Preparation</h2>
              <p class="hidden-xs hidden-sm   " style="height:58px; overflow:hidden; border: 0px solid blue">
                Follow Grant Moon as he prepares a hoof for shoeing</p>
            </section>
            <div class="clearfix"></div>
          </article>
          <article>
            <figure>
              <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" width="560" height="315"
                  srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/qQ1Iq45mu-s><img src=https://img.youtube.com/vi/qQ1Iq45mu-s/hqdefault.jpg alt='AltTagContent'><span>▶</span></a>"
                  title="0" allowfullscreen=""></iframe>
              </div>
              <div class="sep20 hidden-xs hidden-sm  "></div>
            </figure>
            <section class="post-content">
              <div class="meta" style="height:26px">
                <ul>
                  <li class="grigio">16/01/2013</li>
                  <li class=""><i class=" icon-video blu font15"></i>
                  </li>
                </ul>
              </div>
              <h2 class="lh18  " style="border: 0px solid red">Shoe Modifications - part 1</h2>
              <p class="hidden-xs hidden-sm   " style="height:58px; overflow:hidden; border: 0px solid blue">
                Follow Grant Moon as he modifies a horseshoe!</p>
            </section>
            <div class="clearfix"></div>
          </article>
          <article>
            <figure>
              <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" width="560" height="315"
                  srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/euAry145OEg><img src=https://img.youtube.com/vi/euAry145OEg/hqdefault.jpg alt='AltTagContent'><span>▶</span></a>"
                  title="0" allowfullscreen=""></iframe>
              </div>
              <div class="sep20 hidden-xs hidden-sm  "></div>
            </figure>
            <section class="post-content">
              <div class="meta" style="height:26px">
                <ul>
                  <li class="grigio">16/01/2013</li>
                  <li class=""><i class=" icon-video blu font15"></i>
                  </li>
                </ul>
              </div>
              <h2 class="lh18  " style="border: 0px solid red">Tong
                Fitting</h2>
              <p class="hidden-xs hidden-sm   " style="height:58px; overflow:hidden; border: 0px solid blue">
                Even heavy-duty tools need help after intensive use</p>
            </section>
            <div class="clearfix"></div>
          </article>
          <article>
            <figure>
              <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" width="560" height="315"
                  srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/bHN1Mks8dk8><img src=https://img.youtube.com/vi/bHN1Mks8dk8/hqdefault.jpg alt='AltTagContent'><span>▶</span></a>"
                  title="0" allowfullscreen=""></iframe>
              </div>
              <div class="sep20 hidden-xs hidden-sm  "></div>
            </figure>
            <section class="post-content">
              <div class="meta" style="height:26px">
                <ul>
                  <li class="grigio">16/01/2013</li>
                  <li class=""><i class=" icon-video blu font15"></i>
                  </li>
                </ul>
              </div>
              <h2 class="lh18  " style="border: 0px solid red">Mustad Traction - Stud Holes</h2>
              <p class="hidden-xs hidden-sm   " style="height:58px; overflow:hidden; border: 0px solid blue">
                Watch Grant Moon on the anvil creating an additional stud hole</p>
            </section>
            <div class="clearfix"></div>
          </article>
          <article>
            <figure>
              <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" width="560" height="315"
                  srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/sTKdjhQRtZo><img src=https://img.youtube.com/vi/sTKdjhQRtZo/hqdefault.jpg alt='AltTagContent'><span>▶</span></a>"
                  title="0" allowfullscreen=""></iframe>
              </div>
              <div class="sep20 hidden-xs hidden-sm  "></div>
            </figure>
            <section class="post-content">
              <div class="meta" style="height:26px">
                <ul>
                  <li class="grigio">14/01/2013</li>
                  <li class=""><i class=" icon-video blu font15"></i>
                  </li>
                </ul>
              </div>
              <h2 class="lh18  " style="border: 0px solid red">Mustad LiBero horseshoes
                modifications</h2>
              <p class="hidden-xs hidden-sm   " style="height:58px; overflow:hidden; border: 0px solid blue">
                Heels extension on a side-clipped hind shoe</p>
            </section>
            <div class="clearfix"></div>
          </article>
          <article>
            <figure>
              <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" width="560" height="315"
                  srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/lQ65z8Vvb0U><img src=https://img.youtube.com/vi/lQ65z8Vvb0U/hqdefault.jpg alt='AltTagContent'><span>▶</span></a>"
                  title="0" allowfullscreen=""></iframe>
              </div>
              <div class="sep20 hidden-xs hidden-sm  "></div>
            </figure>
            <section class="post-content">
              <div class="meta" style="height:26px">
                <ul>
                  <li class="grigio">14/01/2013</li>
                  <li class=""><i class=" icon-video blu font15"></i>
                  </li>
                </ul>
              </div>
              <h2 class="lh18  " style="border: 0px solid red">Mustad LiBero horseshoes
                modifications</h2>
              <p class="hidden-xs hidden-sm   " style="height:58px; overflow:hidden; border: 0px solid blue">
                Rocker Toe on a front toe-clipped shoe</p>
            </section>
            <div class="clearfix"></div>
          </article>
        </div>
        <h3 class="my-3 ml-3">2012</h3>
        <div class="d-flex flex-wrap ">
          <article>
            <figure>
              <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" width="560" height="315"
                  srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/rhAROteA_S8><img src=https://img.youtube.com/vi/rhAROteA_S8/hqdefault.jpg alt='AltTagContent'><span>▶</span></a>"
                  title="0" allowfullscreen=""></iframe>
              </div>
              <div class="sep20 hidden-xs hidden-sm  "></div>
            </figure>
            <section class="post-content">
              <div class="meta" style="height:26px">
                <ul>
                  <li class="grigio">20/12/2012</li>
                  <li class=""><i class=" icon-video blu font15"></i> </li>
                </ul>
              </div>
              <h2>Horse evaluation</h2>
              <p>
                Grant Moon describes the steps to a correct horse evaluation</p>

            </section>
            <div class="clearfix"></div>
          </article>
          <article>
            <figure>
              <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" width="560" height="315"
                  srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/RTkCLQUXUqw><img src=https://img.youtube.com/vi/RTkCLQUXUqw/hqdefault.jpg alt='AltTagContent'><span>▶</span></a>"
                  title="0" allowfullscreen=""></iframe>
              </div>
              <div class="sep20 hidden-xs hidden-sm  "></div>
            </figure>
            <section class="post-content">
              <div class="meta" style="height:26px">
                <ul>
                  <li class="grigio">19/12/2012</li>
                  <li class=""><i class=" icon-video blu font15"></i> </li>
                </ul>
              </div>
              <h2>Shoeing with Mustad
                Equi-Librium Aluminum Front</h2>
              <p>
                Tips for a correct cold-fitting of Mustad Equi-Librium Aluminum - by Grant Moon</p>
            </section>
            <div class="clearfix"></div>
          </article>
        </div>
      </div>
    </div>
  </div>
</section>
