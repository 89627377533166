import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ServiceService } from '../services/service.service';

import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(public service: ServiceService, @Inject(PLATFORM_ID) private platformId: Object,public translate: TranslateService) {
    this.service.GroupsList.subscribe(data => this.Groups = data);
   }

   Groups:any=[];


  showcarousel: boolean = false;
  showBlocCarrello: boolean = true;

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.showcarousel = true;
      setTimeout(() => {
        this.GetBrands();
        this.getProdoctEvidenza();
        // this.GetNews();
      }, 400);
    }
    // setTimeout(() => {
    //   this.showBlocCarrello=true;
    // }, 500);
  }

  showMain:boolean=false;
  ngAfterViewInit():void{
    this.showMain=true;
  }

  fakelist:any[] = new Array(8);

  Evidenza: any = [];
  getProdoctEvidenza() {
    this.service.getProductsEvidenza().pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.Evidenza = res;
      }
    )
  }

  Listnews:any=[];
  GetNews(){
    this.service.GetBlogs(1,0, 8,new Date().getFullYear(),'desc','').pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.Listnews = res;
      }
    )
  }

  Brands: any = [];

  GetBrands() {
    var DateBrands = JSON.parse(localStorage.getItem("DB"));
    if (JSON.parse(localStorage.getItem("B")) != null) {
      let DateBrand=new Date(moment(DateBrands,'YYYY-MM-DD:hh:mm').toISOString());
      if (Math.round((new Date().getTime() - DateBrand.getTime()) / 50000) <= 10) {
        this.Brands = JSON.parse(localStorage.getItem('B'));
      } else {
        this.getGetBrandsFromServer();
      }
    } else {
    this.getGetBrandsFromServer();
    }
  }

  getGetBrandsFromServer() {
    this.service.GetBrands(0).pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.Brands = res;
        if (this.Brands.length!=0) {
          localStorage.setItem('B', JSON.stringify(this.Brands));
          localStorage.setItem("DB", JSON.stringify(moment(new Date()).format('YYYY-MM-DD:HH:mm')));
        }
      })
  }

}
