<section class="hero-fake" *ngIf="!showMain"></section>
<section class="hero-section p-0" style="margin-top: -1px;" *ngIf="showMain">
  <!-- <div class="headerShadow snoww"></div> -->
  <!-- <img src="assets/img/PALLINE.png" alt="" class="imgnatale">
  <img src="assets/img/sfera&stella.png" alt="" class="imgnatale2"> -->
  <div class="container">
    <div class="row">
      <!-- <div class="col-lg-3 h-100 orderr">
      </div> -->
      <!-- <div class="col-lg-6 offset-lg-3 orderr">
        <img src="assets/img/rotate.png" class="img-loader rotating">
      </div> -->
      <div class="col-lg-4 offset-lg-8">
        <div class="logo96">
          <span class="l96">#Since1996</span>
          <span class="blck">#BlacksmithItalia</span>
          <img src="assets/img/logo-w.png" alt="BlacksmithItalia logo withe">
        </div>
      </div>
    </div>
    <div class="textabso">
      <h1>
        <b>{{'Home.homepage.natale-1' | translate}}</b>
        <small>{{'Home.homepage.natale-2' | translate}}</small>
      </h1>
      <div class="hrr"></div>
    </div>
  </div>
</section>
<section class="bg-sec">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h2 class="title">{{'Home.section1.cat' | translate}}</h2>
        <!-- <img loading="lazy" class="d-block mt-4 mx-auto" width="160" height="160" *ngIf="Groups.length==0"
          src="assets/img/reload.gif" alt="loader horse"> -->
        <carousel [lightDOM]="'true'" [height]="190" [cellWidth]="210" [dots]="false" [arrows]="true"
          [arrowsOutside]="true" [loop]="true" class="mt-4" *ngIf="showcarousel">

          <ng-container *ngIf="Groups.length==0">
            <div class="carousel-cell pt-2" *ngFor="let item of fakelist">
              <div class="placeholderimg fulll" style="min-height: 74% !important"></div>
              <span class="placeholder sml mt-3"></span>
            </div>
          </ng-container>

          <ng-container *ngFor="let g of Groups">
            <a class="carousel-cell catt"
              routerLink="{{(service.currentLang === 'en' ? '/en/categoria/' : '/categoria/') + g.Id }}">
              <ng-container *ngFor="let i of g.ListImages">
                <img [src]="i.LinkImage" alt="{{g.Description}}" *ngIf="i.IsMainPicture==false">
              </ng-container>
              <b class="nameBrand">{{g.Description}}</b>
            </a>
          </ng-container>
        </carousel>
      </div>
    </div>
  </div>
</section>
<section class="sec-net">
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <div class="box-img">
          <img [lazyLoad]="'assets/img/news.jpg'" alt="News" class="imgabsoo">
          <h2>News</h2>
          <h3>{{'Home.section3.title' | translate}}</h3>
          <a routerLink="{{service.currentLang=='en'?'/en/news':'/news'}}" class="btn btn-w">{{'Home.section3.link' |
            translate}}</a>
        </div>

      </div>
      <div class="col-lg-4">
        <div class="box-img">
          <img [lazyLoad]="'assets/img/educational.jpg'" alt="Educational" class="imgabsoo">
          <h2>Educational</h2>
          <h3>{{'Home.section4.title' | translate}}</h3>
          <a routerLink="{{service.currentLang=='en'?'/en/educational':'/educational'}}"
            class="btn btn-w">{{'Home.section4.link' | translate}}</a>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="box-img">
          <img [lazyLoad]="'assets/img/8.jpg'" alt="Educational" class="imgabsoo">
          <h2>{{'Home.scarica.title' | translate}}</h2>
          <a class="mr-3" href="assets/italia.pdf" *ngIf="translate.store.currentLang=='it'" target="_blank">
            <img [lazyLoad]="'assets/img/catalog.png'" alt="Educational" class="imgabsoo2">
          </a>
          <carousel [lightDOM]="'true'" [height]="250" [cellWidth]="180" [cellsToShow]="1" [dots]="false"
            [arrows]="true" *ngIf="translate.store.currentLang!='it'" [arrowsOutside]="false" [loop]="true"
            style="    margin-top: 77px !important;">
            <a class="mr-3" href="assets/italia.pdf" target="_blank">
              <img [lazyLoad]="'assets/img/catalog-ita.png'" alt="Educational">
            </a>
            <a class="mr-3" href="assets/europe.pdf" target="_blank">
              <img [lazyLoad]="'assets/img/catalog-eur.png'" alt="Educational">
            </a>
            <a href="assets/usa.pdf" target="_blank">
              <img [lazyLoad]="'assets/img/catalog-usa.png'" alt="Educational">
            </a>
          </carousel>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- <section class="bg-section-grey">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h3 class="title">{{'Home.section1.title' | translate}}</h3>
        <p class="mt-4" [innerHTML]="'Home.section1.text' | translate">
        </p>
      </div>
    </div>
  </div>
</section> -->
<section class="bg-sec">
  <div class="container">
    <div class="box">
      <h2 class="title">{{'Home.section2.title' | translate}}</h2>
      <!-- <img loading="lazy" class="d-block mt-4 mx-auto" width="160" height="160" *ngIf="Evidenza.length==0"
        src="assets/img/reload.gif" alt="loader horse"> -->
      <carousel [lightDOM]="'true'" [height]="389" [cellWidth]="290" [dots]="false" [arrows]="true"
        [arrowsOutside]="false" [loop]="true" class="mt-5 couselx" *ngIf="showcarousel">

        <ng-container *ngIf="Evidenza.length==0">
          <div class="carousel-cell pt-2" *ngFor="let item of fakelist">
            <a class="d-flex flex-column card-s" style="border:none !important">
              <div class="placeholderimg"></div>
              <div class="prod-desc bg-white">
                <span class="placeholder sml col-4 mb-3"></span>
                <span class="placeholder sml mb-1"></span>
                <span class="placeholder sml"></span>
                <div class="d-flex justify-content-between">
                  <span class="placeholder col-5"></span>
                  <span class="placeholder col-5"></span>
                </div>
              </div>
            </a>
          </div>
        </ng-container>

        <div class="carousel-cell pt-2" *ngFor="let item of Evidenza">
          <a class="d-flex flex-column card-s mr-3 ml-1"
            routerLink="{{(service.currentLang=='en'?'/en':'') + '/prodotto/' +item.Id}}">
            <div class="ribbon" *ngIf="item.Discount!=null && item.Discount!=0">
              <span>{{item.Discount}}{{item.IsPercentage==true?'%':'€'}}
                <small>{{ "CategorieDettagli.section1.discount" | translate}}</small></span>
            </div>
            <!-- <img  loading="lazy"  src="assets/img/b.png" class="imgb" alt="brand"> -->
            <img [lazyLoad]="'assets/img/new.svg'" class="imgnew" alt="new" *ngIf="item?.NewWeb==true">
            <img [lazyLoad]="item?.urlImages[0]" *ngIf="item.urlImages?.length!=0 && item.urlImages!=null"
              alt="{{item.Title}}">
            <img [lazyLoad]="'assets/no-image.png'"
              *ngIf="!item.urlImages || item.urlImages?.length==0 || item.urlImages==null" alt="blacksmith italia">
            <div class="prod-desc">
              <span class="cat">{{item.Code}}</span>
              <span class="desc">{{item.Title}}</span>
              <div class="mt-auto m-price">
                <h5 *ngIf="!item.StopSellWeb">
                  <ng-container *ngIf="item.haveChildren"> {{ "CategorieDettagli.section1.da" | translate
                    }}</ng-container>
                  {{item.Price | currency : "EUR" : "symbol" : "1.2-2" : "it" }}
                  <small class="soldp" *ngIf="item.Discount!=null && item.Discount!=0">{{item.OriginalPrice | currency :
                    "EUR" : "symbol" :
                    "1.2-2" : "it" }}</small>
                  <span class="ml-3 text-nowrap">{{"CategorieDettagli.section1.iva" | translate }}</span>
                </h5>
                <h5 *ngIf="item.StopSellWeb">
                  {{'ProdDet.section1.richiediInformazioni' | translate}}
                </h5>
              </div>
            </div>
            <div class="shop-btns">
              <button>
                +
              </button>
            </div>
          </a>
        </div>
      </carousel>
    </div>
  </div>
</section>

<!-- <section *ngIf="Listnews.length!=0" class="bg-sec">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h4 class="title">News</h4>
        <carousel [lightDOM]="'true'" [height]="388" [cellWidth]="355" [dots]="false" [arrows]="true" [margin]="20"
          [arrowsOutside]="true" [loop]="true" class="mt-5" *ngIf="showcarousel">
          <article *ngFor="let blog of Listnews" class="carousel-cell my-0 ml-0">
            <img src="{{blog.Base64}}" class="img-fluid" *ngIf="blog.Base64!=null">
            <img src="assets/no-image.png" class="img-fluid" *ngIf="blog.Base64==null">
            <div class="post-content">
              <h5 class="datee">{{blog.DtInsert | date}}</h5>
              <h2>{{blog.Title}}</h2>
              <p> {{blog.ShortDescription}}</p>
              <div class="d-flex mt-3 justify-content-between align-items-center">
                <button routerLink="/news/{{blog.Id}}">Leggi tutto</button>
                <a href=""><img src="assets/img/share.svg" alt="share" style="width: 25px"></a>
              </div>
            </div>
            <div class="clearfix"></div>
          </article>
        </carousel>

        <button class="btn btn-bg ml-auto mt-5" style="max-width:300px" routerLink="/news">Visualizza tutti i
          News</button>
      </div>
    </div>
  </div>
</section> -->

<section *ngIf="Brands.length!=0">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h2 class="title">Brand</h2>
        <carousel [lightDOM]="'true'" [height]="120" [cellWidth]="210" [dots]="false" [arrows]="true"
          [arrowsOutside]="true" [loop]="true" class="mt-5" *ngIf="showcarousel">
          <ng-container *ngFor="let item of Brands">
            <a class="carousel-cell brands" routerLink="/marca/{{item.Description.toLowerCase()}}/{{item.Id}}"
              *ngIf="!item.Link">
              <img [lazyLoad]="item.UrlImage" alt="{{item.Description}}">
              <b class="nameBrand">{{item.Description}}</b>
            </a>
            <a class="carousel-cell brands" [href]="item.Link" target="_blank" *ngIf="item.Link">
              <img [lazyLoad]="item.UrlImage" alt="{{item.Description}}">
              <b class="nameBrand">{{item.Description}}</b>
            </a>
          </ng-container>
        </carousel>
        <div class="d-flex flex-wrap" *ngIf="showcarousel==false">
          <ng-container *ngFor="let item of Brands">
            <ng-container *ngIf="!item.Link">
              <a class="carousel-cell brands" routerLink="/marca/{{item.Description.toLowerCase()}}/{{item.Id}}">
              </a>
            </ng-container>
            <ng-container *ngIf="item.Link">
              <a class="carousel-cell brands" [href]="item.Link" target="_blank">
              </a>
            </ng-container>
          </ng-container>
        </div>
        <button class="btn btn-bg ml-auto mt-5" style="max-width:300px"
          routerLink="{{(service.currentLang=='en'?'/en/marche':'/marche')}}">{{'Home.brand.title' |
          translate}}</button>
      </div>
    </div>
  </div>
</section>

<section class="bg-sec foot">
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <img src="assets/img/footer-user.svg" alt="Account personale" width="38" height="auto">
        <h3 class="title mt-3">{{'Home.topfooter.title1' | translate}}</h3>
        <p>{{'Home.topfooter.p1' | translate}}<br> <a routerLink="/register"
            style="font-weight:500">{{'Home.topfooter.registrati' | translate}}</a></p>
      </div>
      <div class="col-lg-4">
        <img src="assets/img/footer-lock.svg" alt="Pagamento sicuro" width="38" height="auto">
        <h3 class="title mt-3">{{'Home.topfooter.title2' | translate}}</h3>
        <p [innerHTML]="'Home.topfooter.p2' | translate"></p>
        <div class="d-flex jcc-mb">
          <img alt="paypal blacksmith" [lazyLoad]="'assets/img/paypal.png'" alt="paypal" height="40" width="70">
          <img alt="visa blacksmith" [lazyLoad]="'assets/img/visa.png'" alt="visa" height="40" width="70">
          <img alt="mastercard blacksmith" [lazyLoad]="'assets/img/mastercard.png'" alt="mastercard" height="40"
            width="70">
        </div>
      </div>
      <div class="col-lg-4">
        <img src="assets/img/footer-consegna.svg" alt="Consegna rapida" width="42" height="auto">
        <h3 class="title mt-3">{{'Home.topfooter.title3' | translate}}</h3>
        <p>{{'Home.topfooter.p3' | translate}}</p>
      </div>
    </div>
  </div>
</section>
