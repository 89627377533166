<ul class="menu-list">
    <li class="big-cat" routerLink="{{service.currentLang=='en'?'/en/prodotti':'/prodotti'}}"><b>{{'menu.all' | translate}}</b></li>
    <hr>
    <li *ngFor="let g of Groups" routerLink="{{(service.currentLang === 'en' ? '/en/categoria/' : '/categoria/') + g.Id }}">
        <a href="#"  routerLink="{{(service.currentLang === 'en' ? '/en/categoria/' : '/categoria/') + g.Id}}">{{g.Description}}</a>
    </li>
    <hr>
    <li class="small-cat"><a   routerLink="{{service.currentLang=='en'?'/en/chi-siamo':'/chi-siamo'}}"><b>{{'menu.m1' | translate}}</b></a></li>
    <li class="small-cat"><a  routerLink="{{service.currentLang=='en'?'/en/prodotti':'/prodotti'}}"><b>{{'menu.m2' | translate}}</b></a></li>
    <li class="small-cat"><a  routerLink="{{service.currentLang=='en'?'/en/educational':'/educational'}}"><b>{{'menu.m3' | translate}}</b></a></li>
    <li class="small-cat"><a  routerLink="{{service.currentLang=='en'?'/en/news':'/news'}}"><b>{{'menu.m4' | translate}}</b></a></li>
    <li class="small-cat"><a  routerLink="{{service.currentLang=='en'?'/en/contatti':'/contatti'}}"><b>{{'menu.m5' | translate}}</b></a></li>
</ul>

