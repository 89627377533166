<section class="footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <h4>BLACKSMITH SRL</h4>
        <p class="mb-3">P.IVA 02685520161 | REA: BG-291485</p>
        <p>Via Monaco, 16 24040 Ciserano (BG)</p>
        <a href="tel:+39354820791">+39 035/4820791</a>
        <p><a href="mailto:info@blacksmithitalia.it">info@blacksmithitalia.it</a> | <a
            href="mailto:blackh@pec.blacksmithsnc.com">blackh@pec.blacksmithsnc.com</a></p>
      </div>
      <div class="col-lg-3 col-sm-6">
        <h4>{{'Footer.section1.h41' | translate}}</h4>
        <!-- <a href="">FAQ</a> -->
        <a routerLink="/servizio-clienti">{{'Footer.section1.m1' | translate}}</a>
        <a routerLink="/contatti">{{'Footer.section1.m2' | translate}}</a>
        <a routerLink="/informativa-privacy">{{'Footer.section1.m3' | translate}}</a>
        <a routerLink="/cookie-policy">{{'Footer.section1.m4' | translate}}</a>
      </div>
      <div class="col-lg-3 col-sm-6">
        <h4>{{'Footer.section1.h42' | translate}}</h4>
        <a routerLink="/termini-condizioni">{{'Footer.section1.m5' | translate}}</a>
        <a routerLink="/modalita-di-pagamento">{{'Footer.section1.m6' | translate}}</a>
        <a routerLink="/condizioni-di-spedizione">{{'Footer.section1.m7' | translate}}</a>
        <a routerLink="/reso-prodotti">{{'Footer.section1.m8' | translate}}</a>
      </div>
    </div>
    <div class="line-x"></div>
    <div class="row">
      <div class="col-lg-6">
        <div class="d-flex jcc-mb">
          <a href="https://www.instagram.com/blacksmithitalia/" target="_blank">
            <img alt="insta blacksmith" [lazyLoad]="'assets/img/insta.svg'" height="32" width="32" class="mr-2">
          </a>
          <a href="https://www.youtube.com/channel/UCXhji19WjayYfaRw5P8PDew" target="_blank"><img alt="youtube blacksmith" [lazyLoad]="'assets/img/youtube.svg'" height="32" width="32"
              class="mr-2"></a>
          <a href="https://www.facebook.com/blacksmithitalia" target="_blank"><img alt="facebook blacksmith" [lazyLoad]="'assets/img/facebook.png'" height="32" width="32"
              class="mr-2"></a>
        </div>
      </div>
      <div class="col-lg-6">
        <p class="text-right">Powered by <a href="https://www.itd-italia.com/" target="_blank">ITD</a></p>
      </div>
    </div>
  </div>
</section>
