<section class="bg-nero">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <h1 class="title">{{'Azienda.section1.title' | translate}}</h1>
        <p class="my-4" [innerHTML]="'Azienda.section1.text' | translate"></p>
      </div>
      <div class="col-lg-6 mb-3 mb-lg-0">
        <!-- <img  loading="lazy" src="assets/img/markus-spiske.jpeg" alt="chi siamo blacksmith" class="imgob" height="320" width="100%"> -->
        <div class="logo96"><span _ngcontent-serverapp-c56="" class="l96">#Since1996</span><span
            class="blck">#BlacksmithItalia</span><img src="assets/img/logo-w.png" alt="BlacksmithItalia logo withe"></div>
      </div>
    </div>
  </div>
</section>

<section class="bg-sec">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <span class="st">“</span>
        <p class="my-4">
          {{'Azienda.section2.text' | translate}}<br><br>
          {{'Azienda.section2.text2' | translate}}<br><br>
          {{'Azienda.section2.text3' | translate}}


        </p>
        <h4 class="title-i mb-4">{{'Azienda.section2.h4' | translate}}</h4>
      </div>
    </div>
  </div>
</section>
