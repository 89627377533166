<section class="bg-section-grey" style="min-height: 75vh">
  <div class="container">
    <div class="row">
      <div class="col-lg-3">
        <ul class="list-group">
          <li class="list-group-item" routerLink="/servizio-clienti">{{'Cookie.menu.m1' | translate}}</li>
          <li class="list-group-item" routerLink="/informativa-privacy">{{'Cookie.menu.m2' | translate}} </li>
          <li class="list-group-item active" routerLink="/servizio-clienti">{{'Cookie.menu.m3' | translate}} </li>
        </ul>
      </div>
      <div class="col-lg-9">
        <div class="main-carrello">
          <h1 class="title mb-4">{{'Cookie.menu.m3' | translate}}</h1>
          <div style="padding:10px">
            <h2 class="blu">
              {{'Cookie.section1.title' | translate}}</h2>
            <p>
              {{'Cookie.section1.text' | translate}}
              <br>
              {{'Cookie.section1.text2' | translate}}
              <br>
              <br>
              {{'Cookie.section1.text3' | translate}}
              <br>
              {{'Cookie.section1.text4' | translate}}
              <br>
              {{'Cookie.section1.text5' | translate}}
            </p>
            <h2 class="blu">
              <br>
              {{'Cookie.section2.title' | translate}}
            </h2>
            <h3 class="blu">
              {{'Cookie.section2.title2' | translate}} </h3>
              {{'Cookie.section2.text' | translate}}

            <h3 class="blu">
              <br>
              {{'Cookie.section2.title3' | translate}}

            </h3>
            {{'Cookie.section2.text2' | translate}}
            <br>
            {{'Cookie.section2.text3' | translate}}
            <br>
            <a href="http://www.google.com/policies/privacy/" rel="nofollow" style="text-decoration:underline"
              target="_blank">{{'Cookie.section2.text4' | translate}} </a><br>
            <a href="https://tools.google.com/dlpage/gaoptout?hl=it" rel="nofollow" style="text-decoration:underline"
              target="_blank">{{'Cookie.section2.text5' | translate}}</a>
            <h3 class="blu">
              <br>
              {{'Cookie.section2.title4' | translate}}

            </h3>
            <strong>AddThis</strong><br>
            {{'Cookie.section2.text6' | translate}}
           <br>
           {{'Cookie.section2.text7' | translate}}
           <br>
           {{'Cookie.section2.text8' | translate}}
           <br>
           {{'Cookie.section2.text9' | translate}}
            <br>
            <a href="http://www.addthis.com/privacy" rel="nofollow" style="text-decoration:underline"
              target="_blank">{{'Cookie.section2.text10' | translate}}</a><br>
            <a href="http://www.addthis.com/privacy/opt-out" rel="nofollow" style="text-decoration:underline"
              target="_blank">{{'Cookie.section2.text11' | translate}}</a><br>
            <br>
            <strong>{{'Cookie.section2.text12' | translate}}</strong><br>
            {{'Cookie.section2.text13' | translate}}<br>
            {{'Cookie.section2.text14' | translate}}<br>
            {{'Cookie.section2.text15' | translate}}<br>
            {{'Cookie.section2.text16' | translate}}<br>
            {{'Cookie.section2.text17' | translate}}<br>
            <br>
            <a href="http://www.google.com/policies/privacy/" rel="nofollow" style="text-decoration:underline"
              target="_blank">{{'Cookie.section2.text4' | translate}}</a><br>
            <p><strong><br>
              {{'Cookie.section2.text19' | translate}}</strong> <br>
              <br>
              {{'Cookie.section2.text20' | translate}}

            </p>
            <ul>
              <li><a href="https://support.google.com/chrome/answer/95647?hl=it" rel="nofollow"
                  target="_blank"><u>Google Chrome</u></a></li>
              <li><a href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie" rel="nofollow"
                  target="_blank"><u>Mozilla Firefox</u></a></li>
              <li><a href="http://windows.microsoft.com/it-it/internet-explorer/delete-manage-cookies#ie=ie-11"
                  rel="nofollow" target="_blank">Internet Explorer</a></li>
              <li><a href="http://support.apple.com/kb/HT1677?viewlocale=it_IT" rel="nofollow"
                  target="_blank"><u>Safari</u></a></li>
              <li><a href="http://help.opera.com/Windows/10.00/it/cookies.html" rel="nofollow"
                  target="_blank"><u>Opera</u></a></li>
            </ul>
            <div id="cookiedec" class="mt-4"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
