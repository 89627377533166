import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CondizioniDiSpedizioneComponent } from './condizioni-di-spedizione.component';

const routes: Routes = [{ path: '', component: CondizioniDiSpedizioneComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CondizioniRoutingModule { }
