import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ProfileRoutingModule } from './profile-routing.module';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ProfileComponent } from './profile.component';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';

@NgModule({
  declarations: [
    ProfileComponent
  ],
  imports: [
    TranslateModule,
    CommonModule,
    ProfileRoutingModule,
    NgxIntlTelInputModule,
    FormsModule,
    MatIconModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatRadioModule
  ],
})
export class ProfileModule { }
