<section class="bg-section-grey" style="min-height: 75vh">
  <div class="container">
    <div class="row">
      <div class="col-lg-3">
        <ul class="list-group">
          <li class="list-group-item" routerLink="/servizio-clienti">
            {{ "Cookie.menu.m1" | translate }}
          </li>
          <li class="list-group-item active" routerLink="/informativa-privacy">
            {{ "Cookie.menu.m2" | translate }}
          </li>
          <li class="list-group-item" routerLink="/cookie-policy">
            {{ "Cookie.menu.m3" | translate }}
          </li>
        </ul>
      </div>
      <div class="col-lg-9">
        <div class="main-carrello">
          <h1 class="title mb-4">{{ "Cookie.menu.m2" | translate }}</h1>

          <h2 class="blu peso100">
            {{ "Privacy.section1.title" | translate }}
          </h2>
          <div [innerHTML]="'Privacy.section1.text' | translate">
          </div>
          <div [innerHTML]="'Privacy.section1.text2' | translate">
          </div>
          <div [innerHTML]="'Privacy.section1.text3' | translate">
          </div>
          <div [innerHTML]="'Privacy.section1.text4' | translate">
          </div>
          <div [innerHTML]="'Privacy.section1.text5' | translate">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
