import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrandComponent } from './brand/brand.component';
import { BrandsComponent } from './brands/brands.component';
import { CategoriesComponent } from './categories/categories.component';
import { CategoryDetailsComponent } from './category-details/category-details.component';
import { HomeComponent } from './home/home.component';
import { ProductsDetailsComponent } from './products-details/products-details.component';
import { ProductsComponent } from './products/products.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { ArticleComponent } from './article/article.component';

const routes: Routes = [
  { path: 'categoria/:IdCat/:IdSCat', component: CategoryDetailsComponent },
  { path: 'categoria/:IdSCat', component: CategoryDetailsComponent },
  { path: 'products', component:ProductsComponent },
  { path: 'prodotto/:IdCat/:IdProduct', component: ProductsDetailsComponent },
  { path: 'prodotto/:IdProduct', component: ProductsDetailsComponent },
  { path: 'prodotti', component: CategoriesComponent },
  { path: 'header', loadChildren: () => import('./header/header.module').then(m => m.HeaderModule) },
  { path: 'menu', loadChildren: () => import('./menu/menu.module').then(m => m.MenuModule) },
  { path: 'carrello', loadChildren: () => import('./carrello/carrello.module').then(m => m.CarrelloModule) },
  { path: 'area-riservata', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  { path: 'register', loadChildren: () => import('./register/register.module').then(m => m.RegisterModule) },
  { path: 'pagamento', loadChildren: () => import('./pagamento/pagamento.module').then(m => m.PagamentoModule) },
  { path: 'news', loadChildren: () => import('./news/news.module').then(m => m.NewsModule) },
  { path: 'news/:id', component: ArticleComponent },
  { path: 'educational', loadChildren: () => import('./educational/educational.module').then(m => m.EducationalModule) },
  { path: 'contatti', loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule) },
  { path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule) },
  { path: 'chi-siamo', loadChildren: () => import('./azienda/azienda.module').then(m => m.AziendaModule) },
  { path: 'condizioni-di-spedizione', loadChildren: () => import('./condizioni-di-spedizione/condizioni.module').then(m => m.CondizioniModule) },
  { path: 'modalita-di-pagamento', loadChildren: () => import('./modalita-di-pagamento/modalita.module').then(m => m.ModalitaModule) },
  { path: 'informativa-privacy', loadChildren: () => import('./privacy/privacy.module').then(m => m.PrivacyModule) },
  { path: 'ordine-result/:bonifico/:estero', loadChildren: () => import('./result-success/success.module').then(m => m.SuccessModule) },
  { path: 'ordine-errore', loadChildren: () => import('./result-error/error.module').then(m => m.ErrorModule) },
  { path: 'cookie-policy', loadChildren: () => import('./cookie-policy/cookie.module').then(m => m.CookieModule) },
  { path: 'reso-prodotti', loadChildren: () => import('./reso-prodotti/reso.module').then(m => m.ResoModule) },
  { path: 'servizio-clienti', loadChildren: () => import('./servizio-clienti/servizio.module').then(m => m.ServizioModule) },
  { path: 'termini-condizioni', loadChildren: () => import('./termini-condizioni/termini.module').then(m => m.TerminiModule) },
  { path: 'result/:search', loadChildren: () => import('./result/result.module').then(m => m.ResultModule) },
  { path: 'marca/:name/:id', component: BrandComponent },
  { path: 'home', component: HomeComponent},
  { path: 'footer', loadChildren: () => import('./footer/footer.module').then(m => m.FooterModule) },
  { path: 'marche', component: BrandsComponent },
  { path: 'wishlist', component: WishlistComponent },
  // { path: 'sitemap', component: SitemapComponent },

  { path: 'en/home', component: HomeComponent},
  // { path: 'en', component: HomeComponent},
  { path: 'en/chi-siamo', loadChildren: () => import('./azienda/azienda.module').then(m => m.AziendaModule) },
  { path: 'en/prodotti', component: CategoriesComponent },
  { path: 'en/educational', loadChildren: () => import('./educational/educational.module').then(m => m.EducationalModule) },
  { path: 'en/news', loadChildren: () => import('./news/news.module').then(m => m.NewsModule) },
  { path: 'en/contatti', loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule) },
  { path: 'en/categoria/:IdCat/:IdSCat', component: CategoryDetailsComponent },
  { path: 'en/categoria/:IdSCat', component: CategoryDetailsComponent },
  { path: 'en/prodotto/:IdCat/:IdProduct', component: ProductsDetailsComponent },
  { path: 'en/prodotto/:IdProduct', component: ProductsDetailsComponent },
  { path: 'en/marche', component: BrandsComponent },
  { path: 'en/wishlist', component: WishlistComponent },

  // { path: 'en', redirectTo: 'en/home', pathMatch: 'full' },
  { path: '', component: HomeComponent },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes),
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

