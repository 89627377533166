import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reso-prodotti',
  templateUrl: './reso-prodotti.component.html',
  styleUrls: ['./reso-prodotti.component.css']
})
export class ResoProdottiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
