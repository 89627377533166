import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SuccessRoutingModule } from './success-routing.module';
import { ResultSuccessComponent } from './result-success.component';

@NgModule({
  declarations: [
    ResultSuccessComponent
  ],
  imports: [
    TranslateModule,
    CommonModule,
    SuccessRoutingModule,
  ],
})
export class SuccessModule { }
