<section class="bg-section-grey" style="min-height: 75vh;padding: 30px 0">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
          <h2 class="title mb-4">{{'Result.section1.title' | translate}}</h2>
          <div>
            <div>
              <span class="result mr-auto" *ngIf="totalProducts!=0"><b>{{totalProducts}}</b> {{'Result.section1.subtitle' | translate}} </span>
              <span class="result mr-auto" *ngIf="products.length==0 && loader==false">{{'Result.section1.noresult' | translate}} <b>{{search}}</b>. </span><br>
              <span class="result mr-auto" *ngIf="products.length==0 && loader==false">{{'Result.section1.noresult2' | translate}} </span>
              <!-- <div class="d-flex" *ngIf="products.length!=0 && loader==false">
                <button class="mr-2 btn btn-w p-0" title="Prezzo CRESCENTE" (click)="orderPriceAsc=true;orderPriceDesc=false;GetProducts()"><img  loading="lazy"  src="assets/img/top-arrow.svg" width="20"
                    class="my-0 m-2"></button>
                <button class="mr-2 btn btn-w p-0" title="Prezzo DECRESCENTE" (click)="orderPriceDesc=true;orderPriceAsc=false;GetProducts()"><img  loading="lazy"  src="assets/img/down-arrow.svg"
                    width="20" class="my-0 m-2"></button>
              </div> -->
            </div>
            <div class="d-flex flex-wrap my-4 justify-content-center justify-content-xl-start" *ngIf="products.length!=0">
              <span *ngIf="products.length==0 && loader==false">{{'Result.section1.noprod' | translate}}</span>
              <a class="d-flex flex-column card-s" *ngFor="let item of products"
                routerLink="{{(service.currentLang=='en'?'/en':'') + '/prodotto/' + item.Id}}">
                <div class="ribbon" *ngIf="item.Discount!=null && item.Discount!=0"><span>{{item.Discount}}{{item.IsPercentage==true?'%':'€'}}
                  <small>{{ "CategorieDettagli.section1.discount" | translate}}</small></span></div>
                <!-- <img  loading="lazy"  src="assets/img/b.png" class="imgb"> -->
                <img src="assets/img/new.svg" class="imgnew" alt="new" *ngIf="item?.NewWeb==true">
                <img loading="lazy" src="{{item?.urlImages[0]}}" *ngIf="item.urlImages?.length!=0 && item.urlImages!=null" alt="{{item.Title}}">
                <img loading="lazy" src="assets/no-image.png" *ngIf="!item.urlImages || item.urlImages?.length==0 || item.urlImages==null">
                <div class="prod-desc">
                  <span class="desc">{{item.Title}}</span>
                  <span class="p-cat">{{item.Code}}</span>
                  <div class="m-price">
                    <h5 *ngIf="!item.StopSellWeb"><ng-container *ngIf="item.haveChildren">{{'CategorieDettagli.section1.da' | translate}}</ng-container>
                      {{item.Price | currency : "EUR" : "symbol" : "1.2-2" : "it" }}
                      <small class="soldp" *ngIf="item.Discount!=null && item.Discount!=0">{{item.OriginalPrice | currency : "EUR" : "symbol" :
                        "1.2-2" : "it" }}</small>
                      <span class="ml-3 text-nowrap">{{'CategorieDettagli.section1.iva' | translate}}</span></h5>
                      <h5 *ngIf="item.StopSellWeb">
                        {{'ProdDet.section1.richiediInformazioni' | translate}}
                      </h5>
                  </div>
                </div>
                <div class="shop-btns">
                  <button>
                    +
                  </button>
                </div>
              </a>
            </div>

            <img loading="lazy" class="d-block my-4 mx-auto" width="160" height="160" *ngIf="loader==true"
            src="assets/img/reload.gif" alt="loader horse">

          </div>
      </div>
    </div>
  </div>
</section>
