import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from '../services/service.service';
import { MessengerService } from '../services/messenger.service';
import { MatStepper } from '@angular/material/stepper';
import { TranslateService } from '@ngx-translate/core';
import { catchError, share } from 'rxjs/operators';

declare var paypal: any;

interface OrderResponse {
  id: string;
}

@Component({
  selector: 'app-pagamento',
  templateUrl: './pagamento.component.html',
  styleUrls: ['./pagamento.component.css']
})
export class PagamentoComponent implements OnInit {

  constructor(private router: Router, private service: ServiceService, private messanger: MessengerService, public translate: TranslateService) {
    this.currentLang = translate.store.currentLang;
  }

  currentLang;
  TotalCarrello;
  TotalSconte;
  totalePeso = 0;
  Profilo: any = [];

  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem("login")) == 'true') {
      this.Profilo = JSON.parse(localStorage.getItem("profil"));
      this.nome = this.Profilo.Name;
      this.cognome = this.Profilo.Surname;
      this.email = this.Profilo.Email;
      this.tel = this.Profilo.PhoneNumber == null ? this.Profilo.MobileNumber : this.Profilo.PhoneNumber;
      setTimeout(() => {
        this.GetCustomerTypes();
        this.GetAddresses(this.Profilo.IdGestionale);
        this.GetDefaultVat(this.Profilo.IdGestionale);
        this.GetCountryCodes();
        this.GetProvinces();
        this.GetDatiFat(this.Profilo.IdGestionale);
        this.GetConfigurationVariable();
        this.GetVatExigibility();
      }, 500);
    } else {
      this.router.navigate(['/area-riservata'],
        { queryParams: { paga: true } });
    }

    if (JSON.parse(localStorage.getItem("carrello")) != null) {
      var c = JSON.parse(localStorage.getItem("carrello"));
      for (let i = 0; i < c.length; i++) {
        this.carrello.push({ Code: c[i].Code, Title: c[i].Title, Weight: c[i].Weight, Description: c[i].Description, qte: c[i].qte, VatPrice: c[i].Price, IsPriceVat: true, total: c[i].Price * c[i].qte, Um: '', VatCode: c[i].VatCode, Aggiunte: [], OriginalPrice: c[i].OriginalPrice, Discount: c[i].Discount })
        this.itemsGA4.push({ item_id: c[i].Code, item_name: c[i].Title, item_variant: c[i].Weight, price: c[i].Price, quantity: c[i].qte })
      }
      if (this.carrello.length > 0) {
        this.TotalCarrello = this.carrello.map(a => a.VatPrice * a.qte).reduce(function (a, b) {
          return a + b;
        });
        this.TotalSconte = this.carrello.map(a => a.Discount != null ? (a.OriginalPrice - a.VatPrice) * a.qte : 0).reduce(function (a, b) {
          return a + b;
        });
        this.totalePeso = this.carrello.map(a => a.Weight).reduce(function (a, b) {
          return a + b;
        });
        this.TotalCarrello = (Math.round(this.TotalCarrello * 100) / 100);
        this.TotalSconte = (Math.round(this.TotalSconte * 100) / 100);
      } else {
        this.TotalCarrello = 0;
        this.TotalSconte = 0;
        this.totalePeso = 0;
      }
    } else {
      this.TotalCarrello = 0;
      this.TotalSconte = 0;
      this.router.navigate(['/carrello']);
    }
  }


  provinces: any = [];
  GetProvinces() {
    this.service.GetProvinces().pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.provinces = res;
      }
    )
  }

  VatExigibility: any = [];
  GetVatExigibility() {
    if (this.VatExigibility.length == 0) {
      this.service.GetVatExigibility().pipe(
        catchError(error => {
          return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
        })
      ).subscribe(
        res => {
          this.VatExigibility = res;
        }
      )
    }
  }

  TipologieSpedizione: any = [];
  GetCarriers() {
    this.service.GetCarriers().pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.TipologieSpedizione = res;
        if (this.TipologieSpedizione.length == 1) {
          this.codeconsegna = this.TipologieSpedizione[0].Code;
          this.getPriceConsegna(this.TipologieSpedizione[0].Code);
        }
        this.GettipologiaPagamento();
      }
    )
  }


  PaymentType = -1;
  paymentPrice = 0;
  PayWeb = false;
  BankRequired = false;
  Tipologiepagamento: any = [];
  GettipologiaPagamento() {
    this.service.GettipologiaPagamento(this.TotalCarrello + this.consegna, this.DefaultVat.Id).pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.Tipologiepagamento = res;
        setTimeout(() => {
          this.changeCountry();
        }, 1100);
      }
    )
  }

  changeCountry() {
    if (this.FCountry != 'Italia') {
      this.FProvincia = "EE";
      this.Fsdi = 'XXXXXXX';
      let tip = this.Tipologiepagamento.filter(x => x.Code == 15);
      this.PaymentType = tip[0]?.Code;
      this.updateFattError = '';
      tip[0]?.ExtraAmount != null ? this.paymentPrice = tip[0]?.ExtraAmount : this.paymentPrice = 0; this.PayWeb = tip[0]?.PayWeb;
    } else {
      this.FProvincia = this.DatiFatturazioni.InvoiceData.Prov != null ? this.DatiFatturazioni.InvoiceData.Prov : "";
      this.Fsdi = '';
      this.PaymentType = -1;
      this.paymentPrice = 0;
      this.PayWeb = false;
    }
    this.getPriceConsegna(this.codeconsegna);
  }

  getPriceConsegna(code) {
    if (this.selectedAdress[0]?.Country != 'Italia' || this.selectedAdress[0]?.Prov == 'EE') {
      this.consegna = 0;
    } else {
      var getdata = this.TipologieSpedizione.filter(x => x.Code == code);
      for (let i = 0; i < getdata.length; i++) {
        if (this.TotalCarrello >= getdata[i].FreePriceOver && getdata[i].FreePriceOver != null) {
          this.consegna = 0;
        }
        if ((this.TotalCarrello < getdata[i].FreePriceOver || getdata[i].FreePriceOver == null) && getdata[i].FixedPrice != null) {
          this.consegna = getdata[i].FixedPrice
        }
        if ((this.TotalCarrello < getdata[i].FreePriceOver || getdata[i].FreePriceOver == null) && getdata[i].FixedPrice == null && getdata[i].ListRates.length != 0) {
          for (let y = 0; y < getdata[i].ListRates.length; y++) {
            if (this.totalePeso >= getdata[i].ListRates[y].FromValue && this.totalePeso < getdata[i].ListRates[y].ToValue) {
              this.consegna = getdata[i].ListRates[y].Price;
            }
            if (this.totalePeso >= getdata[i].ListRates[y].FromValue && getdata[i].ListRates[y].ToValue == null) {
              this.consegna = getdata[i].ListRates[y].Price;
            }
          }
        }
      }
    }
  }


  changePayment(event, item) {
    if (event.checked == true) {
      this.PaymentType = item.Code;
      this.updateFattError = '';
      item.ExtraAmount != null ? this.paymentPrice = item.ExtraAmount : this.paymentPrice = 0;
      this.PayWeb = item.PayWeb;
      this.BankRequired = item.BankRequired
    } else {
      this.PaymentType = -1;
      this.paymentPrice = 0;
    }
  }

  changeSpedizione(event, item) {
    if (event.checked == true) {
      this.codeconsegna = item.Code;
      this.updateFattError = '';
      this.getPriceConsegna(item.Code);
    } else {
      this.codeconsegna = '';
      this.consegna = 0;
      this.PaymentType = -1;
      this.paymentPrice = 0;
    }
  }



  Cities: any = [];
  GetCities() {
    this.service.GetCities().pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.Cities = res;
      }
    )
  }
  CountryCodes: any = [];
  GetCountryCodes() {
    this.service.GetCountryCodes().pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.CountryCodes = res;
      }
    )
  }

  MunicipalitiesList: any = [];
  GetMunicipalities() {
    this.service.GetCities().pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.MunicipalitiesList = res;
      }
    )
  }

  CustomerTpes: any = [];
  GetCustomerTypes() {
    this.service.GetCustomerTypes().pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.CustomerTpes = res;
      }
    )
  }

  GetAddressesList: any = [];
  Loadaddress: boolean = false;
  GetAddresses(IdContact) {
    this.Loadaddress = true;
    this.GetAddressesList = [];
    this.service.GetAddresses(IdContact).pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.GetAddressesList = res;
        this.selectedAdress = [];
        this.selectedAdress = this.GetAddressesList.filter(x => x.Default == true);
        this.GetAddressesList = this.GetAddressesList.sort((a, b) => b.Default - a.Default);
        this.Loadaddress = false;
        this.getPriceConsegna(this.codeconsegna);
      }
    )
  }

  DefaultVat: any = [];
  GetDefaultVat(IdContact) {
    this.service.GetDefaultVat(IdContact).pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.DefaultVat = res;
        this.GetCarriers();
      }
    )
  }

  selectedAdress: any = [];
  addNewAdress: boolean = false;

  selectAdress(item) {
    this.selectedAdress = [];
    this.selectedAdress.push(item);
    this.getPriceConsegna(this.codeconsegna);
  }


  nomeReferente;
  Descrizione;
  Indirizzo;
  Citta;
  cap;
  Paese = 'Italia';
  Telefono;
  provincia;
  StreetNumber;
  errorAddAdress = '';
  addAdressResult: any = [];
  errorcheckAddress: boolean = false;
  addAdress(IdGestionale) {
    if (this.nomeReferente != '' && this.Telefono != null && this.Indirizzo != '' && this.provincia != '' && this.Citta != '' && this.cap != '' && this.Paese != '' && this.StreetNumber != '') {
      this.service.addAdress(this.Descrizione, this.nomeReferente, this.Telefono.e164Number, this.Indirizzo, this.provincia, this.Citta, this.cap, this.Paese, IdGestionale, this.StreetNumber).pipe(
        catchError(error => {
          return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
        })
      ).subscribe(
        res => {
          this.addAdressResult = res;
          this.GetAddresses(IdGestionale);
          this.openAddAdr = false;
          this.clearAdr();
          this.updateFattError = '';
          this.IndirizzospedErrorMsg = '';
        }
      )
    } else {
      this.errorAddAdress = this.currentLang == 'it' ? 'Compila i dati obbligatori' : 'Fill in the mandatory data';
    }
  }

  clearAdr() {
    this.nomeReferente = '';
    this.Descrizione = '';
    this.Indirizzo = '';
    this.Citta = '';
    this.cap = '';
    this.Paese = 'Italia';
    this.Telefono = '';
    this.provincia = '';
    this.StreetNumber = '';
  }

  openAddAdr: boolean = false;


  DatiFatturazioni: any = [];
  GetDatiFat(id) {
    this.service.GetDatiFat(id).pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.DatiFatturazioni = res;

        this.FDatadinascita = this.DatiFatturazioni.InvoiceData.BirthDate == null ? '' : (this.DatiFatturazioni.InvoiceData.BirthDate).slice(0, -9);
        this.Fiva = this.DatiFatturazioni.InvoiceData.PIva == null ? '' : this.DatiFatturazioni.InvoiceData.PIva;
        this.Fcf = this.DatiFatturazioni.InvoiceData.CodFis == null ? '' : this.DatiFatturazioni.InvoiceData.CodFis;
        this.Fsdi = this.DatiFatturazioni.InvoiceData.RecipientCodeFe == null ? '' : this.DatiFatturazioni.InvoiceData.RecipientCodeFe;
        this.Fpec = this.DatiFatturazioni.InvoiceData.EmailPec == null ? '' : this.DatiFatturazioni.InvoiceData.EmailPec;
        this.FCitta = this.DatiFatturazioni.InvoiceData.City;
        this.FProvincia = this.DatiFatturazioni.InvoiceData.Prov;
        this.Fcap = this.DatiFatturazioni.InvoiceData.Cap;
        this.Esigibilita = this.DatiFatturazioni.InvoiceData.Vatexigibility != null ? this.DatiFatturazioni.InvoiceData.Vatexigibility : 'I';
        this.Tipologie = this.DatiFatturazioni.InvoiceData.CustomerTypeFe != null ? this.DatiFatturazioni.InvoiceData.CustomerTypeFe : 'IVA';
        this.FCountry = this.DatiFatturazioni.InvoiceData.Country == null ? 'Italia' : this.DatiFatturazioni.InvoiceData.Country;
        this.FIndirizzo = this.DatiFatturazioni.InvoiceData.Address;
        this.Fnome = this.DatiFatturazioni.InvoiceData.Name;
        this.Fcognome = this.DatiFatturazioni.InvoiceData.Surname;
        this.FragioneSociale = this.DatiFatturazioni.InvoiceData.CompanyName;
        this.Fcivico = this.DatiFatturazioni.InvoiceData.StreetNumber;
      }
    )
  }

  nome;
  cognome;
  CompanyName;
  email;
  tel;
  AgreeUseData;
  FDatadinascita;
  Fiva = '';
  Fcf = '';
  Fsdi = '';
  Fpec = '';
  FCitta;
  FProvincia;
  Fcap;
  Esigibilita = 'I';
  Tipologie = 'IVA';
  FCountry = 'Italia';
  FIndirizzo;
  Fcivico;
  Fnome = '';
  Fcognome = '';
  FragioneSociale = '';

  note;

  updateFatt: boolean = false;
  updateFattError = '';
  SaveContactResult: any = [];
  SaveContact(methodPaga) {
    if (this.fattura == false) {
      this.CreateOrder(methodPaga);
    } else {
      if ((this.FragioneSociale != '' || (this.Fnome != '' && this.Fcognome != '')) && ((this.Tipologie != 'IVA' ? (this.Fiva != '' || this.Fcf != '') : this.Fcf != '') && (this.Tipologie != 'IVA' ? (this.Fsdi != '' || this.Fpec != '') : 1 == 1)) && (this.FCitta != '' && this.FProvincia != '' && this.Fcap != '' && this.Tipologie != '' && this.Esigibilita != '') && this.erreurCodiceFs == false && this.emailErrorformat == false && this.erreurSDI == false && this.erreurFiva == false) {
      } else {
        this.updateFattError = this.currentLang == 'it' ? 'Compila i dati obbligatori!' : 'Fill in the mandatory data';
        this.myStepper.selectedIndex = 0;
        this.checkDatiFatturazione();
        return;
      }
      if (this.selectedAdress.length == 0) {
        this.updateFattError = this.currentLang == 'it' ? 'Devi aggiungere un indirizzo di spedizione!' : 'You must add a shipping address!';
        this.myStepper.selectedIndex = 1;
        this.checkIndirizzosped();
        return;
      }
      if (this.codeconsegna == '' && this.FCountry == 'Italia') {
        this.updateFattError = this.currentLang == 'it' ? 'Scegliere una tipologia di consegna!' : 'Choose a type of delivery!';
        this.myStepper.selectedIndex = 2;
        return;
      }
      if (this.PaymentType == -1) {
        this.updateFattError = this.currentLang == 'it' ? 'Scegliere una tipologia di pagamento!' : 'Choose a payment type!';
        this.myStepper.selectedIndex = 2;
        return;
      }
      if (this.PaymentType == -1 && this.codeconsegna == '' && this.FCountry == 'Italia') {
        this.updateFattError = this.currentLang == 'it' ? 'Scegliere una tipologia di pagamento e di consegna!' : 'Choose a payment and delivery type!';
        this.myStepper.selectedIndex = 2;
        return;
      }

      this.CreateOrder(methodPaga);

    }
  }

  @ViewChild('stepper') private myStepper: MatStepper;

  datiFattErrorMsg = '';
  checkDatiFatturazione() {
    if ((this.FragioneSociale != '' || (this.Fnome != '' && this.Fcognome != '')) && ((this.Tipologie != 'IVA' ? (this.Fiva != '' || this.Fcf != '') : this.Fcf != '') && (this.Tipologie != 'IVA' ? (this.Fsdi != '' || this.Fpec != '') : 1 == 1)) && (this.FCitta != '' && this.FProvincia != '' && this.Fcap != '' && this.Tipologie != '' && this.Esigibilita != '' && this.FCountry != '') && this.erreurCodiceFs == false && this.emailErrorformat == false && this.erreurSDI == false && this.erreurFiva == false) {
      this.service.SaveContact(this.AgreeUseData, this.nome, this.cognome, this.email, this.tel, null, this.Profilo.IdGestionale, this.FDatadinascita == '' ? null : this.FDatadinascita, this.Fiva, this.Fcf, this.Fsdi, this.Fpec, this.FCitta, this.FProvincia, this.Fcap + '', this.Esigibilita, this.Tipologie, this.FCountry, this.CompanyName, this.Profilo.IsPhysicalPerson, this.FIndirizzo, this.Fcivico, this.Fnome, this.Fcognome, this.FragioneSociale).pipe(
        catchError(error => {
          return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
        })
      ).subscribe(
        res => {
          this.SaveContactResult = res;
          this.GetDatiFat(this.Profilo.IdGestionale);
          this.updateFattError = '';
          this.updateFatt = false;
          this.datiFattErrorMsg = '';
          this.myStepper.next();
        }
      )
    } else {
      this.datiFattErrorMsg = this.currentLang == 'it' ? 'Compila i dati obbligatori!' : 'Fill in the mandatory data';
    }
  }

  useAdressFatt: boolean = true;
  useadd(checked) {
    if (checked == true) {
      console.log('add');
      this.useAdressFatt = true;
      this.nomeReferente = this.nome;
      this.Descrizione = 'indirizzo';
      this.Indirizzo = this.FIndirizzo;
      this.Citta = this.FCitta;
      this.cap = this.Fcap;
      this.Paese = 'Italia';
      this.Telefono = this.tel;
      this.provincia = this.FProvincia;
      this.StreetNumber = this.Fcivico;
    } else {
      this.useAdressFatt = false;
      this.clearAdr();
    }
  }

  IndirizzospedErrorMsg = '';
  checkIndirizzosped() {
    if (this.selectedAdress.length == 0) {
      this.IndirizzospedErrorMsg = this.currentLang == 'it' ? 'Devi aggiungere un indirizzo di spedizione!' : 'You must add a shipping address!';
    } else {
      this.IndirizzospedErrorMsg = '';
      this.myStepper.next();
    }
  }

  erreurCodiceFs: boolean = false;
  erreurSDI: boolean = false;
  erreurFiva: boolean = false;
  emailErrorformat: boolean = false;
  checkstatutField() {
    this.Fcf.length < 16 && this.Fiva == '' && this.Tipologie == 'IVA' ? this.erreurCodiceFs = true : this.erreurCodiceFs = false;
    if (this.Tipologie == 'PA') {
      this.Fsdi.length < 6 && this.Fsdi != '' ? this.erreurSDI = true : this.erreurSDI = false;
    } else {
      this.Fsdi.length < 7 && this.Fsdi != '' ? this.erreurSDI = true : this.erreurSDI = false;
    }
    if (this.Tipologie == 'IVA' ? (this.Fnome != '' && this.Fcognome != '') : this.Tipologie == 'PA' ? this.FragioneSociale != '' : (this.FragioneSociale != '' || (this.Fnome != '' && this.Fcognome != '')) && ((this.Tipologie != 'IVA' ? (this.Fiva != '' || this.Fcf != '') : this.Fcf != '') && (this.Tipologie != 'IVA' ? (this.Fsdi != '' || this.Fpec != '') : 1 == 1)) && (this.FCitta != '' && this.FProvincia != '' && this.Fcap != '' && this.Tipologie != '' && this.Esigibilita != '' && this.erreurSDI == false && this.erreurCodiceFs == false)) {
      this.updateFattError = '';
    } else {
      this.updateFattError = this.currentLang == 'it' ? 'Compila i dati obbligatori!' : 'Fill in the mandatory data';
    }
    if (this.Fiva.length < 11 && (this.FCountry == 'Italia' || this.FCountry == null) && this.Fiva != '') {
      this.erreurFiva = true;
    } else {
      this.erreurFiva = false;
    }

    if (!this.validateEmail(this.Fpec) && this.Fpec != '') {
      this.emailErrorformat = true;
    } else {
      this.emailErrorformat = false;
    }


  }
  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  fattura: boolean = true;
  changeFattura() {
    if (this.fattura == true) {
      this.fattura = false;
      this.updateFattError = '';
    } else {
      this.fattura = true;
    }
  }

  pagaResult: any = [];
  ordiniError = '';
  DateTimeNow = new Date();
  startOrder: boolean = false;

  CreateOrder(methodPaga) {
    this.startOrder = true;
    var Address;
    var StreetNumber;
    var City;
    var Prov;
    var Cap;
    var Country;

    var ragsociale;
    var nome;
    var cognome;

    Address = this.selectedAdress[0]?.Address;
    StreetNumber = this.selectedAdress[0]?.StreetNumber;
    City = this.selectedAdress[0]?.City;
    Prov = this.selectedAdress[0]?.Prov;
    Cap = this.selectedAdress[0]?.Cap;
    Country = this.selectedAdress[0]?.Country;

    if (this.Profilo.IsPhysicalPerson == true) {
      nome = this.DatiFatturazioni.InvoiceData.Name;
      cognome = this.DatiFatturazioni.InvoiceData.Surname;
      ragsociale = null;
    } else {
      nome = null;
      cognome = null;
      ragsociale = this.DatiFatturazioni.InvoiceData.CompanyName;
    }

    this.DocumentRModel();

    this.service.CreateOrder(this.selectedAdress[0]?.Row, this.DateTimeNow, this.TipoDoc, this.fattura == true ? true : false, this.Profilo.IdGestionale, this.FIndirizzo,
      this.FCitta, this.FProvincia, this.Fcap, this.FCountry,
      this.Fiva, this.Fcf, this.DatiFatturazioni.FidelityCardId, this.DocRModel, this.note,
      Address, StreetNumber, City, Prov, Cap, Country, this.FragioneSociale, this.Fnome, this.Fcognome, this.Fcivico, this.Fsdi,
      this.Fpec, this.Tipologie, this.Esigibilita, this.codeconsegna, this.totalePeso, this.consegna, this.PaymentType).pipe(
        catchError(error => {
          return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
        })
      ).subscribe(
        res => {
          this.pagaResult = res;
          // this.startOrder = false;

          if (this.pagaResult.testata != null && this.pagaResult.testata != undefined) {

            localStorage.setItem('price', JSON.stringify(this.TotalCarrello + this.consegna + this.paymentPrice));
            localStorage.setItem('shipping', JSON.stringify(this.consegna));
            localStorage.setItem('itemsGA4', JSON.stringify(this.itemsGA4));
            localStorage.setItem('tax', JSON.stringify(this.pagaResult.testata.totaleIva));
            localStorage.setItem('transaction_id', JSON.stringify(this.pagaResult.testata.idDoc + '_' + this.pagaResult.testata.docYear));
            this.messanger.clearProduct();

            if (this.PayWeb == true) {
              this.pay = true;
              this.paymentPaypal(this.pagaResult.testata.idDoc, this.pagaResult.testata.docYear);
            } else {
              if (this.BankRequired == true) {
                this.router.navigateByUrl('/ordine-result/' + true + '/' + ((this.selectedAdress[0]?.Country !== 'Italia' || this.selectedAdress[0]?.Prov == 'EE') ? true : false));
              } else {
                this.router.navigateByUrl('/ordine-result/' + false + '/' + ((this.selectedAdress[0]?.Country !== 'Italia' || this.selectedAdress[0]?.Prov == 'EE') ? true : false));
              }
            }
          } else {
            this.ordiniError = this.currentLang == 'it' ? 'Il tuo ordine non è andato a buon fine' : 'Your order was unsuccessful'
          }
        }
      )
  }

  urldecoder;

  carrello: Array<{
    Title: string,
    Description: string, Code: string, Weight: number, VatPrice: number, qte: number, Um: string, VatCode: string, total: number, Aggiunte: [],
    IsPriceVat: boolean, OriginalPrice: number, Discount: number
  }> = [];

  itemsGA4: Array<{ item_id: string, item_name: string, item_variant: string, price: number, quantity: number }> = [];

  ModifierProduct: Array<{ index: number, IdAttributeValue: number, Description: string, Default: Boolean, Type: string, Order: number, Value: number, IsPercentage: Boolean, CodeProduct: string }> = [];

  DocRModel: Array<{
    IdDoc: number, TipoDoc: number, NumDoc: number, ParteDoc: string, Articolo: string, IsPriceVat: boolean, Title: string,
    Descrizione: string, Um: string, Quantità: number, PrezzoUnit: number, Iva: string, Totale: number, Modifiers: Array<{}>
  }> = [];

  TipoDoc;
  consegna = 0;
  codeconsegna = '';

  GetConfigurationVariable() {
    this.service.GetConfigurationVariable().pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.TipoDoc = +res;
      }
    )
  }

  DocumentRModel() {
    this.DocRModel = [];
    this.ModifierProduct = [];
    var dateNow = new Date();
    var year = dateNow.getFullYear();

    for (let c = 0; c < this.carrello.length; c++) {
      this.DocRModel.push({
        IdDoc: 0, TipoDoc: this.TipoDoc, NumDoc: 0, ParteDoc: year + '', Articolo: this.carrello[c].Code, IsPriceVat: this.carrello[c].IsPriceVat,
        Descrizione: this.carrello[c].Title, Title: this.carrello[c].Title, Um: this.carrello[c].Um, Quantità: this.carrello[c].qte, PrezzoUnit: this.carrello[c].VatPrice, Iva: this.carrello[c].VatCode, Totale: this.carrello[c].VatPrice * this.carrello[c].qte, Modifiers: []
      });
    }
    if (this.consegna != 0) {
      this.DocRModel.push({
        IdDoc: 0, TipoDoc: this.TipoDoc, NumDoc: 0, ParteDoc: year + '', Articolo: '', IsPriceVat: true,
        Descrizione: 'Spese di consegna', Title: '', Um: '', Quantità: 1, PrezzoUnit: this.consegna, Iva: this.DefaultVat?.Code, Totale: this.consegna, Modifiers: []
      });
    }
    if (this.paymentPrice != 0) {
      this.DocRModel.push({
        IdDoc: 0, TipoDoc: this.TipoDoc, NumDoc: 0, ParteDoc: year + '', Articolo: '', IsPriceVat: true,
        Descrizione: 'Supplemento metodo pagamento', Title: '', Um: '', Quantità: 1, PrezzoUnit: this.paymentPrice, Iva: this.DefaultVat?.Code, Totale: this.paymentPrice, Modifiers: []
      });
    }
  }


  pay: boolean = false;
  @ViewChild('paymentRef', { static: true }) paymentRef!: ElementRef;
  resultPaypal: any = [];
  resulrcreateOrder: any = [];

  paymentPaypal(idDoc, docYear) {
    paypal.Buttons({
      style: {
        shape: 'pill',
      },
      createOrder: (data, actions) => {
        return fetch(this.service.url + '/Documents/CreateOrderPaypal', {
          method: "POST",
          body: JSON.stringify({
            Token: this.service.tokenValue,
            IdDoc: idDoc,
            DocYear: docYear
          }),
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();  // Parse the response as JSON
        })
        .then((order: OrderResponse) => order.id)
        .catch((error) => {
          console.log(error);
        });
      },
      onApprove: (data, actions) => {
        return fetch(this.service.url + '/Documents/CaptureOrderPaypal?orderID=' + data.orderID + '&Token=' + this.service.tokenValue, {
          method: "GET",
        })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();  // Parse the response as JSON
        })
        .then((orderData) => {
          // Redirect to success page
          if (orderData.success==1) {
            console.log('success');
            this.router.navigateByUrl('/ordine-result/false/false');
          }else{
            this.router.navigateByUrl('/ordine-errore');
          }
         })
          .catch((error) => {
          console.log(error);
        });
      },
      onError: function (error) {
        // Do something with the error from the SDK

        console.log('error');
        this.router.navigateByUrl('/ordine-errore');
        },
    }).render(this.paymentRef.nativeElement);
  }


}
