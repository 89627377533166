import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ResultComponent } from './result.component';
import { ResultRoutingModule } from './result-routing.module';

@NgModule({
  declarations: [
    ResultComponent
  ],
  imports: [
    TranslateModule,
    CommonModule,
    ResultRoutingModule,
  ],
})
export class ResultModule { }
