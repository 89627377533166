import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { TranslateCacheService } from 'ngx-translate-cache';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  production:boolean=true;

  url: string = this.production ? "https://api.egonext.it/api" : "http://192.168.1.28/egonext.api_blacksmith/api";
  //url: string = "https://apitest.egonext.it/api";


  companyName: string = 'Blacksmith';
  Profilo: any = [];
  childId = '';
  currentLang = 'it';

  constructor(private http: HttpClient, public translate: TranslateService, translateCacheService: TranslateCacheService, @Inject(PLATFORM_ID) private platformId: Object) {
    this.Profilo = JSON.parse(localStorage.getItem("profil"));
    translate.setDefaultLang('it');
    translateCacheService.init();

    this.currentLang = translate.store.currentLang;

    if (isPlatformBrowser(this.platformId)) {
      this.currentLang = localStorage.getItem('lang');
    }
  }

  getToken() {
    const body = ({
      User: "Blacksmith",
      Password: this.production ? "ekW!y7*74t7K" : "Blacksmith"
    });
    return this.http.post(this.url + '/Token/GetToken', body)
  }

  LogErrors(ErrorMessage) {
    const body = ({
      errorString: ErrorMessage,
      company: "blacksmith sito"
    });
    return this.http.post(this.url + '/Utility/SendLogger', body)
  }

  getProducts(groupIDlist, page, numItems, search, idBrand, idProdottiSimili, orderPriceDesc, orderPriceAsc, groupID) {
    if (idProdottiSimili != null) {
      const params = new HttpParams()
        .set("Language", this.currentLang == null ? 'it' : this.currentLang)
        .set("companyName", this.companyName)
        .set("groupID", groupID == -1 ? -1 : groupID != 0 ? groupID : 1)
        .set("shopID", '')
        .set("contactID", this.Profilo != null ? this.Profilo?.IdGestionale : '')
        .set("isApp", '')
        .set("isWeb", '')
        .set("search", '')
        .set("groupIdList", groupID == 0 ? groupIDlist : '')
        .set("checkAvailability", false)
        .set("data", JSON.stringify({
          Offset: page,
          Limit: numItems,
          Sort: [],
          Filter: {
            Logic: "and",
            Filters: [
              {
                Field: "GroupID",
                Operator: "eq",
                Value: idProdottiSimili,
              }
            ]
          }
        }))
      return this.http.get(this.url + '/Products/GetProductsWeb', { params })
    } else if (search == '' && idBrand == 'null') {
      const params = new HttpParams()
        .set("Language", this.currentLang == null ? 'it' : this.currentLang)
        .set("companyName", this.companyName)
        .set("groupID", groupID == -1 ? -1 : groupID != 0 ? groupID : 1)
        .set("shopID", '')
        .set("contactID", this.Profilo != null ? this.Profilo?.IdGestionale : '')
        .set("isApp", '')
        .set("search", '')
        .set("isWeb", '')
        .set("groupIdList", groupID == 0 ? groupIDlist : '')
        .set("checkAvailability", false)
        .set("data", JSON.stringify({
          Offset: page,
          Limit: numItems,
          Sort: orderPriceAsc == false && orderPriceDesc == false ?
          idBrand != 'null' || search != '' ?
              [
                {
                  Field: "OrderGroupLvl1",
                  Dir: 'asc'
                },
                {
                  Field: "OrderGroupLvl2",
                  Dir: 'asc'
                },
                {
                  Field: "OrderGroupLvl3",
                  Dir: 'asc'
                },
                {
                  Field: "OrderGroupLvl4",
                  Dir: 'asc'
                }
              ]
              : [] : [
              {
                Field: "Price",
                Dir: orderPriceDesc == true ? "desc" : "asc"
              }
            ],
          Filter: null
        }))
      return this.http.get(this.url + '/Products/GetProductsWeb', { params })
    } else if (search != '' && idBrand != 'null') {
      const params = new HttpParams()
        .set("Language", this.currentLang == null ? 'it' : this.currentLang)
        .set("companyName", this.companyName)
        .set("groupID", groupID == -1 ? -1 : groupID != 0 ? groupID : 1)
        .set("shopID", '')
        .set("contactID", this.Profilo != null ? this.Profilo?.IdGestionale : '')
        .set("isApp", '')
        .set("brandId", idBrand)
        .set("search", search)
        .set("isWeb", '')
        .set("groupIdList", groupID == 0 ? groupIDlist : '')
        .set("checkAvailability", false)
        .set("data", JSON.stringify({
          Offset: page,
          Limit: numItems,
          Sort: orderPriceAsc == false && orderPriceDesc == false ?
          idBrand != 'null' || search != '' ?
            [
              {
                Field: "OrderGroupLvl1",
                Dir: 'asc'
              },
              {
                Field: "OrderGroupLvl2",
                Dir: 'asc'
              },
              {
                Field: "OrderGroupLvl3",
                Dir: 'asc'
              },
              {
                Field: "OrderGroupLvl4",
                Dir: 'asc'
              }
            ]
            : [] : [
            {
              Field: "Price",
              Dir: orderPriceDesc == true ? "desc" : "asc"
            }
          ]
        }))
      return this.http.get(this.url + '/Products/GetProductsWeb', { params })
    } else {
      const params = new HttpParams()
        .set("Language", this.currentLang == null ? 'it' : this.currentLang)
        .set("companyName", this.companyName)
        .set("groupID", groupID == -1 ? -1 : groupID != 0 ? groupID : 1)
        .set("shopID", '')
        .set("contactID", this.Profilo != null ? this.Profilo?.IdGestionale : '')
        .set("isApp", '')
        .set("isWeb", '')
        .set("brandId", search == '' ? idBrand : '')
        .set("search", search != '' ? search : '')
        .set("groupIdList", groupID == 0 ? groupIDlist : '')
        .set("checkAvailability", false)
        .set("data", JSON.stringify({
          Offset: page,
          Limit: numItems,
          Sort: orderPriceAsc == false && orderPriceDesc == false ?
            idBrand != 'null' || search != '' ?
              [
                {
                  Field: "OrderGroupLvl1",
                  Dir: 'asc'
                },
                {
                  Field: "OrderGroupLvl2",
                  Dir: 'asc'
                },
                {
                  Field: "OrderGroupLvl3",
                  Dir: 'asc'
                },
                {
                  Field: "OrderGroupLvl4",
                  Dir: 'asc'
                }
              ]
              : [] : [
              {
                Field: "Price",
                Dir: orderPriceDesc == true ? "desc" : "asc"
              }
            ]
        }))
      return this.http.get(this.url + '/Products/GetProductsWeb', { params })
    }
  }

  GetTotalProductsWeb(groupIDlist, page, numItems, search, idBrand, orderPriceDesc, orderPriceAsc, groupID) {
    if (search == '' && idBrand == 'null') {
      return this.http.get(this.url + '/Products/GetTotalProductsWeb', {
        params: {
          Language: this.currentLang == null ? 'it' : this.currentLang,
          companyName: this.companyName,
          groupID: groupID == -1 ? -1 : groupID != 0 ? groupID : 1,
          shopID: '',
          contactID: this.Profilo != null ? this.Profilo?.IdGestionale : '',
          isApp: '',
          isWeb: '',
          search: '',
          groupIdList: groupID == 0 ? groupIDlist : '',
          checkAvailability: false,
          data: JSON.stringify({
            Offset: page,
            Limit: numItems,
            Sort: orderPriceAsc == false && orderPriceDesc == false ? [] : [
              {
                Field: "Price",
                Dir: orderPriceDesc == true ? "desc" : "asc"
              }
            ],
            Filter: null
          }),
        }
      })
    } else if (search != '' && idBrand != 'null') {
      return this.http.get(this.url + '/Products/GetTotalProductsWeb', {
        params: {
          Language: this.currentLang == null ? 'it' : this.currentLang,
          companyName: this.companyName,
          groupID: groupID == -1 ? -1 : groupID != 0 ? groupID : 1,
          shopID: '',
          contactID: this.Profilo != null ? this.Profilo?.IdGestionale : '',
          isApp: '',
          brandId: idBrand,
          isWeb: '',
          search: search,
          groupIdList: groupID == 0 ? groupIDlist : '',
          checkAvailability: false,
          data: JSON.stringify({
            Offset: page,
            Limit: numItems,
            Sort: orderPriceAsc == false && orderPriceDesc == false ? [] : [
              {
                Field: "Price",
                Dir: orderPriceDesc == true ? "desc" : "asc"
              }
            ],
          }),
        }
      })
    } else {
      return this.http.get(this.url + '/Products/GetTotalProductsWeb', {
        params: {
          Language: this.currentLang == null ? 'it' : this.currentLang,
          companyName: this.companyName,
          groupID: groupID == -1 ? -1 : groupID != 0 ? groupID : 1,
          shopID: '',
          contactID: this.Profilo != null ? this.Profilo?.IdGestionale : '',
          isApp: '',
          isWeb: '',
          brandId: search == '' ? idBrand : '',
          search: search,
          groupIdList: groupID == 0 ? groupIDlist : '',
          checkAvailability: false,
          data: JSON.stringify({
            Offset: page,
            Limit: numItems,
            Sort: orderPriceAsc == false && orderPriceDesc == false ? [] : [
              {
                Field: "Price",
                Dir: orderPriceDesc == true ? "desc" : "asc"
              }
            ],

          }),
        }
      })
    }
  }

  GetProductsWebDetail(productId, isReorderProd) {
    const params = new HttpParams()
      .set("Language", this.currentLang == null ? 'it' : this.currentLang)
      .set("companyName", this.companyName)
      .set("productId", productId)
      .set("isReorderProd", isReorderProd)
      .set("IdCredential", this.Profilo != null ? this.Profilo?.IdContactsCredential : '')
      .set("contactID", this.Profilo != null ? this.Profilo?.IdGestionale : '');

    return this.http.get(this.url + '/Products/GetProductsWebDetail', { params })
  }

  getProductsEvidenza() {
    return this.http.get(this.url + '/Products/GetProductsWeb', {
      params: {
        Language: this.currentLang == null ? 'it' : this.currentLang,
        companyName: this.companyName,
        groupID: -1,
        shopID: '',
        contactID: this.Profilo != null ? this.Profilo?.IdGestionale : '',
        isApp: '',
        isWeb: '',
        groupIdList: '',
        checkAvailability: false,
        onlyInHomeWeb: true,
        data: JSON.stringify({
          Offset: 0,
          Limit: 20
        }),
      }
    })
  }


  CreateOrder(RowSelectedAdress, DateTimeNow, TipoDoc, fattura, IdCliente, Indirizzo, Citta, Prov, Cap, Nazione, PIva, CodFis, FidelityCardId,
    Righe, note, Address, StreetNumber, City, ProvF, CapF, Country, RagSociale, Nome, Cognome, NumCivico, coddest, pec,
    TipoCliente, EsigibilitaIva, CodeCarrier, NetWeight, CarrierAmount, PaymentType) {
    var dateNow = new Date();
    var year = dateNow.getFullYear();

    const body = ({
      Testata: {
        PaymentType: PaymentType,
        IdDoc: 0,
        TipoDoc: TipoDoc,
        NumDoc: 0,
        ParteDoc: year + '',
        DocYear: year,
        IdStore: null,
        InvoiceRequest: fattura,
        DataDoc: DateTimeNow,
        IdCliente: IdCliente,
        IdInd: null,
        RagSociale: RagSociale,
        RagSociale1: null,
        NumCivico: NumCivico,
        Nome: Nome,
        Cognome: Cognome,
        Indirizzo: Indirizzo,
        Indirizzo1: null,
        Citta: Citta,
        Prov: Prov,
        Cap: Cap,
        Nazione: Nazione,
        piva: PIva,
        codfis: CodFis,
        FidelityCardId: FidelityCardId,
        coddest: coddest,
        pec: pec,
        TipoCliente: TipoCliente,
        EsigibilitaIva: EsigibilitaIva,
        FirmaCliente: null,
        DataFirmaCliente: null,
        TotaleImponibile: null,
        TotaleIva: null,
        TotaleIvato: null,
        Utente: null,
        FlagEvaso: null,
        FlagEvasoTotale: null,
        IdAddressDest: RowSelectedAdress,
        Destination: Address + ', ' + StreetNumber + '\n' + City + ' ' + ProvF + ' ' + CapF + '\n' + Country,
        AddressNotes: note,
        CodeCarrier: CodeCarrier,
        GrossWeight: NetWeight,
        // CarrierAmount:CarrierAmount
      },
      Righe: Righe
    });

    return this.http.post(this.url + '/Documents/SaveDocument', body, {
      params: {
        Token: this.tokenValue,
        ApiUrl: this.url + '/'
      }
    })
  }


  tokenValue;
  updatetoken(token) {
    this.tokenValue = token;
  }


  private Groups: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  GroupsList = this.Groups.asObservable();

  updateUserData(Groups) {
    this.Groups.next(Groups);
  }

  GetGroups(id, contactID) {
    return this.http.get(this.url + '/Products/AllGroups', {
      params: {
        Token: this.tokenValue,
        Grouped: true,
        OnlyWeb: true,
        Language: this.currentLang == null ? 'it' : this.currentLang,
        OnlyWithProducts: true,
        contactID: contactID == undefined ? 0 : contactID
      }
    })
  }

  GetPriceLists() {
    return this.http.get(this.url + '/Products/GetPriceLists', {
      params: {
        Token: this.tokenValue,
      }
    })
  }

  GetCarriers() {
    return this.http.get(this.url + '/Table/GetCarriers', {
      params: {
        Token: this.tokenValue,
      }
    })
  }

  GettipologiaPagamento(Amount, VatId) {
    return this.http.get(this.url + '/Table/GetPaymentTypes', {
      params: {
        Token: this.tokenValue,
        OnlyWeb: true,
        Amount: Amount,
        VatId: VatId
      }
    })
  }

  GetProductsTotal(groupId) {
    return this.http.get(this.url + '/Products/GetProductsTotal?groupId=' + groupId)
  }

  GetBrands(IdGroup) {
    if (this.tokenValue == undefined) {
      this.tokenValue = JSON.parse(localStorage.getItem('token'));
    }
    return this.http.get(this.url + '/Table/GetBrands?Token=' + this.tokenValue + '&IdGroup=' + IdGroup)
  }

  GetConfigurationVariable() {
    return this.http.get(this.url + '/Configuration/GetConfigurationVariable?Token=' + this.tokenValue + '&VarName=tipo_documento_ordine_web')
  }


  login(Username, Password) {
    const body = ({
      Username: Username,
      Password: Password
    });
    return this.http.post(this.url + '/Contacts/Login', body, {
      params: {
        Token: this.tokenValue,
        ApiUrl: this.url + '/'
      }
    })
  }

  Register(Name, Surname, pass, Email, companyname, IsPhysicalPerson, MobileNumber, IdPriceList) {
    const body = ({
      Name: Name,
      Surname: Surname,
      Username: Surname,
      Email: Email,
      MobileNumber: MobileNumber,
      Password: pass,
      FlagPrivacy: true,
      CompanyName: companyname,
      IsPhysicalPerson: IsPhysicalPerson,
      AgreeUseData: true,
      IdPriceList: parseInt(IdPriceList),
    });
    return this.http.post(this.url + '/Contacts/Register', body, {
      params: {
        Token: this.tokenValue,
        ApiUrl: this.url + '/'
      }
    })
  }
  RegisterCompany(Name, Surname, pass, Email, companyname, IsPhysicalPerson, MobileNumber, IdPriceList, FDatadinascita, Fiva, Fcf, Fsdi, Fpec, FCitta, FProvincia, Fcap, Esigibilita, Tipologie, FCountry, CompanyName, Address, StreetNumber, FName, FSurname, FCompanyName) {
    const body = ({
      Name: Name,
      Surname: Surname,
      Username: Surname,
      Email: Email,
      MobileNumber: MobileNumber,
      Password: pass,
      FlagPrivacy: true,
      CompanyName: companyname,
      IsPhysicalPerson: IsPhysicalPerson,
      AgreeUseData: true,
      IdPriceList: parseInt(IdPriceList),
      InvoiceData: {
        BirthDate: FDatadinascita,
        PIva: Fiva,
        CodFis: Fcf,
        RecipientCodeFe: Fsdi,
        EmailPec: Fpec,
        City: FCitta,
        Prov: FProvincia,
        Cap: Fcap,
        Vatexigibility: Esigibilita,
        CustomerTypeFe: Tipologie,
        Country: FCountry,
        Name: FName,
        Surname: FSurname,
        CompanyName: FCompanyName,
        Address: Address,
        StreetNumber: StreetNumber
      },
    });
    return this.http.post(this.url + '/Contacts/Register', body, {
      params: {
        Token: this.tokenValue,
        ApiUrl: this.url + '/'
      }
    })
  }

  RecoverPassword(Email) {
    const body = ({
    });
    return this.http.post(this.url + '/Contacts/RecoverPassword', body, {
      params: {
        Token: this.tokenValue,
        ApiUrl: this.url + '/',
        Email: Email,
      }
    })
  }

  SaveContactCredential(IdGestionale, Password, Username) {
    const body = ({
      Password: Password,
      Username: Username,
      IdGestionale: IdGestionale,
    });
    return this.http.post(this.url + '/Contacts/SaveContactCredential', body, {
      params: {
        Token: this.tokenValue,
        ApiUrl: this.url + '/'
      }
    })
  }


  private Addresses: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  AddressesList = this.Addresses.asObservable();

  updateAddresses(Addresses) {
    this.Addresses.next(Addresses);
  }

  GetAddresses(IdContact) {
    return this.http.get(this.url + '/Contacts/GetContactAddresses', {
      params: {
        Token: this.tokenValue,
        IdContact: IdContact,
        ApiUrl: this.url + '/'
      }
    })
  }

  GetDefaultVat(IdContact) {
    return this.http.get(this.url + '/Documents/GetDefaultVat', {
      params: {
        Token: this.tokenValue,
        IdContact: IdContact
      }
    })
  }

  GetProvinces() {
    return this.http.get(this.url + '/Table/GetProvinces', {
      params: {
        Token: this.tokenValue,
        ApiUrl: this.url + '/'
      }
    })
  }
  GetCities() {
    return this.http.get(this.url + '/Table/GetCities', {
      params: {
        Token: this.tokenValue,
        ApiUrl: this.url + '/'
      }
    })
  }
  GetCountryCodes() {
    return this.http.get(this.url + '/Table/GetCountryCodes', {
      params: {
        Token: this.tokenValue,
        ApiUrl: this.url + '/'
      }
    })
  }

  GetDatiFat(IdContact) {
    return this.http.get(this.url + '/Contacts/GetContact', {
      params: {
        Token: this.tokenValue,
        IdContact: IdContact,
        ApiUrl: this.url + '/'
      }
    })
  }

  addAdress(Descrizionee, ReferenceName, ReferencePhoneNumber, Address, prov, City, Cap, Country, IdGestionale, StreetNumber) {
    const body = ({
      ReferenceName: ReferenceName,
      ReferencePhoneNumber: ReferencePhoneNumber,
      Address: Address,
      Prov: prov,
      City: City,
      Cap: Cap,
      Country: Country,
      Description: Descrizionee,
      Default: true,
      IdContact: IdGestionale,
      StreetNumber: StreetNumber
    });

    return this.http.post(this.url + '/Contacts/SaveContactAddress', body, {
      params: {
        IsNew: true + '',
        Token: this.tokenValue,
        ApiUrl: this.url + '/',
      }
    })
  }

  UpdateAdress(Descrizionee, ReferenceName, ReferencePhoneNumber, Address, prov, City, Cap, Country, IdGestionale, row, Default, StreetNumber) {
    const body = ({
      ReferenceName: ReferenceName,
      ReferencePhoneNumber: ReferencePhoneNumber,
      Address: Address,
      City: City,
      Cap: Cap,
      Country: Country,
      Idcontact: IdGestionale,
      Default: Default,
      Description: Descrizionee,
      Prov: prov,
      Row: row,
      StreetNumber: StreetNumber
    });

    return this.http.post(this.url + '/Contacts/SaveContactAddress', body, {
      params: {
        IsNew: false + '',
        Token: this.tokenValue,
        ApiUrl: this.url + '/',
      }
    })
  }

  UpdateFavAdr(Descrizionee, ReferenceName, ReferencePhoneNumber, Address, prov, City, Cap, Country, IdGestionale, row, Default, StreetNumber) {
    const body = ({
      ReferenceName: ReferenceName,
      ReferencePhoneNumber: ReferencePhoneNumber,
      Address: Address,
      City: City,
      Cap: Cap,
      Country: Country,
      Idcontact: IdGestionale,
      Default: Default,
      Description: Descrizionee,
      Prov: prov,
      Row: row,
      StreetNumber: StreetNumber,
      IdcontactNavigation: null
    });

    return this.http.post(this.url + '/Contacts/SaveContactAddress', body, {
      params: {
        IsNew: false + '',
        Token: this.tokenValue,
        ApiUrl: this.url + '/',
      }
    })
  }

  DeleteAddress(IdGestionale, Row) {
    const body = ({
    });
    return this.http.post(this.url + '/Contacts/DeleteContactAddress', body, {
      params: {
        Token: this.tokenValue,
        ApiUrl: this.url + '/',
        Row: Row,
        idContact: IdGestionale
      }
    })
  }

  GetCustomerTypes() {
    return this.http.get(this.url + '/Table/GetCustomerTypes', {
      params: {
        Token: this.tokenValue,
        ApiUrl: this.url + '/'
      }
    })
  }

  GetVatExigibility() {
    return this.http.get(this.url + '/Table/GetVatExigibility', {
      params: {
        Token: this.tokenValue,
        ApiUrl: this.url + '/'
      }
    })
  }

  GetFidelityDocuments(IdFidelity, IsOrder) {
    return this.http.get(this.url + '/Documents/GetFidelityDocuments', {
      params: {
        Token: this.tokenValue,
        ApiUrl: this.url + '/',
        IdFidelity: IdFidelity,
        IsOrder: IsOrder
      }
    })
  }

  GetStores() {
    return this.http.get(this.url + '/store/GetStores', {
      params: {
        Token: this.tokenValue,
        ApiUrl: this.url + '/'
      }
    })
  }
  GetConfiguration() {
    return this.http.get(this.url + '/Configuration/GetConfigurationVariable', {
      params: {
        Token: this.tokenValue,
        ApiUrl: this.url + '/',
        VarName: "tipo_documento_ordine_web"
      }
    })
  }

  SaveContactDati(AgreeUseData, Name, Surname, Email, PhoneNumber, MobileNumber, IdGestionale, FDatadinascita, Fiva, Fcf, Fsdi, Fpec, FCitta, FProvincia, Fcap, Esigibilita, Tipologie, FCountry, CompanyName, IsPhysicalPerson, Address, StreetNumber, FName, FSurname, FCompanyName) {
    const body = ({
      AgreeUseData: AgreeUseData,
      Email: Email,
      PhoneNumber: PhoneNumber,
      MobileNumber: MobileNumber,
      IdGestionale: IdGestionale,
      Name: Name,
      Surname: Surname,
      CompanyName: CompanyName,
      InvoiceData: {
        BirthDate: FDatadinascita,
        PIva: Fiva,
        CodFis: Fcf,
        RecipientCodeFe: Fsdi,
        EmailPec: Fpec,
        City: FCitta,
        Prov: FProvincia,
        Cap: Fcap,
        Vatexigibility: Esigibilita,
        CustomerTypeFe: Tipologie,
        Country: FCountry,
        Name: FName,
        Surname: FSurname,
        CompanyName: FCompanyName,
        Address: Address,
        StreetNumber: StreetNumber
      },
      IsPhysicalPerson: IsPhysicalPerson
    });

    return this.http.post(this.url + '/Contacts/SaveContact', body, {
      params: {
        Token: this.tokenValue,
        ApiUrl: this.url + '/'
      }
    })
  }


  SaveContact(AgreeUseData, Name, Surname, Email, PhoneNumber, MobileNumber, IdGestionale, FDatadinascita, Fiva, Fcf, Fsdi, Fpec, FCitta, FProvincia, Fcap, Esigibilita, Tipologie, FCountry, CompanyName, IsPhysicalPerson, Address, StreetNumber, FName, FSurname, FCompanyName) {
    const body = ({
      AgreeUseData: AgreeUseData,
      Email: Email,
      PhoneNumber: PhoneNumber,
      MobileNumber: MobileNumber,
      IdGestionale: IdGestionale,
      Name: Name,
      Surname: Surname,
      CompanyName: CompanyName,
      InvoiceData: {
        BirthDate: FDatadinascita,
        PIva: Fiva,
        CodFis: Fcf,
        RecipientCodeFe: Fsdi,
        EmailPec: Fpec,
        City: FCitta,
        Prov: FProvincia,
        Cap: Fcap,
        Vatexigibility: Esigibilita,
        CustomerTypeFe: Tipologie,
        Country: FCountry,
        Name: FName,
        Surname: FSurname,
        CompanyName: FCompanyName,
        Address: Address,
        StreetNumber: StreetNumber
      },
      IsPhysicalPerson: IsPhysicalPerson
    });

    return this.http.post(this.url + '/Contacts/SaveContact', body, {
      params: {
        Token: this.tokenValue,
        ApiUrl: this.url + '/'
      }
    })
  }

  getServices() {
    return this.http.get(this.url + '/Products/GetServicesOnline', {
      params: {
        Token: this.tokenValue,
        ApiUrl: this.url + '/'
      }
    })
  }

  GetBlogs(cat, page, numItems, year, orderDate, search) {
    return this.http.get(this.url + '/Blog/GetBlog', {
      params: {
        Token: this.tokenValue,
        Id: cat,
        Year: year,
        // Month:null,
        DisplayWeb: true,
        data: JSON.stringify({
          Offset: page,
          Limit: numItems,
          Sort: [
            {
              Field: "DtInsert",
              Dir: orderDate
            }
          ],
          Filter: {
            Field: "title",
            Operator: "contains",
            Value: search,
            Logic: "and"
          }
        }),
      }
    })
  }
  GetNumBlogCategory(cat, page, numItems, year, orderDate, search) {
    return this.http.get(this.url + '/Blog/GetNumBlogCategory', {
      params: {
        Token: this.tokenValue,
        Id: cat,
        Year: year,
        // Month:null,
        DisplayWeb: true,
        data: JSON.stringify({
          Offset: page,
          Limit: numItems,
          Sort: [
            {
              Field: "DtInsert",
              Dir: orderDate
            }
          ],
          Filter: {
            Field: "title",
            Operator: "contains",
            Value: search,
            Logic: "and"
          }
        }),
      }
    })
  }

  GetBlogDetails(id) {
    return this.http.get(this.url + '/Blog/GetBlogDetails', {
      params: {
        Token: this.tokenValue,
        Id: id
      }
    })
  }

  GetCategoriesblogs() {
    return this.http.get(this.url + '/Blog/GetCategories', {
      params: {
        Token: this.tokenValue
      }
    })
  }

  GetFavourites(IdCredential) {
    return this.http.get(this.url + '/Products/GetFavourites', {
      params: {
        companyName: this.companyName,
        IdCredential: IdCredential,
      }
    })
  }

  FavouritesCountFromService = 0;
  GetFavouritesCount(IdCredential) {
    this.http.get(this.url + '/Products/GetFavouritesCount', {
      params: {
        companyName: this.companyName,
        IdCredential: IdCredential,
      }
    }).subscribe(res => {
      this.FavouritesCountFromService = res as number;
    })
  }


  ManageFavorites(IdCredential, IdProduct, adding) {
    const body = ({});
    return this.http.post(this.url + '/Products/ManageFavorites', body, {
      params: {
        companyName: this.companyName,
        IdCredential: IdCredential,
        IdProduct: IdProduct,
        Adding: adding
      }
    })
  }

  RemoveAllFavourites(IdCredential) {
    const body = ({});
    return this.http.post(this.url + '/Products/RemoveAllFavourites', body, {
      params: {
        CompanyName: this.companyName,
        IdCredential: IdCredential,
      }
    })
  }


  SendEmail(emailSender, name, cognome, tel, msg, title) {
    var lang = this.currentLang == null ? 'it' : this.currentLang;
    const body = ({
      "senderEmail": emailSender,
      "senderName": name + ' ' + cognome,
      "receiverEmail": lang == 'it' ? "info@blacksmithitalia.it" : "blackh@blacksmithsnc.com",
      // "receiverEmail":"h.boughalem@itd-italia.com",
      "subject": "Richiesta informazioni per articolo " + title,
      "bcc": "",
      "cc": "",
      "body": "<h4>Richiesta proveniente da sito web</h4><b>Nome e Cognome: </b>" + name + ' ' + cognome + "<br><b>Telofono: </b>" + tel + "<br><b>Email: </b>" + emailSender + "<br><b>Richiesta: </b>" + msg + "<br><br>"
    });
    return this.http.post(this.url + '/send/SendEmail', body)
  }

}
