import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from '../services/service.service';
import { formatDate } from '@angular/common';
import { Product } from '../models/product';
import { MessengerService } from '../services/messenger.service';
import { catchError } from 'rxjs/operators';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  constructor(private router: Router, private service: ServiceService, private messanger: MessengerService,private metaService:Meta) {
    this.router.onSameUrlNavigation = 'reload'
  }

  Profilo: any = [];
  day;
  time;
  phoneNGX;
  yearNow = new Date().getFullYear();
  selectyearNow: boolean = true;
  // IsTemporary: boolean = false;

  ngOnInit(): void {
    this.metaService.addTag({ name: 'robots', content: 'noindex' });
    if (JSON.parse(localStorage.getItem("login")) == 'true') {
      this.Profilo = JSON.parse(localStorage.getItem("profil"));
      this.nome = this.Profilo.Name;
      this.cognome = this.Profilo.Surname;
      this.CompanyName = this.Profilo.CompanyName;
      this.IsPhysicalPerson = this.Profilo.IsPhysicalPerson;
      this.email = this.Profilo.Email;
      this.phoneNumber = this.Profilo.PhoneNumber;
      this.mobileNumber = this.Profilo.MobileNumber;
      this.FlagPrivacy = this.Profilo.FlagPrivacy;
      // if (this.Profilo.IsTemporary == true) {
      //   this.IsTemporary = true;
      // }
      this.AgreeUseData = this.Profilo.AgreeUseData == null ? false : this.Profilo.AgreeUseData;
      setTimeout(() => {
        this.GetDatiFat(this.Profilo.IdGestionale);
        this.GetProvinces();
        this.GetCountryCodes();
        this.refreshUser();
      }, 1500);
    } else {
      this.router.navigate(['/area-riservata']);
    }

    if (JSON.parse(localStorage.getItem("carrello")) != null) {
      this.carrello = JSON.parse(localStorage.getItem("carrello"));
    }


  }

  openF: boolean = false;
  openDataFatturazione() {
    this.openF = true;
    this.GetCustomerTypes();
    this.GetVatExigibility();
    this.datiFatturazione = true;
    setTimeout(() => {
      this.openF = false;
    }, 1800);
  }

  oggi = new Date;

  getFormatTime(value) {
    return value.slice(0, 5)
  }


  CountryCodes: any = [];
  GetCountryCodes() {
    if (this.CountryCodes.length == 0) {
      this.service.GetCountryCodes().pipe(
        catchError(error => {
          return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
        })
      ).subscribe(
        res => {
          this.CountryCodes = res;
        }
      )
    }
  }


  carrelloitem: any = [];
  logOut() {
    localStorage.removeItem('profil');
    localStorage.removeItem('login');
    this.service.Profilo = null;
    if (this.carrello != undefined && this.carrello.length != 0) {
      for (let i = 0; i < this.carrello.length; i++) {
        this.carrelloitem = [];
        this.service.GetProductsWebDetail(this.carrello[i].Idchild == undefined ? this.carrello[i].Id : this.carrello[i].Idchild, this.carrello[i].Idchild == undefined ? false : true).pipe(
          catchError(error => {
            return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
          })
        ).subscribe(
          products => {
            this.carrelloitem = products;
            this.carrello[i].Price = this.carrelloitem.Price;
            this.carrello[i].VatCode = this.carrelloitem.VatCode;
            this.carrello[i].Price = this.carrelloitem.Price;
            this.carrello[i].Discount = this.carrelloitem.Discount;
            this.carrello[i].OriginalPrice = this.carrelloitem.OriginalPrice;
            localStorage.setItem('carrello', JSON.stringify(this.carrello));
            if (i == this.carrello.length - 1) {
              this.router.navigate(['area-riservata'])
                .then(() => {
                  window.location.reload();
                });
            }
          }
        )
      }
    } else {
      window.location.reload();
    }
  }

  datiPersonali: boolean = true;
  Indirizzi: boolean = false;
  addNewAdress: boolean = false;

  nome;
  cognome;
  CompanyName;
  IsPhysicalPerson: boolean;
  email;
  phoneNumber;
  mobileNumber;
  adress;
  city;
  prov;

  update: boolean = false;
  save: boolean = false;
  done: boolean = false;


  updateFattError = '';
  SaveContactResult: any = [];
  SaveContact(IdGestionale) {
    if (this.Tipologie == 'IVA' ? (this.Fnome != '' && this.Fcognome != '') : this.Tipologie == 'PA' ? this.FragioneSociale != '' : (this.FragioneSociale != '' || (this.Fnome != '' && this.Fcognome != '')) && ((this.Tipologie != 'IVA' ? (this.Fiva != '' || this.Fcf != '') : this.Fcf != '') && (this.Tipologie != 'IVA' ? (this.Fsdi != '' || this.Fpec != '') : 1 == 1)) && (this.FCitta != '' && this.FProvincia != '' && this.Fcap != '' && this.Tipologie != '' && this.Esigibilita != '' && this.FIndirizzo != '' && this.Fcivico != '') && this.erreurCodiceFs == false && this.emailErrorformat == false && this.erreurSDI == false && this.erreurFiva == false) {
      this.service.SaveContact(this.AgreeUseData, this.nome, this.cognome, this.email, this.phoneNumber?.e164Number, this.mobileNumber?.e164Number, IdGestionale, this.FDatadinascita == '' ? null : this.FDatadinascita, this.Fiva, this.Fcf, this.Fsdi, this.Fpec, this.FCitta, this.FProvincia, this.Fcap, this.Esigibilita, this.Tipologie, this.FCountry, this.CompanyName, this.IsPhysicalPerson, this.FIndirizzo, this.Fcivico, this.Fnome, this.Fcognome, this.FragioneSociale).pipe(
        catchError(error => {
          return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
        })
      ).subscribe(
        res => {
          this.save = true;
          this.refreshUser();
          setTimeout(() => {
            this.SaveContactResult = res;
            if (this.SaveContactResult == true) {
              this.done = true;
              this.updateFattError = '';
            }
            this.save = false;
            this.update = false;
          }, 1200)
        }
      )
    } else {
      this.updateFattError = 'Compila i dati obbligatori';
    }
  }

  annullaSaveContact() {
    this.update = false;
    this.saveProfilError = false;
    this.Profilo = JSON.parse(localStorage.getItem("profil"));
    this.nome = this.Profilo.Name;
    this.cognome = this.Profilo.Surname;
    this.CompanyName = this.Profilo.CompanyName;
    this.IsPhysicalPerson = this.Profilo.IsPhysicalPerson;
    this.email = this.Profilo.Email;
    this.phoneNumber = this.Profilo.PhoneNumber;
    this.mobileNumber = this.Profilo.MobileNumber;
    this.FlagPrivacy = this.Profilo.FlagPrivacy;
  }

  clearActivated() {
    this.datiFatturazione = false;
    this.done = false;
    this.update = false;
    this.ordini = false;
    this.datiPersonali = false;
    this.Indirizzi = false;
  }

  SaveContactResult1: any = [];
  saveProfilError: boolean = false;
  SaveContact1(IdGestionale) {
    if (((this.nome != '' && this.cognome != '') || this.CompanyName != '') && this.email != '' && this.mobileNumber != null) {
      this.service.SaveContactDati(this.AgreeUseData, this.nome, this.cognome, this.email, this.phoneNumber?.e164Number, this.mobileNumber?.e164Number, IdGestionale, this.FDatadinascita == '' ? null : this.FDatadinascita, this.Fiva, this.Fcf, this.Fsdi, this.Fpec, this.FCitta, this.FProvincia, this.Fcap, this.Esigibilita, this.Tipologie, this.FCountry, this.CompanyName, this.IsPhysicalPerson, this.FIndirizzo, this.Fcivico, this.Fnome, this.Fcognome, this.FragioneSociale).pipe(
        catchError(error => {
          return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
        })
      ).subscribe(
        res => {
          this.save = true;
          this.refreshUser();
          setTimeout(() => {
            this.SaveContactResult1 = res;
            if (this.SaveContactResult1 == true) {
              this.done = true;
              this.saveProfilError = false;
            }
            this.save = false;
            this.update = false;
          }, 1200)
        }
      )
    } else {
      this.saveProfilError = true;
    }
  }

  loadIndirizzi: boolean = false;
  GetAddressesList: any = [];
  GetAddresses(IdContact, refresh) {
    if (this.GetAddressesList.length == 0 || refresh == true) {
      this.loadIndirizzi = true;
      this.service.GetAddresses(IdContact).pipe(
        catchError(error => {
          return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
        })
      ).subscribe(
        res => {
          this.GetAddressesList = res;
          if (this.GetAddressesList.length != 0) {
            this.adress = this.GetAddressesList[0].Address;
            this.city = this.GetAddressesList[0].City;
            this.prov = this.GetAddressesList[0].Prov;
          }
          this.loadIndirizzi = false
        }
      )
    }
  }

  nomeReferente;
  Descrizione;
  Indirizzo;
  Citta;
  cap;
  Paese = 'Italia';
  Telefono;
  provincia;
  StreetNumber;
  FlagPrivacy: boolean = false;
  FlagPrivacyFattura: boolean = false;
  AgreeUseData: boolean = false;

  updateAdress: boolean = false;

  errorAddAdress = '';
  addAdressResult: any = [];
  errorcheckAddress: boolean = false;
  addAdress(IdGestionale) {
    if (this.nomeReferente != '' && this.Telefono != null && this.Indirizzo != '' && this.provincia != '' && this.Citta != '' && this.cap != '' && this.Paese != '' && this.StreetNumber != '') {
      this.service.addAdress(this.Descrizione, this.nomeReferente, this.Telefono.e164Number, this.Indirizzo, this.provincia, this.Citta, this.cap, this.Paese, IdGestionale, this.StreetNumber).pipe(
        catchError(error => {
          return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
        })
      ).subscribe(
        res => {
          this.addAdressResult = res;
          this.GetAddresses(IdGestionale, true);
          this.updateAdress = false;
          this.addNewAdress = false;
        }
      )
    } else {
      this.errorAddAdress = 'Compila i dati obbligatori';
    }
  }

  addAgreeData() {
    if (this.AgreeUseData == false) {
      this.AgreeUseData = true
    } else {
      this.AgreeUseData = false
    }
  }
  changeprivacyFattura() {
    if (this.FlagPrivacyFattura == false) {
      this.FlagPrivacyFattura = true
    } else {
      this.FlagPrivacyFattura = false
    }
  }


  UpdateAdressResult: any = [];
  UpdateAdress(IdGestionale) {
    this.service.UpdateAdress(this.Descrizione, this.nomeReferente, this.Telefono.e164Number, this.Indirizzo, this.provincia, this.Citta, this.cap, this.Paese, IdGestionale, this.getAddresse[0].Row, this.getAddresse[0].Default, this.StreetNumber).pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.UpdateAdressResult = res;
        this.GetAddresses(IdGestionale, true);
        this.updateAdress = false;
      }
    )
  }



  Delete: boolean = false;
  DeleteAddressResult: any = [];
  DeleteAddress(IdGestionale, Row) {
    this.Delete = true;
    this.service.DeleteAddress(IdGestionale, Row).pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        setTimeout(() => {
          this.DeleteAddressResult = res;
          this.GetAddresses(IdGestionale, true);
          this.Delete = false;
          this.deleteI = false;
        }, 900)

      }
    )
  }

  clearAdr() {
    this.nomeReferente = '';
    this.Descrizione = '';
    this.Indirizzo = '';
    this.Citta = '';
    this.cap = '';
    this.Paese = 'Italia';
    this.Telefono = '';
    this.provincia = '';
    this.StreetNumber = '';
  }

  favAdr(id, row, sflt, ReferenceName, ReferencePhoneNumber, Address, prov, City, Cap, Country, StreetNumber, desc) {
    this.service.UpdateFavAdr(desc, ReferenceName, ReferencePhoneNumber, Address, prov, City, Cap, Country, id, row, sflt, StreetNumber).pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.UpdateAdressResult = res;
        this.GetAddresses(id, true);
      }
    )
  }

  FDatadinascita;
  Fiva = '';
  Fcf = '';
  Fsdi = '';
  Fpec = '';
  FCitta;
  FProvincia;
  Fcap;
  Esigibilita = 'I';
  Tipologie;
  FCountry = 'Italia';
  FIndirizzo;
  Fcivico;
  Fnome = '';
  Fcognome = '';
  FragioneSociale = '';
  listino = '';

  datiFatturazione: boolean = false;
  DatiFatturazioni: any = [];
  GetDatiFat(id) {
    this.service.GetDatiFat(id).pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.DatiFatturazioni = res;
        this.listino = this.DatiFatturazioni.PriceListDescr;
        this.FDatadinascita = this.DatiFatturazioni.InvoiceData.BirthDate == null ? '' : (this.DatiFatturazioni.InvoiceData.BirthDate).slice(0, -9);
        this.Fiva = this.DatiFatturazioni.InvoiceData.PIva == null ? '' : this.DatiFatturazioni.InvoiceData.PIva;
        this.Fcf = this.DatiFatturazioni.InvoiceData.CodFis == null ? '' : this.DatiFatturazioni.InvoiceData.CodFis;
        this.Fsdi = this.DatiFatturazioni.InvoiceData.RecipientCodeFe == null ? '' : this.DatiFatturazioni.InvoiceData.RecipientCodeFe;
        this.Fpec = this.DatiFatturazioni.InvoiceData.EmailPec == null ? '' : this.DatiFatturazioni.InvoiceData.EmailPec;
        this.FCitta = this.DatiFatturazioni.InvoiceData.City;
        this.FProvincia = this.DatiFatturazioni.InvoiceData.Prov;
        this.Fcap = this.DatiFatturazioni.InvoiceData.Cap;
        this.Esigibilita = this.DatiFatturazioni.InvoiceData.Vatexigibility == null ? 'I' : this.DatiFatturazioni.InvoiceData.Vatexigibility;
        this.Tipologie = this.DatiFatturazioni.InvoiceData.CustomerTypeFe == null ? this.DatiFatturazioni.IsPhysicalPerson == true ? "IVA" : "B2B" : this.DatiFatturazioni.InvoiceData.CustomerTypeFe;
        this.FCountry = this.DatiFatturazioni.InvoiceData.Country == null ? 'Italia' : this.DatiFatturazioni.InvoiceData.Country;
        this.FIndirizzo = this.DatiFatturazioni.InvoiceData.Address;
        this.Fcivico = this.DatiFatturazioni.InvoiceData.StreetNumber;
        this.Fnome = this.DatiFatturazioni.InvoiceData.Name;
        this.Fcognome = this.DatiFatturazioni.InvoiceData.Surname;
        this.FragioneSociale = this.DatiFatturazioni.InvoiceData.CompanyName;
        this.FlagPrivacyFattura = this.DatiFatturazioni.FlagPrivacy == true ? true : false;
      }
    )
  }

  CustomerTpes: any = [];
  GetCustomerTypes() {
    if (this.CustomerTpes.length == 0) {
      this.service.GetCustomerTypes().pipe(
        catchError(error => {
          return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
        })
      ).subscribe(
        res => {
          this.CustomerTpes = res;
        }
      )
    }
  }

  VatExigibility: any = [];
  GetVatExigibility() {
    if (this.VatExigibility.length == 0) {
      this.service.GetVatExigibility().pipe(
        catchError(error => {
          return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
        })
      ).subscribe(
        res => {
          this.VatExigibility = res;
        }
      )
    }
  }

  getAddresse: any = [];
  getAdd(item) {
    this.getAddresse = this.GetAddressesList.filter(x =>
      x.$id == item
    );
    console.log(this.getAddresse);
    this.nomeReferente = this.getAddresse[0].ReferenceName;
    this.Descrizione = this.getAddresse[0].Description;
    this.Indirizzo = this.getAddresse[0].Address;
    this.Citta = this.getAddresse[0].City;
    this.cap = this.getAddresse[0].Cap;
    this.Paese = this.getAddresse[0].Country;
    this.Telefono = this.getAddresse[0].ReferencePhoneNumber;
    this.provincia = this.getAddresse[0].Prov;
    this.StreetNumber = this.getAddresse[0].StreetNumber;
  }


  Loginresult: any = [];
  refreshUser() {
    this.service.login(this.Profilo.Username, this.Profilo.Password).pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.Loginresult = res;
        if (this.Loginresult != null) {
          localStorage.setItem("profil", JSON.stringify(this.Loginresult));
          localStorage.setItem("nome", JSON.stringify(this.Loginresult.Name));
          this.Profilo = this.Loginresult;
        }
      }
    )
    this.GetDatiFat(this.Profilo.IdGestionale)
  }

  erreurCodiceFs: boolean = false;
  erreurSDI: boolean = false;
  erreurFiva: boolean = false;
  emailErrorformat: boolean = false;
  checkstatutFieldF() {
    this.Fcf.length < 16 && this.Fiva == '' && this.Tipologie == 'IVA' ? this.erreurCodiceFs = true : this.erreurCodiceFs = false;
    if (this.Tipologie == 'PA') {
      this.Fsdi.length < 6 && this.Fsdi != '' ? this.erreurSDI = true : this.erreurSDI = false;
    } else {
      this.Fsdi.length < 7 && this.Fsdi != '' ? this.erreurSDI = true : this.erreurSDI = false;
    }
    if (this.Fiva.length < 11 && (this.FCountry == 'Italia' || this.FCountry == null) && this.Fiva != '') {
      this.erreurFiva = true;
    } else {
      this.erreurFiva = false;
    }

    if (this.Tipologie == 'IVA' ? (this.Fnome != '' && this.Fcognome != '') : this.Tipologie == 'PA' ? this.FragioneSociale != '' : (this.FragioneSociale != '' || (this.Fnome != '' && this.Fcognome != '')) && ((this.Tipologie != 'IVA' ? (this.Fiva != '' || this.Fcf != '') : this.Fcf != '') && (this.Tipologie != 'IVA' ? (this.Fsdi != '' || this.Fpec != '') : 1 == 1)) && (this.FCitta != '' && this.FProvincia != '' && this.Fcap != '' && this.Tipologie != '' && this.Esigibilita != '' && this.erreurSDI == false && this.erreurCodiceFs == false)) {
      this.updateFattError = '';
    } else {
      this.updateFattError = 'Compila i dati obbligatori';
    }

    if (!this.validateEmail(this.Fpec) && this.Fpec != '') {
      this.emailErrorformat = true;
    } else {
      this.emailErrorformat = false;
    }
  }
  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  deleteI: boolean = false;

  ordini: boolean = false;
  ordiniList: any = [];
  loadOrdini: boolean = false;
  GetFidelityDocuments() {
    this.loadOrdini = true;
    this.service.GetFidelityDocuments(this.Profilo.FidelityCardId, true).pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.ordiniList = res;
        this.loadOrdini = false;
      }
    )
  }

  checkDisponibilitaYear(year) {
    return this.ordiniList.findIndex(x => new Date(x.Testata.DataDoc).getFullYear() == year) != -1
  }

  checkYear(datetime, year) {
    return new Date(datetime).getFullYear() == year
  }


  getQteX(item) {
    return item.Quantità;
  }

  selectedOrdini: boolean = false;
  selectedOrd: any = [];

  existtN: boolean = false;
  existtA: boolean = false;
  existtR: boolean = false;

  selectOrdini(item) {
    this.riordinaList = [];
    this.selectedOrd = item;
    for (let j = 0; j < this.selectedOrd.Righe.length; j++) {
      for (let r = 0; r < this.selectedOrd.Righe[j].Modifiers.length; r++) {
        if (this.selectedOrd.Righe[j].Modifiers[r].Type == 'A') {
          this.existtA = true;
        }
        if (this.selectedOrd.Righe[j].Modifiers[r].Type == 'R') {
          this.existtR = true;
        }
        if (this.selectedOrd.Righe[j].Modifiers[r].Type == 'N') {
          this.existtN = true;
        }
      }
    }
  }

  selectedIndex: number = null;
  setIndex(index: number) {
    this.selectedIndex = index;
  }

  riordinaList: Array<{ IdProduct: number, Title: string, Description: string, Code: string, Price: number, qte: number, VatCode: string, TotaleIvato: number }> = [];
  totRiordinaList = 0;
  addtoRiordina(item) {
    var objIndex = this.riordinaList.findIndex((obj => obj.Description == item.Descrizione));
    if (this.riordinaList[objIndex]) {
      this.riordinaList.splice(objIndex, 1);
    } else {
      this.riordinaList.push({ IdProduct: item.IdProduct, Title: item.Descrizione, Description: item.Descrizione, Code: item.Articolo, Price: item.PrezzoUnit, qte: item.Quantità, VatCode: item.Iva, TotaleIvato: item.TotaleIvato });
    }
    this.totRiordinaList = this.riordinaList.reduce((accumulator, obj) => {
      return accumulator + obj.TotaleIvato;
    }, 0);
  }

  carrello: Product[] = [];
  product: any = [];
  cnt = 0;
  openAddTocarrello: boolean = false;
  loaderAddC: boolean = false;

  addToCarrello_SvuotaCarrello() {
    this.cnt = 0;
    this.loaderAddC = true;
    this.carrello = [];
    for (let index = 0; index < this.riordinaList.length; index++) {
      this.service.GetProductsWebDetail(this.riordinaList[index].IdProduct, true).pipe(
        catchError(error => {
          return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
        })
      ).subscribe(
        products => {
          this.product = products;
          this.carrello.push({ Id: this.product.Id, Title: this.product.Title, Weight: this.product.Weight == null ? 0 : this.product.Weight, Code: this.product.Code, Description: this.product.Description, GroupID: this.product.GroupID, urlImages: this.product.urlImages != null ? this.product.urlImages[0] : null, Price: this.product.Price, qte: this.riordinaList[index].qte, ShopID: this.product.ShopID, VatCode: this.product.VatCode, listExtraDatas: this.product.listExtraDatas, Idchild: this.product.Idchild,IdFather:this.product.IdFather,Discount:this.product.Discount,OriginalPrice:this.product.OriginalPrice });
          this.cnt += 1;
          if (this.cnt == this.riordinaList.length) {
            localStorage.setItem('carrello', JSON.stringify(this.carrello));
            this.router.navigate(['carrello'])
              .then(() => {
                window.location.reload();
              });
          }
        }
      );
    }
  }

  addToCarrello() {
    this.cnt = 0;
    for (let index = 0; index < this.riordinaList.length; index++) {
      this.service.GetProductsWebDetail(this.riordinaList[index].IdProduct, true).pipe(
        catchError(error => {
          return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
        })
      ).subscribe(
        products => {
          this.cnt += 1;
          this.product = products;
          localStorage.setItem('qte', JSON.stringify(this.riordinaList[index].qte));
          localStorage.setItem('from', 'profile');
          this.messanger.sendProduct(this.product);
          if (this.cnt == this.riordinaList.length) {
            this.router.navigate(['carrello'])
              .then(() => {
                window.location.reload();
              });
          }
        }
      );
    }
  }


  provinces: any = [];
  GetProvinces() {
    if (this.provinces.length == 0) {
      this.service.GetProvinces().pipe(
        catchError(error => {
          return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
        })
      ).subscribe(
        res => {
          this.provinces = res;
        }
      )
    }
  }

}
