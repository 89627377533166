import { Component, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { MessengerService } from '../services/messenger.service';
import { SideNavService } from '../services/side-nav.service';
import { Product } from 'src/app/models/product'
import { Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ServiceService } from '../services/service.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateCacheService } from 'ngx-translate-cache';
import { filter } from 'rxjs/operators';

import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  subscription: Subscription;
  lastProduct: any = [];
  Groups: any = [];
  currentLanguage: string;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, public translate: TranslateService, translateCacheService: TranslateCacheService, public sideNavService: SideNavService, private messanger: MessengerService, public router: Router, private route: ActivatedRoute, public service: ServiceService) {
    if (isPlatformBrowser(this.platformId)) {
      this.refreshCarrello();
    }
    translate.setDefaultLang('it');
    translateCacheService.init();
  }

  refreshCarrello() {
    this.messanger.getProduct().subscribe((product: Product) => {
      if (product == undefined) {
        console.log('clearrr');
        localStorage.removeItem('carrello');
        this.product = [];
      } else {
        this.lastProduct = [];
        this.lastProduct.push(product);
        this.addToCart(product)
      }
    });
  }

  scrolled: boolean = false;
  @HostListener("window:scroll", [])
  onWindowScroll() {
    this.scrolled = window.scrollY > 0;
  }
  scrollbarTop() {
    window.scroll(0, 0);
  }


  changeLang(lang: string) {
    if (this.translate.store.currentLang !== lang) {
      this.translate.use(lang);
      localStorage.setItem('langChanged', 'true');
      this.openlang = !this.openlang;
      // Regular expression to match language codes (assuming they consist of lowercase letters)
      const languageCodeRegex = /^\/[a-z]+\//;
      // Get the current route without language prefix
      const currentRouteWithoutLang = this.router.url.replace(languageCodeRegex, '/');
      // Determine the new route with the selected language prefix
      let newRoute: string;
      if (lang === 'en') {
        newRoute = `/en${currentRouteWithoutLang}`;
      } else {
        newRoute = `/${currentRouteWithoutLang}`;
      }
      // Navigate to the new route
      this.router.navigateByUrl(newRoute);
      setTimeout(() => {
        location.reload();
      }, 500);
    }
  }

  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
    if (isPlatformBrowser(this.platformId)) {
      this.messanger.clearProduct();
    }
  }

  openlang: boolean = false;

  logedIn: boolean = false;

  categories: any = [];
  categoriesList: any = [];
  categoriesMenu: any = [];
  Profilo: any = [];

  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem("login")) == 'true') {
      this.Profilo = JSON.parse(localStorage.getItem("profil")) == null ? JSON.parse(sessionStorage.getItem("profil")) : JSON.parse(localStorage.getItem("profil"));
      this.logedIn = true;
    }
    if (JSON.parse(localStorage.getItem('carrello')) != null) {
      this.product = JSON.parse(localStorage.getItem('carrello'));
      this.CalculeTotalCarrello();
    }
    this.refreshGroups();

    if (this.Profilo.length != 0) {
      if (this.Profilo.IdContactsCredential == undefined) {
        this.service.login(this.Profilo.Username, this.Profilo.Password).subscribe(
          res => {
            this.Loginresult = res;
            if (this.Loginresult != null) {
              localStorage.setItem("profil", JSON.stringify(this.Loginresult));
              localStorage.setItem("nome", JSON.stringify(this.Loginresult.Name));
              this.Profilo = this.Loginresult;
              this.GetFavouritesCount();
            }
          }
        )
      } else {
        this.GetFavouritesCount();
      }
    }
  }
  Loginresult: any = [];


  GetFavouritesCount() {
    this.service.GetFavouritesCount(this.Profilo.IdContactsCredential);
  }

  refreshGroups() {
    this.service.GroupsList.subscribe(data => {
      this.categories = data;
      this.categoriesList = data;
      this.categoriesMenu = data;
    });
  }

  fakelist: any[] = new Array(9);

  qte;
  product: Product[] = [];
  modal: boolean = false;

  addToCart(product: Product) {
    if (localStorage.getItem('deleteItem') == 'true') {
      for (let index = 0; index < this.product.length; index++) {
        if (this.product[index].Code == product.Code) {
          this.product.splice(index, 1);
          localStorage.setItem('deleteItem', '');
        }
      }
    } else {
      if (JSON.parse(localStorage.getItem('qte')) != null) {
        this.qte = JSON.parse(localStorage.getItem('qte'));
      }
      let productExist = false;
      for (let index = 0; index < this.product.length; index++) {
        if (this.product[index].Code == product.Code) {
          this.product[index].qte = this.product[index].qte + this.qte;
          productExist = true;
          break;
        }
      }
      if (!productExist) {
        this.product.push({ Id: product.Id, Title: product.Title, Weight: product.Weight == null ? 0 : product.Weight, Code: product.Code, Description: product.Description, GroupID: product.GroupID, urlImages: product.urlImages != null ? product.urlImages[0] : null, Price: product.Price, qte: this.qte, ShopID: product.ShopID, VatCode: product.VatCode, listExtraDatas: product.listExtraDatas, Idchild: product.Idchild, IdFather: product.IdFather, Discount: product.Discount, OriginalPrice: product.OriginalPrice });
      }
      if (localStorage.getItem('from') == 'prod-det' && localStorage.getItem('deleteItem') != 'true') {
        this.modal = true;
      }
    }

    localStorage.setItem('carrello', JSON.stringify(this.product));
    localStorage.setItem('qte', JSON.stringify(1));
    this.CalculeTotalCarrello();
  }

  TotalCarrello = 0;
  CalculeTotalCarrello() {
    this.TotalCarrello = 0;
    this.product.forEach(item => {
      this.TotalCarrello += (item.qte * item.Price)
    })
  }

  removeProduct(item) {
    const index: number = this.product.indexOf(item);
    if (index !== -1) {
      this.product.splice(index, 1);
    }
    localStorage.setItem('carrello', JSON.stringify(this.product));
    this.CalculeTotalCarrello();

    this.refreshCarrello();
    // location.reload();
  }

  clickMenu() {
    this.sideNavService.toggle();
  }

  closeModal() {
    this.modal = false;
  }

  showCarrello: boolean = false;
  searchActivated: boolean = false;
  searchValue = '';


  toResult() {
    this.searchActivated = false;
    this.categoriesList = [];
    if (this.searchValue != '') {
      this.router.navigateByUrl('/result/' + this.searchValue.replace(/\/$/, ""));
      this.searchValue = '';
    }
  }

  toProduct(id){
    this.closeMenuSearch();
    this.router.navigateByUrl('/categoria/' + id);
  }

  closeMenuSearch() {
    this.searchActivated = false; this.showCarrello = false; this.searchValue = ''; this.categoriesList = [];
  }

  goToProduct(item) {
    if (item.IdFather==null) {
      this.router.navigateByUrl('/prodotto/' + item.Id);
    }else {
      this.service.childId=item.Code;
      this.router.navigateByUrl('/prodotto/' + item.IdFather);
    }
  }

}
