<section class="bg-section-grey pt-5" style="min-height: 86vh">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="d-flex align-items-baseline">
          <h2 class="d-flex flex-wrap align-items-baseline w-75 title"><img loading="lazy" src="assets/img/account.svg"
              width="24" class="mr-3">
            {{'Profile.section1.title' | translate}}
            {{Profilo.Name}} <small class="ml-2">({{listino}})</small></h2>
          <h5 (click)="logOut()" style="cursor: pointer;" class="ml-auto"><img loading="lazy"
              src="assets/img/logout.svg" width="24">
          </h5>
        </div>
        <hr>
        <div class="row">
          <div class="col-lg-3">
            <div class="main-carrello mt-4">
              <div class="row">
                <div class="d-flex justify-content-center flex-wrap">
                  <button class="btn profil mb-2" [ngClass]="{'active': datiPersonali==true}"
                    (click)="clearActivated();datiPersonali=true">
                    <img loading="lazy" src="assets/img/personale.svg" class="mr-4">{{'Profile.section1.datipers' |
                    translate}}
                  </button>

                  <button class="btn profil mb-2" [ngClass]="{'active': Indirizzi==true}"
                    (click)="clearActivated();Indirizzi=true;GetAddresses(Profilo.IdGestionale,false)">
                    <img loading="lazy" src="assets/img/spedezione.svg" class="mr-4">{{'Profile.section1.indirizzi' |
                    translate}}
                  </button>

                  <button class="btn profil mb-2" [ngClass]="{'active': datiFatturazione==true}"
                    (click)="clearActivated();openDataFatturazione();">
                    <img loading="lazy" src="assets/img/fatturazione.svg" class="mr-4">{{'Profile.section1.fatturazione'
                    |
                    translate}}
                  </button>

                  <button class="btn profil mb-2 mt-4 mt-md-0 mt-lg-4" [ngClass]="{'active': ordini==true}"
                    (click)="clearActivated();ordini=true;GetFidelityDocuments()">
                    <img loading="lazy" src="assets/img/ordini.svg" class="mr-4">{{'Profile.section1.ordini' |
                    translate}}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-9">
            <div class="main-carrello mt-4">
              <!-- i miei ordini -->
              <div class="row" *ngIf="ordini==true">
                <div class="col-lg-12 mx-auto mb-3">
                  <div class="d-flex mt-2 align-items-center">
                    <h4 class="d-flex align-items-center w-75"><img loading="lazy" src="assets/img/ordini.svg"
                        class="mx-3">
                      {{'Profile.ordini.ord' | translate}}
                    </h4>
                  </div>
                  <div class="row mt-4">
                    <div class="col-lg-6 mx-ordini" [ngClass]="{'col-lg-12': selectedOrdini==false}">
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" *ngIf="checkDisponibilitaYear(yearNow-1)">
                          <a class="nav-link" [ngClass]="{'active': selectyearNow==false}" (click)="selectyearNow=false"
                            aria-controls="profile" aria-selected="false">{{yearNow - 1}}</a>
                        </li>
                        <li class="nav-item" *ngIf="checkDisponibilitaYear(yearNow)">
                          <a class="nav-link" [ngClass]="{'active': selectyearNow==true}" (click)="selectyearNow=true"
                            aria-controls="home" aria-selected="true">{{yearNow}}</a>
                        </li>
                      </ul>
                      <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show" [ngClass]="{'active': selectyearNow==false}">
                          <div *ngFor="let item of ordiniList; let i = index">
                            <div *ngIf="item.Testata!=null && checkYear(item.Testata.DataDoc,yearNow - 1)"
                              class="d-flex b-t" [class.active]="selectedIndex === i"
                              (click)="selectOrdini(item);selectedOrdini=true" (click)="setIndex(i)">
                              <b>{{'Profile.ordini.Nord' | translate}}{{item.Testata.NumDoc}}</b>
                              <span class="ml-auto">{{item.Testata.DataDoc | date:'dd/MM/yyyy'}}</span>
                              <span class="ml-auto">{{item.Testata.TotaleIvato | currency : "EUR" : "symbol" : "1.2-2" :
                                "it" }}</span>
                              <img loading="lazy" class="ml-auto" src="assets/img/arrow-r.svg" style="cursor: pointer;">
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade show" [ngClass]="{'active': selectyearNow==true}">
                          <div *ngFor="let item of ordiniList; let i = index">
                            <div *ngIf="item.Testata!=null && checkYear(item.Testata.DataDoc,yearNow)"
                              class="d-flex b-t" [class.active]="selectedIndex === i"
                              (click)="selectOrdini(item);selectedOrdini=true" (click)="setIndex(i)">
                              <b>{{'Profile.ordini.Nord' | translate}}{{item.Testata.NumDoc}}</b>
                              <span class="ml-auto">{{item.Testata.DataDoc | date:'dd/MM/yyyy'}}</span>
                              <span class="ml-auto">{{item.Testata.TotaleIvato | currency : "EUR" : "symbol" : "1.2-2" :
                                "it" }}</span>
                              <img loading="lazy" class="ml-auto" src="assets/img/arrow-r.svg" style="cursor: pointer;">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 px-4" *ngIf="selectedOrdini==true">
                      <div class="order">
                        <div class="d-flex mt-2 align-items-center">
                          <h5><b>{{'Profile.ordini.Nord' |
                              translate}}{{selectedOrd.Testata.NumDoc}}</b> - <b>
                              {{selectedOrd.Testata.TotaleIvato | currency : "EUR" : "symbol" : "1.2-2" : "it" }}</b>
                          </h5>
                          <img loading="lazy" src="assets/img/close.svg" (click)="selectedOrdini=false"
                            style="cursor: pointer;" class="ml-auto" width="24">
                        </div>
                        <h6 class="mt-2">{{'Profile.ordini.spec' | translate}}</h6>
                        <div *ngFor="let rig of selectedOrd.Righe" class="box-rounded my-3 checka p-2">
                          <div class="d-flex flex-wrap justify-content-between align-items-center">
                            <div class="d-flex align-items-center">
                              <mat-checkbox class="checkabs mt-2" (change)="addtoRiordina(rig)"></mat-checkbox>
                              <div class="d-flex flex-wrap ml-3">
                                <p class="mb-0">{{rig.Descrizione}}</p>
                              </div>
                            </div>
                            <div class="d-flex flex-wrap align-items-center ml-auto">
                              <h6 class="s-title ml-1 fw-normal mr-3 mb-0"><span> {{'Profile.ordini.qta' | translate}}:
                                </span>
                                <b>{{getQteX(rig)}}</b>
                              </h6>
                              <b>{{rig.TotaleIvato | currency : "EUR" : "symbol" : "1.2-2" : "it" }}</b>
                            </div>
                          </div>
                        </div>
                        <h5 class="mb-3" *ngIf="totRiordinaList!=0"><b> {{'Profile.ordini.tot' | translate}}:
                            {{totRiordinaList | currency : "EUR" : "symbol" : "1.2-2" : "it" }}</b></h5>
                        <button class="btn btn-bg w-100" (click)="openAddTocarrello=true"
                          [disabled]="riordinaList.length==0">{{'Profile.ordini.reorder' | translate}}</button>
                        {{resultSelectedOrdini}}
                        <div class="alert alert-danger" *ngIf="notDisponible==true">
                          {{'Profile.ordini.noprod' | translate}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <p *ngIf="ordiniList.length==0 && loadOrdini==false" class="mt-4">{{'Profile.ordini.noord' |
                    translate}}</p>
                  <div class="w-100 d-flex justify-content-center" *ngIf="loadOrdini==true">
                    <img loading="lazy" class="d-block mt-4 mx-auto" width="120" height="120"
                      src="assets/img/reload.gif" alt="loader horse">
                  </div>
                </div>
              </div>
              <!-- Dati Fatturazione -->
              <div class="row" *ngIf="datiFatturazione==true">
                <div class="col-lg-12 mx-auto mb-3">
                  <div class="d-flex mt-2 align-items-center">
                    <h4 class="d-flex align-items-center w-75"><img loading="lazy" src="assets/img/fatturazione.svg"
                        class="mx-3">{{'Profile.ordini.datifatt' | translate}} </h4>
                    <h5 style="cursor: pointer;" class="ml-auto" (click)="update=true;done=false"><img
                        src="assets/img/update.svg"></h5>
                  </div>
                  <div class="w-100 d-flex justify-content-center" *ngIf="openF==true">
                    <img loading="lazy" class="d-block mt-4 mx-auto" width="120" height="120"
                      src="assets/img/reload.gif" alt="loader horse">
                  </div>
                  <form class="mt-4" *ngIf="openF==false">
                    <div class="row">
                      <div class=" col-lg-12">
                        <label for=""> {{'Pagamento.fattura.tipCliente' | translate}}</label>
                        <mat-radio-group name="Tipologie" [(ngModel)]="Tipologie" required
                          (change)="checkstatutFieldF()" class="mt-1 d-flex flex-wrap w-100 justify-content-around"
                          [disabled]="update==false">
                          <mat-radio-button *ngFor="let item of CustomerTpes" value="{{item.Code}}">
                            {{(item.Description=='B2B - AZIENDA'&&service.currentLang=='en')?'B2B - COMPANY':(item.Description=='IVA - PRIVATO'&&service.currentLang=='en')?'VAT - PRIVATE':(item.Description=='PA - PUBBLICA AMMINISTRAZIONE'&&service.currentLang=='en')?'PA - PUBLIC ADMINISTRATION':item.Description}}
                          </mat-radio-button>
                        </mat-radio-group>
                      </div>

                      <div class=" col-lg-12" *ngIf="Tipologie!='IVA'">
                        <label for="">{{'Pagamento.fattura.ragSoc' | translate}}</label>
                        <input type="text" name="FragioneSociale" [(ngModel)]="FragioneSociale"
                          [required]="Tipologie=='PA' || (Fnome=='' && Fcognome=='')" [disabled]="update==false"
                          (change)="checkstatutFieldF()">
                      </div>
                      <div class=" col-lg-6" *ngIf="Tipologie!='PA'">
                        <label for="">{{'Pagamento.section1.nome' | translate}}</label>
                        <input type="text" name="Fnome" [(ngModel)]="Fnome"
                          [required]="FragioneSociale=='' || Tipologie=='IVA'" [disabled]="update==false"
                          (change)="checkstatutFieldF()">
                      </div>
                      <div class=" col-lg-6" *ngIf="Tipologie!='PA'">
                        <label for="">{{'Pagamento.section1.cognome' | translate}}</label>
                        <input type="text" name="Fcognome" [(ngModel)]="Fcognome"
                          [required]="FragioneSociale=='' || Tipologie=='IVA'" [disabled]="update==false"
                          (change)="checkstatutFieldF()">
                      </div>

                      <div class=" col-lg-8">
                        <label for="">{{'Pagamento.section1.via' | translate}}</label>
                        <input type="text" name="FIndirizzo" [(ngModel)]="FIndirizzo" required
                          [disabled]="update==false">
                      </div>
                      <div class=" col-lg-4">
                        <label for="">{{'Pagamento.section1.civico' | translate}}</label>
                        <input type="text" name="Fcivico" [(ngModel)]="Fcivico" required [disabled]="update==false">
                      </div>
                      <div class=" col-lg-6">
                        <label for="">{{'Pagamento.fattura.naz' | translate}}</label>
                        <select [(ngModel)]="FCountry" name="FCountry" [disabled]="update==false">
                          <option [selected]="true" [ngValue]="Italia">Italia</option>
                          <option value="{{item.Country}}" *ngFor="let item of CountryCodes">
                            {{item.Country}}
                          </option>
                        </select>
                      </div>
                      <div class=" col-6">
                        <label for="">{{'Pagamento.section1.prov' | translate}}</label>
                        <select name="FProvincia" [(ngModel)]="FProvincia" required [disabled]="update==false">
                          <option *ngFor="let item of provinces" value="{{item.Acronym}}">
                            {{item.Acronym}} | {{item.Description}}
                          </option>
                        </select>
                      </div>
                      <div class=" col-6">
                        <label for="">{{'Pagamento.section1.citta' | translate}}</label>
                        <input type="text" name="FCitta" [(ngModel)]="FCitta" required [disabled]="update==false">
                      </div>
                      <div class=" col-lg-3">
                        <label for="">{{'Pagamento.section1.CAP' | translate}}</label>
                        <input type="text" name="Fcap" [(ngModel)]="Fcap" required [disabled]="update==false">
                      </div>
                    </div>
                    <hr>
                    <div class="px-3 row">
                      <div class=" col-lg-6" *ngIf="Tipologie!='IVA'">
                        <label for="">{{'Pagamento.fattura.piva' | translate}}</label>
                        <input type="text" name="Fiva" [(ngModel)]="Fiva" [disabled]="update==false"
                          [required]="Fcf==null || Fcf=='' && Tipologie!='IVA'" (change)="checkstatutFieldF()"
                          [minlength]="(FCountry=='Italia' || FCountry==null)?11:0"
                          [maxlength]="(FCountry=='Italia' || FCountry==null)?11:100">
                        <small *ngIf="erreurFiva==true" class="text-danger">
                          {{'Pagamento.fattura.pivaalert' | translate}}
                        </small>
                      </div>
                      <div class=" col-lg-6">
                        <label for="">{{'Pagamento.fattura.cf' | translate}}</label>
                        <input type="text" name="Fcf" [(ngModel)]="Fcf" [disabled]="update==false"
                          [required]="Fiva==null || Fiva==''" (change)="checkstatutFieldF()"
                          [minlength]="Tipologie=='IVA'?16:0" [maxlength]="Tipologie=='IVA'?16:100">
                        <small *ngIf="erreurCodiceFs==true" class="text-danger">
                          {{'Pagamento.fattura.cfalert' | translate}}
                        </small>
                      </div>
                      <div class=" col-lg-6" *ngIf="Tipologie!='IVA'">
                        <label for="">{{'Pagamento.fattura.sdi' | translate}}</label>
                        <input type="text" name="Fsdi" [(ngModel)]="Fsdi" [disabled]="update==false"
                          [required]="Fpec==null || Fpec=='' && Tipologie!='IVA'" (change)="checkstatutFieldF()"
                          [maxlength]="Tipologie=='PA'?6:7" [minlength]="Tipologie=='PA'?6:7">
                        <small *ngIf="erreurSDI==true && Tipologie=='PA'" class="text-danger">
                          {{'Pagamento.fattura.sdialert' | translate}}
                        </small>
                        <small *ngIf="erreurSDI==true && Tipologie!='PA'" class="text-danger">
                          {{'Pagamento.fattura.sdialert2' | translate}}
                        </small>
                      </div>
                      <div class=" col-lg-6" *ngIf="Tipologie!='IVA'">
                        <label for="">Pec</label>
                        <input type="email" name="Fpec" [(ngModel)]="Fpec" [disabled]="update==false"
                          [required]="Fsdi==null || Fsdi=='' && Tipologie!='IVA'" (change)="checkstatutFieldF()"
                          [ngClass]="{'required':emailErrorformat==true}">
                        <small *ngIf="emailErrorformat==true" class="text-danger">{{'Pagamento.fattura.pecalert' |
                          translate}}</small>
                      </div>
                      <div class=" col-lg-6">
                        <label for="">{{'Pagamento.fattura.esig' | translate}}</label>
                        <select name="Esigibilita" [(ngModel)]="Esigibilita" required [disabled]="update==false"
                          (change)="checkstatutFieldF()">
                          <option *ngFor="let item of VatExigibility" value="{{item.Code}}">
                            {{item.Description}}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-6 mx-auto my-3 alert alert-danger" *ngIf="updateFattError!=''">
                      {{updateFattError}}
                    </div>
                    <div class="px-3 mt-4 mb-3" *ngIf="update==true">
                      <button class="btn btn-bg mx-auto d-block w-50"
                        (click)="SaveContact(Profilo.IdGestionale)">{{'Profile.ordini.conferma' | translate}}</button>
                    </div>
                    <div class="d-flex justify-content-center align-items-center w-100 mt-4">
                      <mat-spinner *ngIf="save==true"></mat-spinner>
                      <img loading="lazy" src="assets/img/check.svg" alt="" width="40" class="mr-3" *ngIf="done==true">
                      <span *ngIf="done==true">{{'Profile.ordini.okmod' | translate}}</span>
                    </div>
                  </form>
                </div>
              </div>
              <!-- Dati personali -->
              <div class="row" *ngIf="datiPersonali==true">
                <div class="col-lg-12 mx-auto mb-3">
                  <div class="d-flex mt-2 align-items-center">
                    <h4 class="d-flex align-items-center w-75"><img loading="lazy" src="assets/img/personale.svg"
                        class="mx-3">
                      {{'Pagamento.section1.dati' | translate}}
                    </h4>
                    <h5 style="cursor: pointer;" class="ml-auto" (click)="update=true;done=false"><img
                        src="assets/img/update.svg"></h5>
                  </div>
                  <form class="mt-4">
                    <div class="px-3 row">
                      <div class=" col-lg-12" *ngIf="Profilo.IsPhysicalPerson!=true">
                        <label for="">{{'Pagamento.fattura.ragSoc' | translate}}*</label>
                        <input type="text" name="CompanyName" [(ngModel)]="CompanyName"
                          [required]="nome=='' && cognome==''" [disabled]="update==false">
                      </div>
                      <div class=" col-lg-6" *ngIf="Profilo.IsPhysicalPerson==true">
                        <label for="">{{'Pagamento.section1.nome' | translate}}*</label>
                        <input type="text" name="nome" [(ngModel)]="nome" [required]="CompanyName==''"
                          [disabled]="update==false">
                      </div>
                      <div class=" col-lg-6" *ngIf="Profilo.IsPhysicalPerson==true">
                        <label for="">{{'Pagamento.section1.cognome' | translate}}*</label>
                        <input type="text" name="cognome" [(ngModel)]="cognome" [required]="CompanyName==''"
                          [disabled]="update==false">
                      </div>
                      <div class=" col-lg-6">
                        <label for="">{{'Pagamento.section1.email' | translate}}* <small>{{'Profile.ordini.ricezione' |
                            translate}}</small></label>
                        <input type="email" name="email" [(ngModel)]="email" required
                          (keydown.space)="$event.preventDefault();" [disabled]="update==false">
                      </div>
                      <div class=" col-lg-6">
                        <label for="" class="d-block">{{'Pagamento.section1.cell' | translate}}*</label>
                        <ngx-intl-tel-input [preferredCountries]="['it']" [enableAutoCountrySelect]="true"
                          [separateDialCode]="true" [disabled]="update==false" [searchCountryFlag]="true"
                          [selectFirstCountry]="true" (keydown.space)="$event.preventDefault();"
                          [(ngModel)]="mobileNumber" name="mobileNumber"
                          [ngClass]="{'required':mobileNumber==null && update==true}">
                        </ngx-intl-tel-input>
                      </div>
                      <div class=" col-lg-6">
                        <label for="" class="d-block">{{'Pagamento.section1.tel' | translate}}</label>
                        <ngx-intl-tel-input [preferredCountries]="['it']" [enableAutoCountrySelect]="true"
                          [separateDialCode]="true" [disabled]="update==false" [searchCountryFlag]="true"
                          [selectFirstCountry]="true" [(ngModel)]="phoneNumber" name="phoneNumber">
                        </ngx-intl-tel-input>
                      </div>
                    </div>
                    <div class="px-3 mt-3">
                      <mat-checkbox [checked]="FlagPrivacy==true"
                        [disabled]="FlagPrivacy==true">{{'Profile.ordini.privacy'
                        | translate}} <a routerLink="/informativa-privacy" target="blank"
                          style="font-size: inherit">{{'Profile.ordini.privacy2' | translate}}</a>
                      </mat-checkbox><br>
                      <mat-checkbox [disabled]="AgreeUseData==true" [checked]="AgreeUseData==true"
                        (change)="addAgreeData()">{{'Profile.ordini.tratt' | translate}} <a
                          routerLink="/termini-condizioni" target="blank"
                          style="font-size: inherit">{{'Profile.ordini.tratt2' |
                          translate}}</a></mat-checkbox>
                    </div>
                    <div class="d-flex justify-content-center w-100 mt-4 mb-3" *ngIf="update==true">
                      <button class="btn btn-bg mr-2"
                        (click)="SaveContact1(Profilo.IdGestionale)">{{'Profile.ordini.conferma' | translate}}</button>
                      <button class="btn btn-w-border reg ml-2"
                        (click)="annullaSaveContact()">{{'Profile.ordini.annulla'
                        |
                        translate}}</button>
                    </div>
                    <div class="d-flex justify-content-center align-items-center w-100 mt-4">
                      <mat-spinner *ngIf="save==true"></mat-spinner>
                      <img loading="lazy" src="assets/check.svg" alt="" width="40" class="mr-3" *ngIf="done==true">
                      <span *ngIf="done==true">{{'Profile.ordini.okmod' | translate}}</span>
                      <span class="text-danger" *ngIf="saveProfilError==true">{{'Account.section1.datiobbligatori' |
                        translate}}</span>
                    </div>
                  </form>
                </div>
              </div>
              <!-- Indirizzi -->
              <div class="row" *ngIf="Indirizzi==true">
                <div class="col-lg-12 mx-auto mb-3">
                  <div class="d-flex mt-2 justify-content-between">
                    <h4 class="d-flex align-items-center w-75"><img loading="lazy" src="assets/img/spedezione.svg"
                        class="mx-3">
                      {{'Profile.section1.indirizzi' | translate}}</h4>
                    <img loading="lazy" src="assets/img/refresh.svg" width="30" height="30" style="cursor: pointer"
                      (click)="GetAddresses(Profilo.IdGestionale,true)">
                  </div>

                  <div class="w-100 d-flex justify-content-center" *ngIf="loadIndirizzi==true">
                    <img loading="lazy" class="d-block mt-4 mx-auto" width="120" height="120"
                      src="assets/img/reload.gif" alt="loader horse">
                  </div>
                  <div class="row" *ngIf="loadIndirizzi==false">
                    <div class="col-lg-4 mt-3" *ngFor="let item of GetAddressesList">
                      <div class="box-rounded text-left">
                        <img loading="lazy" src="assets/img/fav.svg" width="32" class="i-abs"
                          *ngIf="item.Default==false"
                          (click)="favAdr(item.Idcontact,item.Row,true,item.ReferenceName,item.ReferencePhoneNumber,item.Address,item.Prov,item.City,item.Cap,item.Country,item.StreetNumber,item.Description)">
                        <img loading="lazy" src="assets/img/favorite.svg" width="32" class="i-abs"
                          *ngIf="item.Default==true"
                          (click)="favAdr(item.Idcontact,item.Row,false,item.ReferenceName,item.ReferencePhoneNumber,item.Address,item.Prov,item.City,item.Cap,item.Country,item.StreetNumber,item.Description)">
                        <h4>{{item.ReferenceName}}</h4>
                        <span>{{item.Description}}</span><br>
                        <span>{{item.Address}} {{item.StreetNumber}}</span><br>
                        <span>{{item.City}},{{item.Prov}},{{item.Cap}}</span><br>
                        <span>{{item.Country}}</span><br>
                        <span>{{item.ReferencePhoneNumber}}</span><br>
                        <div class="d-flex flex-wrap">
                          <button class="btn btn-w-border small d-block mt-3"
                            (click)="updateAdress=true;addNewAdress=false;getAdd(item.$id)">{{'Profile.indirizzi.modifica'
                            |
                            translate}}</button>
                          <button class="btn btn-bg small mx-auto d-block mt-3"
                            (click)="deleteI=true">{{'Profile.indirizzi.rimuovi' | translate}}</button>
                        </div>
                      </div>
                      <div class="modal" tabindex="-1" role="dialog" *ngIf="deleteI==true">
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title">{{'Profile.indirizzi.confInd' | translate}}</h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                (click)="deleteI=false">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-bg"
                                (click)="DeleteAddress(item.Idcontact,item.Row)">{{'Profile.indirizzi.rimuovi' |
                                translate}}</button>
                              <button type="button" class="btn btn-w-border" data-dismiss="modal"
                                (click)="deleteI=false">{{'Profile.ordini.annulla' | translate}}</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-4 d-flex justify-content-center w-100">
                      <mat-spinner *ngIf="Delete==true"></mat-spinner>
                    </div>
                  </div>


                  <div class="modal modal-address" *ngIf="updateAdress==true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                      <div class="modal-content" style="padding: 0 0 20px;max-height: 96vh;overflow-y: auto;">
                        <div class="col-lg-12 mt-4">
                          <div class="row">
                            <div class=" col-12">
                              <label for="">{{'Profile.indirizzi.descr' | translate}}</label>
                              <input type="text" name="Descrizione" [(ngModel)]="Descrizione"
                                placeholder="{{'Profile.indirizzi.descplaceholder' | translate}}">
                            </div>
                            <div class=" col-12">
                              <label for="">{{'Pagamento.section1.refer' | translate}}</label>
                              <input type="text" name="nomeReferente" [(ngModel)]="nomeReferente" required>
                            </div>
                            <div class=" col-8">
                              <label for="">{{'Pagamento.section1.via' | translate}}</label>
                              <input type="text" name="Indirizzo" [(ngModel)]="Indirizzo" required>
                            </div>
                            <div class=" col-4">
                              <label for="">{{'Pagamento.section1.civico' | translate}}</label>
                              <input type="text" name="StreetNumber" [(ngModel)]="StreetNumber" required>
                            </div>
                            <div class=" col-lg-6">
                              <label for="">{{'Pagamento.fattura.naz' | translate}}</label>
                              <select [(ngModel)]="Paese" name="Paese" required>
                                <option value="{{item.Country}}" *ngFor="let item of CountryCodes">
                                  {{item.Country}}
                                </option>
                              </select>
                            </div>
                            <div class=" col-6">
                              <label for="">{{'Pagamento.section1.prov' | translate}}</label>
                              <select name="provincia" [(ngModel)]="provincia" required>
                                <option *ngFor="let item of provinces" value="{{item.Acronym}}">
                                  {{item.Acronym}} | {{item.Description}}
                                </option>
                              </select>
                            </div>
                            <div class=" col-6">
                              <label for="">{{'Pagamento.section1.citta' | translate}}</label>
                              <input type="text" name="Citta" [(ngModel)]="Citta" required>
                            </div>
                            <div class=" col-6">
                              <label for="">{{'Pagamento.section1.CAP' | translate}}</label>
                              <input type="text" name="cap" [(ngModel)]="cap" required>
                            </div>
                            <div class=" col-6">
                              <label for="">{{'Pagamento.section1.tel' | translate}}</label><br>
                              <input type="text" matInput hidden>
                              <ngx-intl-tel-input matInput [preferredCountries]="['it']"
                                [enableAutoCountrySelect]="true" [searchCountryFlag]="true" [selectFirstCountry]="true"
                                [separateDialCode]="true" [phoneValidation]="false" [(ngModel)]="Telefono" #phoneNGX
                                [ngClass]="{'required':Telefono==null && updateAdress==true}">
                              </ngx-intl-tel-input>
                            </div>
                            <div class="d-flex justify-content-center w-100 mt-4 px-4">
                              <button class="btn btn-bg mr-2" (click)="addAdress(Profilo.IdGestionale)"
                                *ngIf="addNewAdress==true">{{'Pagamento.section1.add' | translate}}</button>
                              <button class="btn btn-bg mr-2" (click)="UpdateAdress(Profilo.IdGestionale)"
                                *ngIf="addNewAdress==false">{{'Pagamento.section1.add' | translate}}</button>
                              <button class="btn btn-w-border reg ml-2"
                                (click)="addNewAdress=false;updateAdress=false">{{'Pagamento.section1.annM' |
                                translate}}</button>
                            </div>
                            <small class="text-danger mt-2 ml-3" *ngIf="errorAddAdress!=''">{{errorAddAdress}}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="col-lg-4 offset-lg-4 px-3 mt-4 mb-3" *ngIf="addNewAdress==false">
                    <button class="btn btn-bg mx-auto d-block"
                      (click)="updateAdress=true;addNewAdress=true;clearAdr()">{{'Pagamento.section1.telefono' |
                      translate}}</button>
                  </div>

                </div>
              </div>
              <div class="modal" *ngIf="errorcheckAddress==true" style="z-index: 9999999999999999;">
                <div class="modal-dialog">
                  <div class="modal-content" style="padding: 38px 15px;">
                    <div class="d-flex justify-content-between">
                      <div class="d-flex justify-content-between w-100" style="border-bottom: 2px solid #D5A422">
                        <h4 class="aggiunte-title">{{'Profile.carte.cartalert' | translate}}</h4>
                        <button mat-button class="px-0  mr-2 btn-count" (click)="errorcheckAddress=false"><img
                            src="assets/img/close.svg" alt="" width="23"></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="modal fade show" *ngIf="openAddTocarrello==true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body mt-3">
        <h4 class="s-title"><b>Vuoi svuotare il carrello ?</b></h4>
        <img loading="lazy" class="d-block mt-4 mx-auto" width="80" height="80" *ngIf="loaderAddC==true"
          src="assets/img/reload.gif" alt="loader horse">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-w-border" (click)="addToCarrello()">No</button>
        <button type="button" class="btn btn-bg" (click)="addToCarrello_SvuotaCarrello()">Si</button>
      </div>
    </div>
  </div>
</div>
