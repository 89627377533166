import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Product } from '../models/product';
import { ServiceService } from '../services/service.service';
import { catchError } from 'rxjs/operators';
import { MessengerService } from '../services/messenger.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.css']
})
export class WishlistComponent implements OnInit {

  constructor(private router: Router, public service: ServiceService, private messanger: MessengerService,private metaService:Meta) { }

  login;
  Profilo: any = [];
  loading: boolean = false;

  ngOnInit(): void {
    this.metaService.addTag({ name: 'robots', content: 'noindex' });
    this.loading = true;
    this.Profilo = JSON.parse(localStorage.getItem("profil")) == null ? JSON.parse(sessionStorage.getItem("profil")) : JSON.parse(localStorage.getItem("profil"));

    if (JSON.parse(localStorage.getItem("login")) != null) {
      this.login = JSON.parse(localStorage.getItem("login"));
      if (this.login == 'true') {
        this.Login();
      }
    } else {
      this.router.navigate(['/area-riservata'],
        { queryParams: { wishlist: true } });
        return;
    }
  }

  Loginresult:any=[];
  Login() {
    if (this.Profilo.IdContactsCredential==undefined) {
      this.service.login(this.Profilo.Username, this.Profilo.Password).subscribe(
        res => {
          this.Loginresult = res;
          if (this.Loginresult != null) {
            localStorage.setItem("profil", JSON.stringify(this.Loginresult));
            localStorage.setItem("nome", JSON.stringify(this.Loginresult.Name));
            this.Profilo = this.Loginresult;
            this.GetFavourites();
          }
        }
      )
    }else{
      this.GetFavourites();
    }

  }

  favorites: any = [];
  GetFavourites() {
    this.service.GetFavourites(this.Profilo.IdContactsCredential).subscribe(res => {
      this.favorites = res;
      this.loading = false;
    });
    this.service.GetFavouritesCount(this.Profilo.IdContactsCredential);
  }

  removefavoritesresult: any = [];
  removefavorites(id) {
    this.service.ManageFavorites(this.Profilo.IdContactsCredential, id, false).subscribe(res => {
      this.removefavoritesresult = res;
      const index = this.favorites.findIndex(x => x.Id == id);
      if (index > -1) {
        this.favorites.splice(index, 1);
      }
      this.service.GetFavouritesCount(this.Profilo.IdContactsCredential);
      // this.GetFavourites();
      if (this.removefavoritesresult.length != 0) {
        if (this.removefavoritesresult.status == 1) {
          this.msgsuccess = true;
        } else {
          this.Error = true;
        }
        setTimeout(() => {
          this.Error = false;
          this.msgsuccess = false;
        }, 1800);
      }
    })
  }

  Error: boolean = false;
  msgsuccess: boolean = false;

  goToProduct(item) {
    if (item.IdFather == null) {
      this.router.navigateByUrl('/prodotto/' + item.Id);
    } else {
      this.service.childId = item.Code;
      this.router.navigateByUrl('/prodotto/' + item.IdFather);
    }
  }

  product: any = [];
  openAcquisto = '';
  qte = 1;
  sendtocarrello: boolean = false;
  addToCarrello(item) {
    // this.loading = true;
    //   this.service.GetProductsWebDetail(item.Id, true).pipe(
    //     catchError(error => {
    //       return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
    //     })
    //   ).subscribe(
    //     products => {
    this.sendtocarrello = true;
    this.product = item;
    localStorage.setItem('qte', JSON.stringify(this.qte));
    localStorage.setItem('from', 'profile');
    this.messanger.sendProduct(this.product);
    this.qte = 1;
    this.openAcquisto = '';
    this.loading = false;
    setTimeout(() => {
      this.sendtocarrello = false;
    }, 1800);
    //   }
    // );
  }


  resultRemoveAllFavourites: any = [];
  clearLista() {
    const result = confirm('Vuoi svuotare la lista dei preferiti ?');
    if (result) {
      this.service.RemoveAllFavourites(this.Profilo.IdContactsCredential).subscribe(res => {
        this.resultRemoveAllFavourites = res;
        if (this.resultRemoveAllFavourites == 1) {
          this.favorites = [];
        }
      })
    }
  }

}
