<section style="min-height: 75vh">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h4 class="title">Brand</h4>

        <div class="d-flex flex-wrap mt-4">
          <ng-container  *ngFor="let item of Brands">
            <a class="brands" routerLink="/marca/{{item.Description.toLowerCase()}}/{{item.Id}}" *ngIf="!item.Link">
              <img  loading="lazy"  src="{{item.UrlImage}}" alt="{{item.Description}}">
              <b>{{item.Description}}</b>
            </a>
            <a class="brands" [href]="item.Link" target="_blank" *ngIf="item.Link">
              <img  loading="lazy"  src="{{item.UrlImage}}" alt="{{item.Description}}">
              <b>{{item.Description}}</b>
            </a>
          </ng-container>
        </div>

      </div>
    </div>
  </div>
</section>
