import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AziendaRoutingModule } from './azienda-routing.module';
import { AziendaComponent } from './azienda.component';

@NgModule({
  declarations: [
    AziendaComponent
  ],
  imports: [
    TranslateModule,
    CommonModule,
    AziendaRoutingModule,
  ],
})
export class AziendaModule { }
