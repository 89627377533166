import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { AccountComponent } from './account.component';
import { AccountRoutingModule } from './account-routing.module';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    AccountComponent
  ],
  imports: [
    TranslateModule,
    CommonModule,
    AccountRoutingModule,
    FormsModule,
    MatIconModule,
    MatProgressSpinnerModule
  ],
})
export class AccountModule { }
