import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.css']
})
export class CookiePolicyComponent implements OnInit {

  constructor(private renderer: Renderer2, private el: ElementRef) { }

  ngOnInit(): void {
    // Creare uno script element
    const script = this.renderer.createElement('script');

    // Aggiungere l'attributo src con il link esterno
    this.renderer.setAttribute(script, 'src', 'https://consent.cookiebot.com/fe3613f8-4a3b-4025-80a1-b3084586a051/cd.js');

    // Ottenere l'elemento div con id "cookiedec"
    const divElement = this.el.nativeElement.querySelector('#cookiedec');

    // Aggiungere lo script al div
    this.renderer.appendChild(divElement, script);

  }

}
