<section class="bg-section-grey" style="min-height: 75vh">
  <div class="container">
    <div class="row">
      <div class="col-lg-3">
        <ul class="list-group">
          <li class="list-group-item" routerLink="/termini-condizioni">{{ "CondizioniSped.menu.m1" | translate }}</li>
          <li class="list-group-item" routerLink="/modalita-di-pagamento">{{ "CondizioniSped.menu.m2" | translate }}</li>
          <li class="list-group-item" routerLink="/condizioni-di-spedizione">{{ "CondizioniSped.menu.m3" | translate }}</li>
          <li class="list-group-item active" routerLink="/reso-prodotti">{{ "CondizioniSped.menu.m4" | translate }} </li>
        </ul>
      </div>
      <div class="col-lg-9">
        <div class="main-carrello">
          <h1 class="title mb-4">{{ "CondizioniSped.menu.m4" | translate }} </h1>

          <h4 class="blu">
            {{ "Reso.section1.title" | translate }}
            </h4>
          <p>
            {{ "Reso.section1.text" | translate }}

          </p>

        </div>
      </div>
    </div>
  </div>
</section>
