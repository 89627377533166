import { Component, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../services/service.service';
import { MessengerService } from '../services/messenger.service';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { catchError } from 'rxjs/operators';


@Component({
  selector: 'app-products-details',
  templateUrl: './products-details.component.html',
  styleUrls: ['./products-details.component.css']
})
export class ProductsDetailsComponent implements OnInit {

  constructor(
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private _document,
    private title: Title,
    private service: ServiceService,
    private messanger: MessengerService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    private metaService: Meta
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  showcarousel: boolean = false;
  padre: any = [];
  resquest;
  product_selected: any = [];
  product_similar: any = [];
  IdProduct;
  IdCat;
  ListChildren: any = [];
  filtri: any = [];
  selectedtab = '';
  filtriBkp: any = [];
  listLogisticChildren: any = [];
  ThicknessAreAllEmpty:boolean=false;
  loadProductSimili: boolean = false;
  fakelist: any[] = new Array(10);
  disponible: boolean = true;
  modalform: boolean = false;

  nome: string = '';
  cognome: string = '';
  tel: string = '';
  email: string = '';
  msg: string = '';

  ngOnInit(): void {
    this.initializeComponent();
  }

  initializeComponent() {
    if (isPlatformBrowser(this.platformId)) {
      this.showcarousel = true;
    }
    this.route.paramMap.subscribe(params => {
      this.IdCat = params.get('IdCat');
      this.IdProduct = params.get('IdProduct');
      // if (this.IdCat !== null && this.IdProduct !== null) {
      //   const newUrl = `/prodotto/${this.IdProduct}`;
      //   this.router.navigateByUrl(newUrl);
      // }
      this.loadProductSimili = true;
      this.loadProductDetails();
    });
  }

  loadProductDetails() {
    this.service.GetProductsWebDetail(this.IdProduct, false).pipe(
      catchError(error => {
        this.disponible = false;
        this.getProdoctEvidenza();
        this.metaService.updateTag({ name: 'robots', content: 'noindex' });
        return error
        // return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(products => {
      this.product_selected = products;
      if (this.product_selected?.listLogisticChildren.length!=0) {
        this.listLogisticChildren=this.product_selected?.listLogisticChildren;
        this.ThicknessAreAllEmpty=this.listLogisticChildren.every(element => element.Thickness === null)
      }
      this.padre = products;
      this.IdCat = this.product_selected.FirstGroupID;
      this.updatePageSEO();
      this.myImgSrc = this.product_selected?.urlImages[0];
      this.ListChildren = this.product_selected.listChildren;
      this.filtri = this.generateFilters();
      this.filtriBkp = [...this.filtri];
      this.selectedtab = this.filtri[0]?.Description;
      this.handleChildSelectionFromSearch();
      this.loadSimilarProducts();
    });
  }

  updatePageSEO() {
    const fPath = this._document.location.href.replace(/\http:/g, 'https:');
    const fullPath = fPath.replace(/^https?:\/\/(www\.)?/i, 'https://');

    const title = this.product_selected.Title.toLowerCase().trim().replace(/\&nbsp;/g, '');
    const capitalizedTitle = title.charAt(0).toUpperCase() + title.slice(1);
    this.title.setTitle(capitalizedTitle);
    this.metaService.addTag({ name: 'title', content: capitalizedTitle });
    this.metaService.addTag({ property: 'og:title', content: capitalizedTitle });
    if (this.product_selected.Description != null) {
      var html = this.product_selected.Description.trim().replace(/\r\n|\n|\r|&nbsp;/g, " ").toLowerCase().substring(0, 190);
      var desc = document.createElement("div");
      desc.innerHTML = html;
      this.metaService.addTag({ name: 'description', content: desc.textContent || desc.innerText || "" });
      this.metaService.addTag({ property: 'og:description', content: desc.textContent || desc.innerText || "" });
    }
    this.metaService.addTag({ name: 'image', content: this.product_selected?.urlImages[0] });
    this.metaService.addTag({ property: 'og:image', content: this.product_selected?.urlImages[0] });
    this.metaService.addTag({ property: 'og:url', content: fullPath });
  }

  generateFilters() {
    let filters = [];
    for (const item of this.ListChildren) {
      if (item.listExtraDatas != null) {
        for (const extraData of item.listExtraDatas) {
          const { Description, Value } = extraData;
          let filter = filters.find(entry => entry.Description === Description);
          if (!filter) {
            filter = { Description: Description, values: [] };
            filters.push(filter);
          }
          if (!filter.values.includes(Value.trim())) {
            filter.values.push(Value.trim());
          }
        }
      }
    }
    return filters;
  }

  handleChildSelectionFromSearch() {
    if (this.service.childId !== '') {
      for (let m = 0; m < this.ListChildren.length; m++) {
        if (this.ListChildren[m].Code.toLowerCase() === this.service.childId.toLowerCase()) {
          const selectedChild = this.ListChildren[m];
          this.product_selected = { ...selectedChild };
          this.product_selected.urlImages = this.padre.urlImages;
          this.product_selected.Idchild = this.ListChildren[m].Id;
          this.product_selected.Id = this.padre.Id;
          this.openPriceChild = true;
          for (let i = 0; i < this.ListChildren[m].listExtraDatas.length; i++) {
            this.selectFiltri(this.ListChildren[m].listExtraDatas[i].Description, this.ListChildren[m].listExtraDatas[i].Value.trim());
          }
          break;
        }
      }
    }
  }

  loadSimilarProducts() {
    this.resquest = this.service.getProducts(this.IdCat, 0, 15, '', '', this.product_selected.GroupID, false, false, 0).pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(products => {
      this.product_similar = products;
      this.product_similar = this.product_similar.filter(x => x.Id != this.IdProduct);
      this.loadProductSimili = false;
    });
  }

  getOrderedfiltri(description): any[] {
    const itemIndex = this.filtri.findIndex(item => item.Description === description);
    if (itemIndex !== -1) {
      const itemsStartingWithMisura = this.filtri.slice(itemIndex);
      const itemsBeforeMisura = this.filtri.slice(0, itemIndex);
      return itemsStartingWithMisura.concat(itemsBeforeMisura);
    }
    return this.filtri;
  }

  changeTabfiltri(desc) {
    this.selectedtab = desc;
    this.filtred = [];
    this.clearBM();
  }

  getSelectedfromsearch(description) {
    const index = this.filtred.findIndex(item => item.Description === description);
    if (index !== -1) { // Check if item with the given description is found
      return this.filtred[index].Value?.trim(); // Return the corresponding value
    } else {
      return null; // or whatever default value you want to return when the description is not found
    }
  }

  Evidenza: any = [];
  getProdoctEvidenza() {
    this.service.getProductsEvidenza().pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.Evidenza = res;
      }
    )
  }

  matchedExtraDatas: any = []; // Array to store matched child.listExtraDatas
  filtred: any = [];
  selectFiltri(desc, value) {
    if (value == 'null') {
      const itemIndex = this.filtred.findIndex(item => item.Description === desc);
      if (itemIndex !== -1) {
        this.filtred.splice(itemIndex, 1);
        if (itemIndex < this.filtred.length) {
          this.filtred.splice(itemIndex, this.filtred.length);
        }
      }
    } else {
      const itemIndex = this.filtred.findIndex(item => item.Description === desc);
      if (itemIndex !== -1) {
        this.filtred[itemIndex].Value = value;
        if (itemIndex < this.filtred.length - 1) {
          this.filtred.splice(itemIndex + 1, this.filtred.length);
        }
      } else {
        this.filtred.push({ Description: desc, Value: value });
      }
    }
    if (this.filtred.length == 0) {
      this.clearBM();
    } else {
      for (let i = 0; i < this.filtri.length; i++) {
        if (!this.filtred.some(filtredEntry => filtredEntry.Description === this.filtri[i].Description)) {
          this.filtri[i].values = []; // Clear values array for filters except filterIndex
        }
      }
      this.matchedExtraDatas = [];
      for (const child of this.ListChildren) {
        let matchedDataForChild = []; // Array to store matched child.listExtraDatas for current child
        let itemMatched = true;
        for (const f of this.filtred) {
          const matchingData = child.listExtraDatas.find(data => data.Description === f.Description && data.Value.trim() === f.Value.trim())
          if (!matchingData) {
            itemMatched = false;
          }
        }
        if (itemMatched) {
          matchedDataForChild.push(child.listExtraDatas); // Store matched child.listExtraDatas
        }
        if (matchedDataForChild.length != 0) {
          this.matchedExtraDatas.push(...matchedDataForChild); // Store matchedDataForChild for current child
        }
      }
      if (this.matchedExtraDatas.length > 0) {
        for (const filter of this.filtri) {
          const addedValues: Set<string> = new Set(); // Set to keep track of added values for the current filter
          for (const matchedData of this.matchedExtraDatas) {
            const matchingData = matchedData.find(data => data.Description === filter.Description);
            if (matchingData) {
              const trimmedValue = matchingData.Value.trim();
              if (!filter.values.includes(trimmedValue) && !addedValues.has(trimmedValue)) {
                filter.values.push(trimmedValue);
                addedValues.add(trimmedValue); // Add the value to the set of added values
              }
            }
          }
        }
      }

      if (this.filtri.length == this.filtred.length) {
        let found = false;
        for (let m = 0; m < this.ListChildren.length; m++) {
          for (let x = 0; x < this.ListChildren[m].listExtraDatas.length; x++) {
            if (this.arraysEqual(this.ListChildren[m].listExtraDatas, this.filtred)) {
              const selectedChild = this.ListChildren[m];
              this.product_selected = { ...selectedChild };
              this.product_selected.urlImages = this.padre.urlImages;
              this.product_selected.Idchild = this.ListChildren[m].Id; // Moved inside the condition
              this.product_selected.Id = this.padre.Id;
              this.openPriceChild = true;
              found = true;
              break;
            }
          }
          if (found) { // Se abbiamo trovato il prodotto, usciamo anche dal ciclo esterno
            break;
          }
        }
      } else {
        this.product_selected = this.padre;
        this.openPriceChild = false;
      }
    }
  }

  arraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }
    // Sort both arrays based on Description
    const sortedArr1 = arr1.slice().sort((a, b) => a.Description.localeCompare(b.Description));
    const sortedArr2 = arr2.slice().sort((a, b) => a.Description.localeCompare(b.Description));
    // Check each element for equality
    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i].Description !== sortedArr2[i].Description || sortedArr1[i].Value.trim() !== sortedArr2[i].Value.trim()) {
        return false;
      }
    }
    return true;
  }

  ifSelected(item) {
    return this.filtred.find(e => e.Description === item)
  }

  clearBM() {
    this.filtri = this.filtriBkp.map(filter => ({ ...filter, values: [...filter.values] }));
    this.product_selected = this.padre;
    this.openPriceChild = false;
  }

  openPriceChild: boolean = false;

  ngOnDestroy() {
    this.resquest?.unsubscribe();
  }

  qte: number = 1;

  addToCart(item) {
    if (this.qte >= 1) {
      localStorage.setItem('qte', JSON.stringify(this.qte));
      localStorage.setItem('from', 'prod-det');
      localStorage.setItem('prodotto_carrello', JSON.stringify(item));
      localStorage.setItem('prodotto_carrello_qte', JSON.stringify(this.qte));
      this.messanger.sendProduct(item);
      this.qte = 1;

      //script purchase GA4
      const s = this.renderer2.createElement('script');
      s.type = 'text/javascript';
      s.src = 'assets/GA4_add_to_cart.js';
      s.text = ``;
      this.renderer2.appendChild(this._document.body, s);
    }
  }

  Profilo: any = [];
  resultAddToWishlist: any = [];
  ErrorFav: boolean = false;
  msgsuccessFav: boolean = false;
  errorLogin: boolean = false;

  addTowishlist(id, type) {
    this.ErrorFav = false;
    this.msgsuccessFav = false;
    this.Profilo = JSON.parse(localStorage.getItem("profil")) == null ? JSON.parse(sessionStorage.getItem("profil")) : JSON.parse(localStorage.getItem("profil"));
    if (this.Profilo != null) {
      this.service.ManageFavorites(this.Profilo.IdContactsCredential, id, type).subscribe(res => {
        this.resultAddToWishlist = res;
        this.service.GetFavouritesCount(this.Profilo.IdContactsCredential);
        if (this.resultAddToWishlist.length != 0) {
          if (this.resultAddToWishlist.status == 1) {
            this.msgsuccessFav = true;
            type == true ? this.product_selected.IsMyFavourite = true : this.product_selected.IsMyFavourite = false
          } else {
            this.ErrorFav = true;
          }
          setTimeout(() => {
            this.ErrorFav = false;
            this.msgsuccessFav = false;
          }, 2000);
        }
      })
    } else {
      this.errorLogin = true;
      setTimeout(() => {
        this.errorLogin = false;
      }, 2000);
    }
  }

  indietro() {
    window.history.back()
  }

  zoomin: boolean = false;
  imageZoom(imgID, resultID) {
    var img, lens, result, cx, cy;
    img = document.getElementById(imgID);
    result = document.getElementById(resultID);
    lens = document.createElement("DIV");
    lens.setAttribute("class", "img-zoom-lens");
    img.parentElement.insertBefore(lens, img);
    cx = result.offsetWidth / lens.offsetWidth * 0.22;
    cy = result.offsetHeight / lens.offsetHeight * 0.22;
    result.style.backgroundColor = '#fff';
    result.style.backgroundImage = "url('" + img.src + "')";
    result.style.backgroundRepeat = "no-repeat"
    result.style.backgroundSize = (img.width * cx) + "px " + (img.height * cy) + "px";
    lens.addEventListener("mousemove", moveLens, { passive: true });
    img.addEventListener("mousemove", moveLens, { passive: true });
    lens.addEventListener("touchmove", moveLens, { passive: true });
    img.addEventListener("touchmove", moveLens, { passive: true });
    function moveLens(e) {
      var pos, x, y;
      pos = getCursorPos(e);
      x = pos.x - (lens.offsetWidth) - 5;
      y = pos.y - (lens.offsetHeight) - 5;
      if (x > img.width - lens.offsetWidth) { x = img.width - lens.offsetWidth; }
      if (x < 0) { x = 0; }
      if (y > img.height - lens.offsetHeight) { y = img.height - lens.offsetHeight; }
      if (y < 0) { y = 0; }
      lens.style.left = x + 25 + "px";
      lens.style.top = y + 12 + "px";
      result.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
    }
    function getCursorPos(e) {
      var a, x = 0, y = 0;
      e = e || window.event;
      a = img.getBoundingClientRect();
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return { x: x, y: y };
    }
  }


  myImgSrc = '';

  url = window.location.href;

  social(a) {
    let pinterest = "http://pinterest.com/pin/create/button/" +
      "?url=" + this.url +
      "&media=" + this.product_selected?.urlImages[0] +
      "&description=" + this.product_selected.Description;
    let facebook = 'https://www.facebook.com/sharer/sharer.php?u=' + this.url;
    let twitter = 'https://twitter.com/share?text=' + this.product_selected.Title + '&url=' + this.url;
    let whatsapp = 'https://wa.me/?text=' + this.url;
    let linkedin = 'https://www.linkedin.com/sharing/share-offsite/?url=' + this.url;
    let b = '';
    if (a === 'pinterest') {
      b = pinterest;
    }
    if (a === 'twitter') {
      b = twitter;
    }
    if (a === 'facebook') {
      b = facebook;
    }
    if (a === 'linkedin') {
      b = linkedin;
    }
    if (a === 'whatsapp') {
      b = whatsapp;
    }
    let params = `width=500,height=400,left=100,top=100`;
    window.open(b, a, params)
  }

  check(misura, binda) {
    for (let m = 0; m < this.ListChildren.length; m++) {
      for (let x = 0; x < this.ListChildren[m].listExtraDatas.length; x++) {
        if (this.ListChildren[m].listExtraDatas[x].Value.trim() == misura) {
          let array = this.ListChildren[m].listExtraDatas.filter(xx => xx.Value.trim() == binda);
          if (array.length != 0) {
            return true;
          }
        }
      }
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  resultEmail:any=[];
  SendMsg() {
    if (this.nome != '' && this.cognome != '' && this.tel != '' && this.email != '' && this.validateEmail(this.email) && this.msg != '') {
          this.service.SendEmail(this.email,this.nome,this.cognome,this.tel,this.msg,this.product_selected.Title).subscribe(x=>{
            this.resultEmail=x;
            setTimeout(() => {
              this.modalform=false;
              this.resultEmail=[];
              this.nome='';
              this.cognome='';
              this.tel='';
              this.email='';
              this.msg='';
            }, 2000);
          })
    }
  }

}
