import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../services/service.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  constructor(public service: ServiceService) { }

  ngOnInit(): void {
    this.service.GroupsList.subscribe(data => this.Groups = data);
  }

  Groups:any=[];


}
