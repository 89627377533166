import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Product } from '../models/product';
import { MessengerService } from '../services/messenger.service';
import { Meta } from '@angular/platform-browser';
import { ServiceService } from '../services/service.service';

@Component({
  selector: 'app-carrello',
  templateUrl: './carrello.component.html',
  styleUrls: ['./carrello.component.css']
})
export class CarrelloComponent implements OnInit {

  subscription: Subscription;
  login;

  constructor(private messanger: MessengerService, private router: Router,private metaService: Meta,private service: ServiceService) {
  }

  ngOnInit(): void {
    this.metaService.addTag({ name: 'robots', content: 'noindex' });
    if (JSON.parse(localStorage.getItem('carrello')) != null) {
      console.log(this.product);
      this.product = JSON.parse(localStorage.getItem('carrello'));
      this.CalculeTotalCarrello();
    }else{
      this.product = [];
    }
    if (JSON.parse(localStorage.getItem("login")) != null) {
      this.login = JSON.parse(localStorage.getItem("login"));
    }
  }

  qte: number = 1;

  product: Product[];

  TotalCarrello = 0;
  TotalSconte = 0;
  CalculeTotalCarrello() {
    this.TotalCarrello = 0;
    this.TotalSconte=0;
    this.product.forEach(item => {
      this.TotalCarrello += (item.qte * item.Price)
      this.TotalSconte+=(item.Discount!=null?(item.OriginalPrice-item.Price)*item.qte:0)
    })
  }

  removeProduct(item) {
    const index: number = this.product.indexOf(item);
    localStorage.setItem('deleteItem', 'true');
    this.messanger.sendProduct(this.product[index]);
    this.product = JSON.parse(localStorage.getItem('carrello'));
    this.CalculeTotalCarrello();
  }


  changeQte(item, operation) {
    const index: number = this.product.indexOf(item);
    localStorage.setItem('qte', JSON.stringify(operation));
    localStorage.setItem('from', 'carrello');
    this.messanger.sendProduct(this.product[index]);
    this.product = JSON.parse(localStorage.getItem('carrello'));
    this.CalculeTotalCarrello();
  }


  concludiOrdine() {
    if (this.login == 'true') {
      this.router.navigateByUrl('/pagamento');
    } else {
      this.router.navigate(['/area-riservata'],
        { queryParams: { paga: true } });
    }
  }

  clearCarrello() {
    const result = confirm('Vuoi svuotare il carrello ?');
    if (result) {
      localStorage.removeItem('carrello');
      this.product = [];
      this.messanger.clearProduct();
    }
  }

  goToProduct(item) {
    if (item.IdFather==null) {
      this.router.navigateByUrl('/prodotto/' + item.Id);
    }else {
      this.service.childId=item.Code;
      this.router.navigateByUrl('/prodotto/' + item.IdFather);
    }
  }


}
