import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../services/service.service';

import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-category-details',
  templateUrl: './category-details.component.html',
  styleUrls: ['./category-details.component.css']
})
export class CategoryDetailsComponent implements OnInit {

  constructor(
    public service: ServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private titleSeo: Title,
    @Inject(PLATFORM_ID) private platformId: Object,
    private metaService: Meta, @Inject(DOCUMENT) private document: Document) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }


  ngOnInit(): void {
    this.groupsrequest = this.service.GroupsList.subscribe(
      (group1: any[]) => {
        this.handleGroupList(group1);
      },
      (error: any) => {
        console.error('Error fetching group list:', error);
        // Handle error (e.g., show error message)
      }
    );

    if (isPlatformBrowser(this.platformId)) {
      this.showcarousel = true;
    }
  }

  showcarousel: boolean = false;
  disponible: boolean = true;

  handleGroupList(group1: any[]): void {
    this.IdCat = parseInt(this.route.snapshot.paramMap.get('IdCat')!, 10);
    this.IdSousCat = parseInt(this.route.snapshot.paramMap.get('IdSCat')!, 0) || this.IdCat;
    this.group1 = group1;
    if (this.group1.length != 0) {
      this.disponible = false;
      for (const group of this.group1) {
        if (group.Id == this.IdSousCat) {
          this.categoryLevel1 = this.group1.filter(res => res.Id == this.IdSousCat);
          this.IdCat = this.IdSousCat;
          this.IdSousCat = 0;
          this.disponible = true;
        } else {
          for (const subgroup of group.ListGroups) {
            if (subgroup.Id == this.IdSousCat) {
              this.categoryLevel1 = this.group1.filter(res => res.Id == group.Id);
              this.IdCat = group.Id;
              this.IdSousCat = subgroup.Id;
              this.disponible = true;
            }
          }
        }
      }
    }


    if (this.disponible == false) {
      this.getProdoctEvidenza();
      this.metaService.updateTag({ name: 'robots', content: 'noindex' });
    }

    if (this.categoryLevel1.length !== 0) {
      this.updateCategoryDetails();
    }
  }

  private updateCategoryDetails(): void {

    // if (this.IdCat !== null && this.IdSousCat !== null) {
    //   const newUrl = `/categoria/${this.IdCat}`;
    //   this.router.navigateByUrl(newUrl);
    // }

    for (let i = 0; i < this.categoryLevel1[0]?.ListImages.length; i++) {
      if (this.categoryLevel1[0]?.ListImages[i].IsMainPicture) {
        this.mainPicture = this.categoryLevel1[0]?.ListImages[i].LinkImage;
      } else {
        this.cattPicture = this.categoryLevel1[0]?.ListImages[i].LinkImage;
      }
    }

    this.sousCatName = this.categoryLevel1[0].ListGroups
      .filter(res => res.Id == this.IdSousCat);
    this.title = this.sousCatName.length !== 0 ? this.sousCatName[0].Description : this.categoryLevel1[0]?.Description;
    this.cat1 = this.sousCatName.length !== 0 ? this.sousCatName[0].Description?.trim() + ' - ' + this.categoryLevel1[0]?.Description : 'Blacksmith Italia - ' + this.categoryLevel1[0]?.Description;
    this.desc = this.sousCatName.length !== 0 ?
      this.truncateDescription(this.sousCatName[0].DescriptionWeb).length < 50 ? 'Cerchi attrezzatura professionale per maniscalco? Blacksmith Italia offre ' + this.sousCatName[0].Description?.trim() + ' al miglior prezzo. Acquista online!' : this.sousCatName[0].DescriptionWeb
      : this.categoryLevel1[0]?.DescriptionWeb;
    this.updateSEO();
  }

  private updateSEO(): void {
    const fPath = this.document.location.href.replace(/\http:/g, 'https:');
    const fullPath = fPath.replace(/^https?:\/\/(www\.)?/i, 'https://');
    const truncatedDesc = this.truncateDescription(this.desc);

    const title = this.cat1.toLowerCase().trim().replace(/\&nbsp;/g, '');
    const capitalizedTitle = title.charAt(0).toUpperCase() + title.slice(1);

    this.titleSeo.setTitle(capitalizedTitle);
    this.metaService.addTag({ name: 'title', content: capitalizedTitle });
    this.metaService.addTag({ property: 'og:title', content: capitalizedTitle });
    this.metaService.addTag({ name: 'description', content: truncatedDesc });
    this.metaService.addTag({ property: 'og:description', content: truncatedDesc });
    this.metaService.addTag({ name: 'image', content: this.cattPicture });
    this.metaService.addTag({ property: 'og:image', content: this.cattPicture });
    this.metaService.addTag({ property: 'og:url', content: fullPath });

    this.GetTotalProducts();
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.GetBrands();
      }, 300);
    }

  }

  private truncateDescription(description: string): string {
    const html = description?.trim().replace(/\r\n|\n|\r|&nbsp;/g, ' ').toLowerCase().substring(0, 190);
    const descElement = document.createElement('div');
    descElement.innerHTML = html;
    return descElement.textContent || descElement.innerText || '';
  }

  categoryLevel2: any = [];
  categoryLevel1: any = [];
  mainPicture = '';
  cattPicture = '';

  Evidenza: any = [];
  getProdoctEvidenza() {
    this.service.getProductsEvidenza().pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.Evidenza = res;
      }
    )
  }

  products: any = [];
  group1: any = [];

  IdCat;

  cat1 = '';
  title = '';
  desc = '';
  IdSousCat = null;
  Groups: any = [];
  sousCatName: any = [];

  loader: boolean = true;
  loaderNext: boolean = false;

  fakelist: any[] = new Array(10);

  groupsrequest;

  orderPriceAsc: boolean = false;
  orderPriceDesc: boolean = false;
  priceFilter = '';

  filtri() {
    if (this.priceFilter == 'asc') {
      this.orderPriceAsc = true; this.orderPriceDesc = false; this.GetProducts();
    } else if (this.priceFilter == 'desc') {
      this.orderPriceAsc = false; this.orderPriceDesc = true; this.GetProducts();
    } else {
      this.orderPriceAsc = false; this.orderPriceDesc = false; this.GetProducts();
    }
  }

  ProductRequest;
  GetProducts() {
    this.loader = true;
    this.products = [];
    this.ProductRequest = this.service.getProducts(this.IdCat, 0, this.pageSize, this.searchValue, this.brandSelected, null, this.orderPriceDesc, this.orderPriceAsc, this.IdSousCat).pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      products => {
        this.products = products;
        this.loader = false;
      });
  }

  totProductRequest;
  GetTotalProducts() {
    // if (isPlatformBrowser(this.platformId)) {
    this.totProductRequest = this.service.GetTotalProductsWeb(this.IdCat, -1, -1, this.searchValue, this.brandSelected, this.orderPriceDesc, this.orderPriceAsc, this.IdSousCat).pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        var result = res;
        this.totalProducts = +result;
        if (this.totalProducts < 15) {
          this.fakelist = new Array(this.totalProducts);
        }
        if (this.totalProducts > this.pageSize) {
          this.pageLimit = Math.ceil(this.totalProducts / this.pageSize);
        } else {
          this.pageLimit = 1;
          this.pageIndex = 1;
        }
        this.changeSizePage(this.pageSize);
      });
    // }
  }

  ngOnDestroy() {
    this.totProductRequest?.unsubscribe();
    this.ProductRequest?.unsubscribe();
    this.groupsrequest?.unsubscribe();
  }


  pageIndex: number = 1;
  pageLimit: number = 0;
  pageSize: number = 15;
  Offset: number = 0;
  totalProducts: number = 0;
  productleft: number = 0;

  nextrequest: any = [];

  newproduct: any = [];
  nextPage() {
    this.newproduct = [];
    if (this.nextrequest.length != 0) {
      this.nextrequest?.unsubscribe();
      this.totProductRequest?.unsubscribe();
    }
    if (this.pageIndex < this.pageLimit) {
      this.pageIndex += 1;
      this.Offset = this.Offset + this.pageSize;
      this.productleft = this.productleft - this.pageSize;
      this.loaderNext = true;
      this.nextrequest = this.service.getProducts(this.IdCat, this.Offset, this.pageSize, this.searchValue, this.brandSelected, null, this.orderPriceDesc, this.orderPriceAsc, this.IdSousCat).pipe(
        catchError(error => {
          return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
        })
      ).subscribe(
        products => {
          this.newproduct = products;
          for (let i = 0; i < this.newproduct.length; i++) {
            this.products.push(this.newproduct[i]);
          }
          this.loaderNext = false;
        });
      // this.GetTotalProducts();
      // if (isPlatformBrowser(this.platformId)) {
      //   window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      // }
    }
  }

  changeSizePage(i) {
    this.productleft = this.totalProducts - this.pageSize;
    this.pageSize = parseInt(i);
    this.pageIndex = 1;
    if ((this.totalProducts / this.pageSize) % 1 != 0) {
      this.pageLimit = Math.ceil(this.totalProducts / this.pageSize);
    }
    this.GetProducts();
    if (isPlatformBrowser(this.platformId)) {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
  }


  Brands: any = [];
  token: any = [];
  brandSelected = 'null';
  GetBrands() {
    if (this.service.tokenValue == undefined) {
      setTimeout(() => {
        this.gtBrands();
      }, 300);
    } else {
      this.gtBrands();
    }
  }

  gtBrands() {
    this.service.GetBrands(this.IdSousCat == 0 ? this.IdCat : this.IdSousCat).pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.Brands = res;
      })
  }

  toSousCat = null;

  goTo(page) {
    if (page != null) {
      this.router.navigate([page]);
    }
  }


  searchActivated: boolean = false;
  searchValue = '';

}
