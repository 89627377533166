import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TerminiRoutingModule } from './termini-routing.module';
import { TerminiCondizioniComponent } from './termini-condizioni.component';

@NgModule({
  declarations: [
    TerminiCondizioniComponent
  ],
  imports: [
    TranslateModule,
    CommonModule,
    TerminiRoutingModule,
  ],
})
export class TerminiModule { }
