<section class="bg-section-grey" style="min-height: 75vh">
  <div class="container">
    <div class="row">
      <div class="col-lg-3">
        <ul class="list-group">
          <li class="list-group-item" routerLink="/termini-condizioni">{{'Footer.section1.m5' | translate}}</li>
          <li class="list-group-item active" routerLink="/modalita-di-pagamento">{{'Footer.section1.m6' | translate}}</li>
          <li class="list-group-item" routerLink="/condizioni-di-spedizione">{{'Footer.section1.m7' | translate}}</li>
          <li class="list-group-item" routerLink="/reso-prodotti">{{'Footer.section1.m8' | translate}}</li>
        </ul>
      </div>
      <div class="col-lg-9">
        <div class="main-carrello">
          <h1 class="title mb-4">{{'ModPag.section1.title' | translate}}</h1>
          <h4 class="blu"> {{'ModPag.section1.h41' | translate}}</h4>
          <h4 class="blu"> {{'ModPag.section1.h42' | translate}}</h4>
          <ul class="arrow">
            <li ><p [innerHTML]="'ModPag.section1.text' | translate"></p><br>
              <br>

              <table border="0" cellpadding="10" cellspacing="0" align="center">
                <tbody>
                  <tr>
                    <td align="center"></td>
                  </tr>
                  <tr>
                    <td align="center"><a href="https://www.paypal.com/it/webapps/mpp/paypal-popup"
                        title="Come funziona PayPal"
                        onclick="javascript:window.open('https://www.paypal.com/it/webapps/mpp/paypal-popup','WIPaypal','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=500'); return false;"><img
                          src="https://www.paypalobjects.com/webstatic/mktg/logo-center/logo_paypal_sicuro.png"
                          border="0" alt="Sicuro con PayPal"></a>
                    </td>
                  </tr>
                </tbody>
              </table><br>
            </li>
            <li [innerHTML]="'ModPag.section1.text2' | translate">
            </li>
            <li [innerHTML]="'ModPag.section1.text3' | translate">
            </li>
            <!-- <li [innerHTML]="'ModPag.section1.text4' | translate"></li> -->
          </ul>
          <br>
          <h4 class="blu"> {{'ModPag.section1.h43' | translate}}</h4>
          <p [innerHTML]="'ModPag.section1.text5' | translate">
          </p>
          <h4 class="blu"> {{'ModPag.section1.h44' | translate}}</h4>
          <p>{{'ModPag.section1.text6' | translate}}
           </p>
        </div>
      </div>
    </div>
  </div>
</section>
