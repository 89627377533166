import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../services/service.service';
import { Product } from '../models/product';
import { Meta } from '@angular/platform-browser';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  constructor(private service: ServiceService, private router: Router, public ActivateRouter: ActivatedRoute,private metaService: Meta) {
    this.paga = this.ActivateRouter.snapshot.queryParamMap.get("paga");
    this.wishlist = this.ActivateRouter.snapshot.queryParamMap.get("wishlist");
  }

  Profilo: any = [];
  IsTemporary: boolean = false;

  paga;
  wishlist;
  tokenValue;
  ngOnInit(): void {
    this.metaService.addTag({ name: 'robots', content: 'noindex' });
    this.Profilo = JSON.parse(localStorage.getItem("profil"))==null?JSON.parse(sessionStorage.getItem("profil")):JSON.parse(localStorage.getItem("profil"));

    if (JSON.parse(localStorage.getItem("login")) == 'true' && this.Profilo?.IsTemporary == false) {
      this.router.navigate(['/profile']);
    } else if (this.Profilo?.IsTemporary == true) {
      this.router.navigate(['/area-riservata']);
      this.IsTemporary = true;
      console.log(this.IsTemporary);
    }

    this.carrello = JSON.parse(localStorage.getItem('carrello'));
  }


  toRegister() {
    if (this.paga == 'true') {
      this.router.navigate(['/register'],
        { queryParams: { paga: true } });
    } else {
      this.router.navigateByUrl('/register');
    }
  }

  oggi = new Date;


  username = '';
  password = '';
  p: boolean = false;
  loginError: boolean = false;
  logindisabled: boolean = false;

  carrello: Product[];
  carrelloitem: any = [];

  Loginresult: any = [];
  Login() {
    this.logindisabled = false;
    this.loginError = false;
    this.service.login(this.username, this.password).pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.Loginresult = res;
        if (this.Loginresult != null) {
          this.Profilo = JSON.parse(localStorage.getItem("profil"));
          setTimeout(() => {
            this.p = false;
            this.loginError = false;
            this.service.Profilo = this.Loginresult;
            if (this.Loginresult.IsTemporary == true) {
              location.reload();
              sessionStorage.setItem("profil", JSON.stringify(this.Loginresult));
            } else if (this.Loginresult.Disable != true) {

              localStorage.setItem("login", JSON.stringify('true'));
              localStorage.setItem("profil", JSON.stringify(this.Loginresult));
              this.service.GetAddresses(this.Loginresult.IdGestionale).pipe(
                catchError(error => {
                  return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
                })
              ).subscribe(
                res => {
                  this.service.updateAddresses(res)
                }
              )
              if(this.carrello!=undefined && this.carrello?.length!=0){
                for (let i = 0; i < this.carrello?.length; i++) {
                  this.carrelloitem=[];
                  this.service.GetProductsWebDetail(this.carrello[i].Idchild==undefined?this.carrello[i].Id:this.carrello[i].Idchild, this.carrello[i].Idchild==undefined?false:true).pipe(
                    catchError(error => {
                      return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
                    })
                  ).subscribe(
                    products => {
                      this.carrelloitem=products;
                      this.carrello[i].Price = this.carrelloitem.Price;
                      this.carrello[i].VatCode = this.carrelloitem.VatCode;
                      this.carrello[i].Price = this.carrelloitem.Price;
                      this.carrello[i].Discount = this.carrelloitem.Discount;
                      this.carrello[i].OriginalPrice = this.carrelloitem.OriginalPrice;
                      localStorage.setItem('carrello', JSON.stringify(this.carrello));
                      if(i==this.carrello.length-1){
                        if (this.paga == 'true') {
                          this.router.navigate(['pagamento']).then(() => {
                            window.location.reload();
                          });
                        } else if(this.wishlist=='true'){
                          this.router.navigate(['wishlist']).then(() => {
                            window.location.reload();
                          });
                        }else {
                          this.router.navigate(['profile']) .then(() => {
                            window.location.reload();
                          });
                        }
                      }
                    }
                  )
                }
              }else{
                if (this.paga == 'true') {
                  this.router.navigate(['pagamento']);
                }else if(this.wishlist=='true'){
                  this.router.navigate(['wishlist']).then(() => {
                    window.location.reload();
                  });
                } else {
                  this.router.navigate(['profile']);
                }
              }
            } else {
              this.p = false;
              this.logindisabled = true;
            }
          }, 1200)
        } else {
          setTimeout(() => {
            this.p = false;
            this.loginError = true;
          }, 1000)
        }
      }
    )
  }


  emailR;
  recPass: boolean = false;
  k: boolean = false;
  recPassMsg: boolean = false;
  msg: boolean = false;
  recePassResult: any = [];
  errorRecePAss: any = [];
  recePass() {
    this.recPassMsg = true;
    this.service.RecoverPassword(this.emailR).pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.recePassResult = res;

        setTimeout(() => {
          location.reload();
          this.k = false;
          this.errorRecePAss = [];
          this.loginError = false;
          this.recPassMsg = false;
        }, 5000)

      },
      error => {
        this.errorRecePAss = error;
        this.k = false;
        this.recPassMsg = false;
        this.loginError = false;
      }
    )
  }



  errorPass = '';
  Pass = '';
  RipetiPass = '';

  checkPass() {
    // String with some numbers
    const str = this.Pass;
    // Regular expression
    const regex = /\d/;
    // Check if string contians numbers
    const doesItHaveNumber = regex.test(str);
    function hasLowerCase(str) {
      return (/[A-Z]/.test(str));
    }

    if (str.length < 6) {
      this.errorPass = 'La password deve avere almeno 6 caratteri';
    } else {
      if (doesItHaveNumber == false) {
        this.errorPass = 'La password deve avere almeno un numero';
      } else {
        if (hasLowerCase(str) == false) {
          this.errorPass = 'La password deve avere almeno una lettera maiuscola';
        } else {
          this.errorPass = '';
          this.errorCr = false;
        }

      }
    }
  }

  errorPassReapet = '';
  checkPassReapet() {
    if (this.RipetiPass != this.Pass) {
      this.errorPassReapet = 'Le password non corrispondono'
    } else {
      this.errorCr = false;
      this.errorPassReapet = ''
    }
  }

  saveCredential: any = [];
  errorCr: boolean = false;
  SaveCredential() {
    if (this.Pass != '' && this.RipetiPass != '' && this.errorPassReapet == '' && this.errorPass == '') {
      this.service.SaveContactCredential(this.Profilo.IdGestionale, this.Pass, this.Profilo.Username).pipe(
        catchError(error => {
          return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
        })
      ).subscribe(
        res => {
          this.saveCredential = res;
          this.errorCr = false;
          if (this.saveCredential == true) {
            this.msg=true;
            setTimeout(() => {
              this.msg=false;
              this.IsTemporary = false;
            }, 1000);
          }
        }
      )
    } else {
      this.errorCr = true;
    }
  }

  logout(){
    this.IsTemporary = false;
    sessionStorage.removeItem("profil");
  }

}
