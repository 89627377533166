import { Component, OnInit, ViewChild, Inject, PLATFORM_ID } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SideNavService } from './services/side-nav.service';
import { MetatagsService } from './services/metatags.service';
import { seoSitemap } from './seo-sitemap';
import { Event, Router, NavigationEnd, NavigationStart, NavigationCancel, NavigationError, ActivatedRoute } from '@angular/router';
import { transition, trigger, style, animate } from '@angular/animations';
import { ServiceService } from './services/service.service';
import * as moment from 'moment';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { TranslateService } from "@ngx-translate/core";
import { catchError, filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('myAnimation', [
      transition('*<=>*', [
        // css styles at start of transition
        style({ opacity: 0 }),
        // animation and styles at end of transition
        animate('0s', style({ opacity: 1 }))
      ])
    ])
  ]
})

export class AppComponent implements OnInit {

  title = 'black-smith';
  sub;

  @ViewChild('sidenav') public sidenav: MatSidenav;

  constructor(private sideNavService: SideNavService,
    @Inject(DOCUMENT) private _document,
    public MetatagsServ: MetatagsService,
    private router: Router,
    public service: ServiceService,
    @Inject(PLATFORM_ID) private platformId: Object, public translate: TranslateService) {
      translate.setDefaultLang('it');
      translate.use('it');

      const fPath = this._document.location.href.replace(/\http:/g, 'https:');
      const fullPath = fPath.replace(/^https?:\/\/(www\.)?/i, 'https://');
      const canonicalLink = this._document.createElement('link');
      canonicalLink.setAttribute('rel', 'canonical');
      fullPath.includes('/home')?canonicalLink.setAttribute('href', 'https://blacksmithitalia.it/'):canonicalLink.setAttribute('href', fullPath);
      this._document.head.appendChild(canonicalLink);

    this.sub = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.setMetaTags(event);
      }
    });
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          setTimeout(() => {
            this.loading = false;
          }, 500);
          break;
        }
        default: {
          break;
        }
      }
    });
    this.sideNavService.sideNavToggleSubject.subscribe(() => {
      this.sidenav?.toggle();
    });
  }


  loading = false;

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      this.sub && this.sub.unsubscribe();
    }
  }


  ngOnInit() {
    var date = moment(new Date(), "YYYY-MM-DD");
    var hour = new Date().getHours();
    if (JSON.parse(localStorage.getItem("token")) != null) {
      var DateToken = JSON.parse(localStorage.getItem("DtToken"));
      if (moment(DateToken, 'YYYY-MM-DD') > date) {
        this.service.updatetoken(JSON.parse(localStorage.getItem("token")));
        this.GetGroups();
      } else if (moment(DateToken, 'YYYY-MM-DD') == date) {
        if (new Date(DateToken).getHours() > hour) {
          this.service.updatetoken(JSON.parse(localStorage.getItem("token")));
          this.GetGroups();
        } else {
          this.getToken();
        }
      } else {
        this.getToken();
      }
    } else {
      this.getToken();
    }
    if (isPlatformBrowser(this.platformId)) {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
  }

  token: any = [];
  getToken() {
    this.service.getToken().pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.token = res;
        this.service.updatetoken(this.token.Token);
        localStorage.setItem("token", JSON.stringify(this.token.Token));
        localStorage.setItem("DtToken", JSON.stringify(moment(this.token.DtExpire.substring(0, 15), 'YYYY-MM-DD:hh:mm')));
        this.GetGroups();
      }, error => {
        // You can access status:
        console.log(error.message);
      }
    )
  }

  Groups: any = [];
  GetGroups() {
    if (localStorage.getItem('langChanged') != null && localStorage.getItem('langChanged') == 'true') {
      this.getGroupsFromServer();
      return;
    }
    var DateGroup = JSON.parse(localStorage.getItem("DG"));
    if (JSON.parse(localStorage.getItem("G")) != null) {
      // Adjust the moment format to match the ISO format
      let Dateg = new Date(moment(DateGroup).toISOString());
      // Calculate the difference in minutes
      let timeDifferenceMinutes = Math.round((new Date().getTime() - Dateg.getTime()) / 60000);
      if (timeDifferenceMinutes <= 5) {
        this.service.updateUserData(JSON.parse(localStorage.getItem('G')));
      } else {
        this.getGroupsFromServer();
        return;
      }
    } else {
      this.getGroupsFromServer();
      return;
    }
  }


  getGroupsFromServer() {
    this.service.GetGroups(null, JSON.parse(localStorage.getItem("profil"))?.IdGestionale).pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.Groups = res;
        this.service.updateUserData(this.Groups);
        localStorage.setItem("G", JSON.stringify(this.Groups));
        localStorage.setItem("DG", JSON.stringify(moment(new Date(), 'YYYY-MM-DD:HH:mm')));
        localStorage.removeItem('langChanged');
      }, error => {
        // You can access status:
        console.log(error.status);
      }
    )
  }

  private setMetaTags(event: NavigationEnd) {
    const item = seoSitemap.find((i) => event.urlAfterRedirects === i.customUrl);
    if (item) {
      if (item.title != null) {
        if (item.title) this.MetatagsServ.updateTitle(item.title);
        this.MetatagsServ.updateTags([
          item.description ? { name: 'description', content: item.description.toLowerCase() } : null,
          item.keywords ? { name: 'keywords', content: item.keywords } : null,
          item.image ? { name: 'image', content: item.image } : null,
          { property: 'og:title', content: item.title.toLowerCase() },
          { name: 'title', content: item.title.toLowerCase() },
          { property: 'og:image', content: item.image },
          { property: 'og:description', content: item.description.toLowerCase() },
          { property: 'og:url', content: window.location.href },
        ]);
      }
    }
  }
}
