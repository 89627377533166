<section class="bg-section-grey">
  <div class="container">
    <div class="row">
      <div class="col-lg-10 offset-lg-1">

        <h2 class="title"><img style="cursor:pointer" src="assets/img/arrow-l.svg" alt="blacksmith" routerLink="/area-riservata" class="mr-3"> {{'Register.section1.title' | translate}}</h2>
        <div class="main-carrello mt-4">
          <mat-checkbox class="example-margin" (change)="comp($event)">{{'Register.section1.azienda' | translate}}</mat-checkbox>
            <div class="row mt-4" *ngIf="companyname==true">
              <div class="mb-3 col-lg-12">
                <label for=""> {{'Pagamento.fattura.tipCliente' | translate}}</label>
                <mat-radio-group name="Tipologie" [(ngModel)]="Tipologie" required (change)="checkstatutFieldF()"
                  class="mt-1 d-flex w-100 justify-content-around" >
                  <mat-radio-button *ngFor="let item of CustomerTpes" value="{{item.Code}}">
                    {{(item.Description=='B2B - AZIENDA'&&service.currentLang=='en')?'B2B - COMPANY':(item.Description=='IVA - PRIVATO'&&service.currentLang=='en')?'VAT - PRIVATE':(item.Description=='PA - PUBBLICA AMMINISTRAZIONE'&&service.currentLang=='en')?'PA - PUBLIC ADMINISTRATION':item.Description}}</mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="mb-3 col-lg-12" *ngIf="Tipologie!='IVA'">
                <input type="text" name="FragioneSociale" [(ngModel)]="FragioneSociale"
                  [required]="(Tipologie=='PA' || (Fnome=='' && Fcognome=='')) && registerError!=''" placeholder="{{'Pagamento.fattura.ragSoc' | translate}}"
                  (change)="checkstatutFieldF()">
              </div>
              <div class="mb-3 col-lg-6" *ngIf="Tipologie!='PA'">
                <input type="text" name="Fnome" [(ngModel)]="Fnome"
                  [required]="(FragioneSociale=='' || Tipologie=='IVA') && registerError!=''" placeholder="{{'Pagamento.section1.nome' | translate}}"
                  (change)="checkstatutFieldF()">
              </div>
              <div class="mb-3 col-lg-6" *ngIf="Tipologie!='PA'">
                <input type="text" name="Fcognome" [(ngModel)]="Fcognome"
                  [required]="(FragioneSociale=='' || Tipologie=='IVA') && registerError!=''" placeholder="{{'Pagamento.section1.cognome' | translate}}"
                  (change)="checkstatutFieldF()">
              </div>

              <div class="mb-3 col-lg-8">
                <input type="text" name="FIndirizzo" [(ngModel)]="FIndirizzo" [required]="FIndirizzo=='' && registerError!=''" placeholder="{{'Pagamento.section1.via' | translate}}">
              </div>
              <div class="mb-3 col-lg-4">
                <input type="text" name="Fcivico" [(ngModel)]="Fcivico" [required]="Fcivico=='' && registerError!=''" placeholder="{{'Pagamento.section1.civico' | translate}}">
              </div>
              <div class="mb-3 col-lg-6">
                <select [(ngModel)]="FCountry" name="FCountry" (change)="changeCountry()">
                  <option [selected]="true" ngValue=" Italia">Italia</option>
                  <option value="{{item.Country}}" *ngFor="let item of CountryCodes">
                    {{item.Country}}
                  </option>
                </select>
              </div>
              <div class="mb-3 col-6">
                <select name="FProvincia" [(ngModel)]="FProvincia" [required]="FProvincia=='' && registerError!=''" >
                  <option value="">{{'Pagamento.section1.prov' | translate}}</option>
                  <option *ngFor="let item of provinces" value="{{item.Acronym}}">
                    {{item.Acronym}} | {{item.Description}}
                  </option>
                </select>
              </div>
              <div class="mb-3 col-6">
                <input type="text" name="FCitta" [(ngModel)]="FCitta" [required]="FCitta=='' && registerError!=''" placeholder="{{'Pagamento.section1.citta' | translate}}">
              </div>
              <div class="mb-3 col-lg-3">
                <input type="text" name="Fcap" [(ngModel)]="Fcap" [required]="Fcap=='' && registerError!=''" placeholder="{{'Pagamento.section1.CAP' | translate}}">
              </div>
            </div>

            <div class="mt-2 row" *ngIf="companyname==true">
              <div class="mb-3 col-lg-6" *ngIf="Tipologie!='IVA'">
                <input type="text" name="Fiva" [(ngModel)]="Fiva"
                  [required]="Fcf==null || Fcf=='' && Tipologie!='IVA'&& registerError!=''" (change)="checkstatutFieldF()"
                  [minlength]="(FCountry=='Italia' || FCountry==null)?11:0" placeholder="{{'Pagamento.fattura.piva' | translate}}"
                  [maxlength]="(FCountry=='Italia' || FCountry==null)?11:100">
                <small *ngIf="erreurFiva==true" class="text-danger">
                  {{'Pagamento.fattura.pivaalert' | translate}}
                </small>
              </div>
              <div class="mb-3 col-lg-6">
                <input type="text" name="Fcf" [(ngModel)]="Fcf"
                  [required]="(Fiva==null || Fiva=='' ) && registerError!=''" (change)="checkstatutFieldF()" placeholder="{{'Pagamento.fattura.cf' | translate}}"
                  [minlength]="Tipologie=='IVA'?16:0" [maxlength]="Tipologie=='IVA'?16:100">
                <small *ngIf="erreurCodiceFs==true" class="text-danger">
                  {{'Pagamento.fattura.cfalert' | translate}}
                </small>
              </div>
              <div class="mb-3 col-lg-6" *ngIf="Tipologie!='IVA'">
                <input type="text" name="Fsdi" [(ngModel)]="Fsdi"
                  [required]="Fpec==null || Fpec=='' && Tipologie!='IVA'  && registerError!=''" (change)="checkstatutFieldF()" placeholder="{{'Pagamento.fattura.sdi' | translate}}"
                  [maxlength]="Tipologie=='PA'?6:7" [minlength]="Tipologie=='PA'?6:7">
                <small *ngIf="erreurSDI==true && Tipologie=='PA'" class="text-danger">
                  {{'Pagamento.fattura.sdialert' | translate}}
                </small>
                <small *ngIf="erreurSDI==true && Tipologie!='PA'" class="text-danger">
                  {{'Pagamento.fattura.sdialert2' | translate}}
                </small>
              </div>
              <div class="mb-3 col-lg-6" *ngIf="Tipologie!='IVA'">
                <input type="email" name="Fpec" [(ngModel)]="Fpec"
                  [required]="Fsdi==null || Fsdi=='' && Tipologie!='IVA' && registerError!=''" (change)="checkstatutFieldF()" placeholder="Pec"
                  [ngClass]="{'required':emailErrorformat==true}">
                <small *ngIf="emailErrorformat==true" class="text-danger">{{'Pagamento.fattura.pecalert' |
                  translate}}</small>
              </div>
              <div class=" col-lg-6">
                <label for="">{{'Pagamento.fattura.esig' | translate}}</label>
                <select name="Esigibilita" [(ngModel)]="Esigibilita" required
                  (change)="checkstatutFieldF()">
                  <option *ngFor="let item of VatExigibility" value="{{item.Code}}">
                    {{item.Description}}
                  </option>
                </select>
              </div>
            </div>
          <hr>
          <div class="mt-3 row">
            <div class="mb-3 col-lg-6" *ngIf="companyname==false">
              <input type="text" name="Nome" [(ngModel)]="Nome"
              [ngClass]="{'required': Nome==''}" placeholder="{{'Pagamento.section1.nome' | translate}}"
                (change)="checkstatutFieldF()">
            </div>
            <div class="mb-3 col-lg-6" *ngIf="companyname==false">
              <input type="text" name="Cognome" [(ngModel)]="Cognome"
              [ngClass]="{'required': Cognome==''}" placeholder="{{'Pagamento.section1.cognome' | translate}}"
                (change)="checkstatutFieldF()">
            </div>
            <div class="col-lg-6 mb-3">
              <input type="email" [(ngModel)]="Email" placeholder="Email*" autocomplete="new-password" class="em"
                [ngClass]="{'required': validateEmail(Email)==false && registerError!=''}">
              <small *ngIf="validateEmail(Email)==false && registerError!=''" class="text-danger">inserire un
                indirizzo mail valido</small>
            </div>
            <div class="col-lg-6 mb-3">
              <input type="text" hidden>
              <ngx-intl-tel-input [preferredCountries]="['it']" [enableAutoCountrySelect]="true" [separateDialCode]="true"
                  [enablePlaceholder]="false" [searchCountryFlag]="true" [selectFirstCountry]="true"
                  [phoneValidation]="false" [(ngModel)]="Cellulare" #phoneNGX required
                  [ngClass]="{'required': Cellulare==null && registerError!=''}"
                  (keydown.space)="$event.preventDefault();">
              </ngx-intl-tel-input>
            </div>
            <div class="col-lg-6 mb-3">
              <div class="position-relative">
                <input [type]="fieldTextType ? 'text' : 'password'" [(ngModel)]="Pass" placeholder="Password*"
                  autocomplete="new-password" [required]="registerError!='' && Pass==''"
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9]).{6,}"
                  (input)="checkPass();RipetiPass!=''?checkPassReapet():1==1"
                  (keydown.space)="$event.preventDefault();" maxlength="25">
                <small *ngIf="errorPass!=''" class="text-danger">{{errorPass}}</small>
                <mat-icon class="aps-icon" (click)="fieldTextType = !fieldTextType;icon=!icon" style="cursor: pointer">
                  {{icon?'visibility_off':'visibility'}}</mat-icon>
              </div>
            </div>
            <div class="col-lg-6 mb-3">
              <div class="position-relative">
                <input [type]="fieldTextType1 ? 'text' : 'password'" [(ngModel)]="RipetiPass"
                  autocomplete="new-password" placeholder="{{'Pagamento.section1.ripetipass' | translate}}*"
                  [required]="registerError!='' && RipetiPass==''" (keydown.space)="$event.preventDefault();"
                  (input)="checkPassReapet()" maxlength="25">
                <small *ngIf="errorPassReapet!=''" class="text-danger">{{errorPassReapet}}</small>
                <mat-icon class="aps-icon" (click)="fieldTextType1 = !fieldTextType1;icon1=!icon1"
                  style="cursor: pointer">{{icon1?'visibility_off':'visibility'}}</mat-icon>
              </div>
            </div>
            <div class="col-lg-6 mb-3">
              <select [(ngModel)]="pricelistselected">
                <option value="">{{'Register.section1.TipologiaCliente' | translate}}</option>
                <option *ngFor="let item of PriceLists" [value]="item.Code">{{item.Description}}</option>
              </select>
            </div>


            <div class="col-lg-12">
              <div class="row mt-3">
                <div class="col-lg-6 mb-3">
                  <mat-checkbox [ngClass]="{'required':condizioni==false && registerError!=''}"
                    [(ngModel)]="condizioni">
                    {{'Profile.ordini.privacy' | translate}}<a routerLink="/informativa-privacy" target="blank" style="font-size: inherit"> {{'Profile.ordini.privacy2' | translate}}</a></mat-checkbox>
                </div>
                <div class="col-lg-6 mb-3">
                  <mat-checkbox [ngClass]="{'required':privacy==false && registerError!=''}" [(ngModel)]="privacy">
                    {{'Profile.ordini.tratt' | translate}}<a routerLink="/termini-condizioni" target="blank" style="font-size: inherit" class="ml-1">{{'Profile.ordini.tratt2' | translate}}</a></mat-checkbox>
                </div>
              </div>
            </div>

            <div class="col-lg-6 mb-3">
              <div class="d-flex flex-column mt-4">
                <button type="submit" class="btn btn-bg" (click)="RegisterF()">
                  <span *ngIf="p==false">{{'Register.section1.crea' | translate}}</span>
                  <mat-spinner *ngIf="p==true" class="mx-auto" [diameter]="30"></mat-spinner>
                </button>
                <div class="alert alert-danger mt-3" role="alert" *ngIf="loginError==true">
                  {{registerResult.message}}
                </div>
                <div class="alert alert-success mt-3" role="alert" *ngIf="msg==true">
                  {{'Register.section1.result' | translate}}
                </div>
                <div class="alert alert-danger mt-3" role="alert" *ngIf="registerError!=''">
                  {{registerError}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
