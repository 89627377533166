<section class="bg-section-grey" style="min-height: 57vh">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h1 class="title mb-4">{{BrandsName}}</h1>
        <div>
          <div class="d-flex flex-wrap align-items-start">
            <span class="result mr-auto" ><b>{{totalProducts}} </b>
              <ng-container *ngIf="totalProducts!=1">{{'CategorieDettagli.section1.totpro1' | translate}}
              </ng-container>
              <ng-container *ngIf="totalProducts==1">{{'CategorieDettagli.section1.totpro2' | translate}}
              </ng-container>
            </span>
          </div>
          <div class="d-flex flex-wrap my-4 justify-content-center justify-content-xl-start">

            <ng-container *ngIf="loader">
              <a class="d-flex flex-column card-s" style="border:none !important" *ngFor="let item of fakelist">
                <div class="placeholderimg"></div>
                <div class="prod-desc bg-white">
                  <span class="placeholder sml mt-2"></span>
                  <span class="placeholder sml"></span>
                  <div class="d-flex justify-content-between mt-5">
                    <span class="placeholder col-5"></span>
                    <span class="placeholder col-5"></span>
                  </div>
                </div>
              </a>
            </ng-container>

            <a class="d-flex flex-column card-s" *ngFor="let item of products"
              routerLink="{{(service.currentLang=='en'?'/en':'') + '/prodotto/' + item.Id}}">
              <div class="ribbon" *ngIf="item.Discount!=null && item.Discount!=0"><span>{{item.Discount}}{{item.IsPercentage==true?'%':'€'}}
                <small>{{ "CategorieDettagli.section1.discount" | translate}}</small></span></div>
              <!-- <img loading="lazy" src="assets/img/b.png" class="imgb"> -->
              <img loading="lazy" src="{{item?.urlImages[0]}}" *ngIf="item.urlImages?.length!=0 && item.urlImages!=null"
                alt="{{item.Title}}">
              <img loading="lazy" src="assets/no-image.png"
                *ngIf="!item.urlImages || item.urlImages?.length==0 || item.urlImages==null" alt="blacksmith italia">
              <div class="prod-desc">
                <span class="desc">{{item.Title}}</span>
                <span class="p-cat">{{item.Code}}</span>
                <div class="m-price">
                  <h5 *ngIf="!item.StopSellWeb"> <ng-container *ngIf="item.haveChildren">{{'CategorieDettagli.section1.da' |
                      translate}}</ng-container>
                    {{item.Price | currency : "EUR" : "symbol" : "1.2-2" : "it" }}
                    <small class="soldp" *ngIf="item.Discount!=null && item.Discount!=0">{{item.OriginalPrice | currency
                      : "EUR" : "symbol" :
                      "1.2-2" : "it" }}</small>
                    <span class="ml-3 text-nowrap">{{'CategorieDettagli.section1.iva' | translate}}</span>
                    <h5 *ngIf="item.StopSellWeb">
                      {{'ProdDet.section1.richiediInformazioni' | translate}}
                    </h5>
                  </h5>
                </div>
              </div>
              <div class="shop-btns">
                <button>
                  +
                </button>
              </div>
            </a>
          </div>
<!--
          <img loading="lazy" class="d-block my-4 mx-auto" width="160" height="160" *ngIf="loader==true"
            src="assets/img/reload.gif" alt="loader horse"> -->

          <div class="d-flex flex-wrap justify-content-center" *ngIf="products.length!=0">
            <button class="btn btn-w" (click)="nextPage()"
              *ngIf="pageIndex!=pageLimit">{{"CategorieDettagli.section1.mostradipiu" | translate}} ({{productleft}})
              <img src="assets/img/arrow-bottom.svg" class="ml-3" width="17" alt="arrow-bottom"></button>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<section>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h4 class="title">Brand</h4>
        <carousel [lightDOM]="'true'" [height]="120" [cellWidth]="210" [dots]="false" [arrows]="true"
          [arrowsOutside]="true" [loop]="true" class="mt-3" *ngIf="showcarousel">
          <ng-container *ngFor="let item of Brands">
            <a class="carousel-cell brands"  *ngIf="!item.Link"
              routerLink="{{(service.currentLang=='en'?'/en':'') + '/marca/'+ replaceSpacesWithDash(item.Description)  +'/' + item.Id}}">
              <img loading="lazy" src="{{item.UrlImage}}" alt="{{item.Description}}">
              <b class="nameBrand">{{item.Description}}</b>
            </a>
            <a class="carousel-cell brands"  *ngIf="item.Link" [href]="item.Link" target="_blank">
            <img loading="lazy" src="{{item.UrlImage}}" alt="{{item.Description}}">
            <b class="nameBrand">{{item.Description}}</b>
          </a>
          </ng-container>
        </carousel>
        <button class="btn btn-bg ml-auto mt-3" style="max-width:300px"
          routerLink="{{(service.currentLang=='en'?'/en/marche':'/marche')}}">{{'Home.brand.title' |
          translate}}</button>
      </div>
    </div>
  </div>
</section>
