import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ServiceService } from '../services/service.service';
import * as moment from 'moment';

import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.css']
})
export class BrandsComponent implements OnInit {

  constructor(public router: Router, private service: ServiceService, @Inject(PLATFORM_ID) private platformId: Object) { }


  ngOnInit(): void {
    this.getGetBrandsFromServer();
  }

  Brands: any = [];

  // GetBrands() {
  //   var DateBrands = JSON.parse(localStorage.getItem("DB"));
  //   if (JSON.parse(localStorage.getItem("B")) != null) {
  //     if (Math.round((new Date().getTime() - new Date(moment(DateBrands).format('YYYY-MM-DD HH:mm')).getTime()) / 50000) <= 10) {
  //       this.Brands = JSON.parse(localStorage.getItem('B'));
  //     } else {
  //       this.getGetBrandsFromServer();
  //     }
  //   } else {
  //   this.getGetBrandsFromServer();
  //   }
  // }

  getGetBrandsFromServer() {
    this.service.GetBrands(0).pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.Brands = res;
        if (this.Brands.length != 0) {
          localStorage.setItem('B', JSON.stringify(this.Brands));
          localStorage.setItem("DB", JSON.stringify(moment(new Date()).format('YYYY-MM-DD:HH:mm')));
        }
      })
  }


}
