import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../services/service.service';
import { catchError } from 'rxjs/operators';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  constructor(private service: ServiceService, private router: Router, public ActivateRouter: ActivatedRoute,private metaService:Meta) {
    this.paga = this.ActivateRouter.snapshot.queryParamMap.get("paga");
  }
  paga;
  ngOnInit(): void {
    this.metaService.addTag({ name: 'robots', content: 'noindex' });
    setTimeout(() => {
      this.GetPriceLists();
      this.GetCustomerTypes();
      this.GetVatExigibility();
      this.GetProvinces();
      this.GetCountryCodes();
    }, 500);
  }

  phoneNGX;

  p: boolean = false;
  loginError: boolean = false;

  register: boolean = false;
  msg: boolean = false;
  registerResult: any = [];
  Loginresult: any = [];
  SaveContactResult: any = [];

  registerError = '';
  RegisterF() {
    this.registerError = '';
    this.p = true;
    var isPersonal: boolean = false;
    if (this.companyname == false) {
      isPersonal = true;
      if (this.validateEmail(this.Email) == true && this.pricelistselected != '' && this.Pass != '' && this.Nome != '' && this.Cognome != '' && this.Email != '' && this.RipetiPass != '' && this.Cellulare != null && this.errorPassReapet == '' && this.errorPass == '' && this.privacy == true && this.condizioni == true) {
        this.service.Register(this.Nome, this.Cognome, this.Pass, this.Email, this.companynameValue, isPersonal, this.Cellulare.e164Number, this.pricelistselected).pipe(
          catchError(error => {
            return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
          })
        ).subscribe(
          res => {
            this.registerResult = res;
            if (this.registerResult.status == true) {
              setTimeout(() => {
                this.p = false;
                this.loginError = false;
                this.msg = true;
                setTimeout(() => {
                  if (this.paga == 'true') {
                    this.router.navigate(['/area-riservata'],
                      { queryParams: { paga: true } });
                  } else {
                    this.router.navigateByUrl('/area-riservata');
                  }
                }, 1200)
              }, 1500)
            } else {
              this.p = false;
              setTimeout(() => {
                this.loginError = true;
              }, 1200)
            }
          }
        )
      } else {
        this.p = false;
        this.registerError = 'compilare i dati obbligatori';
      }
    } else {
      if (this.validateEmail(this.Email) == true && this.pricelistselected != '' && ((this.Nome != '' && this.Cognome != '') || this.companynameValue != '') &&
       this.Pass != '' && this.Email != '' && this.RipetiPass != '' && this.Cellulare != null && this.errorPassReapet == '' && this.errorPass == '' &&
        this.privacy == true && this.condizioni == true && this.Tipologie == 'IVA' ? (this.Fnome != '' && this.Fcognome != '') : this.Tipologie == 'PA' ? this.FragioneSociale != '' : (this.FragioneSociale != '' || (this.Fnome != '' && this.Fcognome != '')) && ((this.Tipologie != 'IVA' ? (this.Fiva != '' || this.Fcf != '') : this.Fcf != '') && (this.Tipologie != 'IVA' ? (this.Fsdi != '' || this.Fpec != '') : 1 == 1)) && (this.FCitta != '' && this.FProvincia != '' && this.Fcap != '' && this.Tipologie != '' && this.Esigibilita != '' && this.FIndirizzo != '' && this.Fcivico != '') && this.erreurCodiceFs == false &&
         this.emailErrorformat == false && this.erreurSDI == false && this.erreurFiva == false) {
        this.service.RegisterCompany(this.Nome, this.Cognome, this.Pass, this.Email, this.companynameValue, isPersonal, this.Cellulare.e164Number, this.pricelistselected,this.FDatadinascita == '' ? null : this.FDatadinascita, this.Fiva, this.Fcf, this.Fsdi, this.Fpec, this.FCitta, this.FProvincia, this.Fcap, this.Esigibilita, this.Tipologie, this.FCountry, this.companynameValue, this.FIndirizzo, this.Fcivico, this.Fnome, this.Fcognome, this.FragioneSociale).pipe(
          catchError(error => {
            return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
          })
        ).subscribe(
          res => {
            this.registerResult = res;
            if (this.registerResult.status == true) {
              this.service.login(this.Email, this.Pass).pipe(
                catchError(error => {
                  return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
                })
              ).subscribe(
                res => {
                  this.Loginresult = res;
                  if (this.Loginresult != null) {
                    this.service.SaveContact(this.privacy, this.Nome, this.Cognome, this.Email, this.Cellulare?.e164Number, this.Cellulare?.e164Number, this.Loginresult.IdGestionale, this.FDatadinascita == '' ? null : this.FDatadinascita, this.Fiva, this.Fcf, this.Fsdi, this.Fpec, this.FCitta, this.FProvincia, this.Fcap, this.Esigibilita, this.Tipologie, this.FCountry, this.companynameValue, isPersonal, this.FIndirizzo, this.Fcivico, this.Fnome, this.Fcognome, this.FragioneSociale).pipe(
                      catchError(error => {
                        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
                      })
                    ).subscribe(
                      res => {
                        this.SaveContactResult = res;
                        if (this.SaveContactResult == true) {
                          this.updateFattError = '';
                          setTimeout(() => {
                            this.p = false;
                            this.loginError = false;
                            this.msg = true;
                            setTimeout(() => {
                              if (this.paga == 'true') {
                                this.router.navigate(['/area-riservata'],
                                  { queryParams: { paga: true } });
                              } else {
                                this.router.navigateByUrl('/area-riservata');
                              }
                            }, 1200)
                          }, 1500)
                        }
                      }
                    )
                  }
                }
              );
            } else {
              setTimeout(() => {
                this.p = false;
                this.loginError = true;
              }, 1200)
            }
          }
        )
      } else {
        this.p = false;
        this.registerError = 'compilare i dati obbligatori';
      }
    }
  }

  Nome = '';
  Cognome = '';
  Email = '';
  Cellulare;
  Pass = '';
  RipetiPass = '';
  companynameValue = '';


  companyname: boolean = false;

  comp(event) {
    if (event.checked == true) {
      this.companyname = true;
      this.Tipologie="B2B"
      this.Nome = '';
      this.Cognome = '';
    } else {
      this.companyname = false;
    }
    console.log(event)
  }

  errorPass = '';
  checkPass() {
    // String with some numbers
    const str = this.Pass;
    // Regular expression
    const regex = /\d/;
    // Check if string contians numbers
    const doesItHaveNumber = regex.test(str);
    function hasLowerCase(str) {
      return (/[A-Z]/.test(str));
    }

    if (str.length < 6) {
      this.errorPass = 'La password deve avere almeno 6 caratteri';
    } else {
      if (doesItHaveNumber == false) {
        this.errorPass = 'La password deve avere almeno un numero';
      } else {
        if (hasLowerCase(str) == false) {
          this.errorPass = 'La password deve avere almeno una lettera maiuscola';
        } else {
          this.errorPass = '';
        }

      }
    }
  }

  errorPassReapet = '';
  checkPassReapet() {
    if (this.RipetiPass != this.Pass) {
      this.errorPassReapet = 'Le password non corrispondono'
    } else {
      this.errorPassReapet = ''
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


  privacy: boolean = false;
  condizioni: boolean = false;


  fieldTextType;
  icon;
  fieldTextType1;
  icon1;

  pricelistselected = '';
  PriceLists: any = [];
  GetPriceLists() {
    this.service.GetPriceLists().pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.PriceLists = res;
      }
    )
  }

  CustomerTpes: any = [];
  GetCustomerTypes() {
    if (this.CustomerTpes.length == 0) {
      this.service.GetCustomerTypes().pipe(
        catchError(error => {
          return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
        })
      ).subscribe(
        res => {
          this.CustomerTpes = res;
        }
      )
    }
  }

  VatExigibility: any = [];
  GetVatExigibility() {
    if (this.VatExigibility.length == 0) {
      this.service.GetVatExigibility().pipe(
        catchError(error => {
          return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
        })
      ).subscribe(
        res => {
          this.VatExigibility = res;
        }
      )
    }
  }

  provinces: any = [];
  GetProvinces() {
    if (this.provinces.length == 0) {
      this.service.GetProvinces().pipe(
        catchError(error => {
          return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
        })
      ).subscribe(
        res => {
          this.provinces = res;
        }
      )
    }
  }


  CountryCodes: any = [];
  GetCountryCodes() {
    if (this.CountryCodes.length == 0) {
      this.service.GetCountryCodes().pipe(
        catchError(error => {
          return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
        })
      ).subscribe(
        res => {
          this.CountryCodes = res;
        }
      )
    }
  }

  FDatadinascita;
  Fiva = '';
  Fcf = '';
  Fsdi = '';
  Fpec = '';
  FCitta='';
  FProvincia = '';
  Fcap='';
  Esigibilita = 'I';
  Tipologie='';
  FCountry = 'Italia';
  FIndirizzo='';
  Fcivico='';
  Fnome = '';
  Fcognome = '';
  FragioneSociale = '';

  erreurCodiceFs: boolean = false;
  erreurSDI: boolean = false;
  erreurFiva: boolean = false;
  emailErrorformat: boolean = false;
  updateFattError = '';

  checkstatutFieldF() {
    this.Fcf.length < 16 && this.Fiva == '' && this.Tipologie == 'IVA' ? this.erreurCodiceFs = true : this.erreurCodiceFs = false;
    if (this.Tipologie == 'PA') {
      this.Fsdi.length < 6 && this.Fsdi != '' ? this.erreurSDI = true : this.erreurSDI = false;
    } else {
      this.Fsdi.length < 7 && this.Fsdi != '' ? this.erreurSDI = true : this.erreurSDI = false;
    }
    if (this.Fiva.length < 11 && (this.FCountry == 'Italia' || this.FCountry == null) && this.Fiva != '') {
      this.erreurFiva = true;
    } else {
      this.erreurFiva = false;
    }

    if (this.Tipologie == 'IVA' ? (this.Fnome != '' && this.Fcognome != '') : this.Tipologie == 'PA' ? this.FragioneSociale != '' : (this.FragioneSociale != '' || (this.Fnome != '' && this.Fcognome != '')) && ((this.Tipologie != 'IVA' ? (this.Fiva != '' || this.Fcf != '') : this.Fcf != '') && (this.Tipologie != 'IVA' ? (this.Fsdi != '' || this.Fpec != '') : 1 == 1)) && (this.FCitta != '' && this.FProvincia != '' && this.Fcap != '' && this.Tipologie != '' && this.Esigibilita != '' && this.erreurSDI == false && this.erreurCodiceFs == false)) {
      this.updateFattError = '';
    } else {
      this.updateFattError = 'Compila i dati obbligatori';
    }

    if (!this.validateEmail(this.Fpec) && this.Fpec != '') {
      this.emailErrorformat = true;
    } else {
      this.emailErrorformat = false;
    }
  }

  changeCountry(){
    if(this.FCountry.includes('Italia')){
      // this.FCountry = 'Italia';
      this.FProvincia = '';
      this.Fcap='';
      this.Fsdi='';
    }else{
      this.FProvincia='EE';
      this.Fcap='00000';
      this.Fsdi='XXXXXXX';
    }
  }

}
