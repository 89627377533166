<header class="header-section" (mouseleave)="closeMenuSearch()"
  [ngClass]="{'scrolled': scrolled}">
  <div class="inner-header">
    <div class="container py-0">
      <div class="first-header d-flex justify-content-between align-items-center">
        <div class="main-menu-s" (click)="clickMenu()"><span class="menu-s"></span></div>
        <div class="logores">
          <div class="logo">
            <a routerLink="{{service.currentLang=='en'?'/en/home':'/home'}}">
              <img src="assets/img/logo.png" width="270" height="36" alt="logo blacksmith" />
            </a>
          </div>
        </div>

        <div class="d-none d-xl-flex menu-p">
          <a routerLink="{{service.currentLang=='en'?'/en/chi-siamo':'/chi-siamo'}}">{{'menu.m1' | translate}}</a>
          <a routerLink="{{service.currentLang=='en'?'/en/prodotti':'/prodotti'}}">{{'menu.m2' | translate}}</a>
          <a routerLink="{{service.currentLang=='en'?'/en/educational':'/educational'}}">{{'menu.m3' | translate}}</a>
          <a routerLink="{{service.currentLang=='en'?'/en/news':'/news'}}">{{'menu.m4' | translate}}</a>
          <a routerLink="{{service.currentLang=='en'?'/en/contatti':'/contatti'}}">{{'menu.m5' | translate}}</a>
        </div>

        <div class="ml-auto d-flex" >
          <div class="d-flex align-items-center">
            <div class="container-search" (click)="searchActivated=true;refreshGroups()"
              [ngClass]="{'active': searchActivated==true}">
              <input type="text" placeholder="Search..." [(ngModel)]="searchValue" (keyup.enter)="toResult(); $event.stopPropagation()">
              <div class="autocomplete-items" *ngIf="searchValue!=''">
                <ng-container *ngFor="let item of categoriesList">
                  <a (click)="toProduct(item.Id); $event.stopPropagation()" style="font-weight: 500;">
                    <div *ngIf="item.Description.toLowerCase().includes(searchValue.toLowerCase())==true">
                      <ng-container *ngFor="let i of item.ListImages">
                        <img [src]="i.LinkImage" *ngIf="i.IsMainPicture==false" height="28" class="mr-2" alt="c-{{item.Description}}">
                      </ng-container>
                      {{item.Description}}
                    </div>
                  </a>
                  <ng-container *ngFor="let soucat of item.ListGroups">
                    <a (click)="toProduct(soucat.Id); $event.stopPropagation()">
                      <div *ngIf="soucat.Description.toLowerCase().includes(searchValue.toLowerCase())==true">
                        <ng-container *ngFor="let i of item.ListImages">
                          <img [src]="i.LinkImage" *ngIf="i.IsMainPicture==false" height="28" class="mr-2" alt="sc-{{soucat.Description}}">
                        </ng-container>
                        {{soucat.Description}}
                      </div>
                    </a>
                  </ng-container>
                </ng-container>
              </div>
              <span class="search" (click)="toResult(); $event.stopPropagation()"></span>
            </div>
            <a routerLink="/area-riservata" class="cart-icon">
              <img alt="account blacksmith" src="assets/img/account.svg" width="30" height="35">
            </a>
            <a routerLink="/wishlist" class="cart-icon ml-2 ml-md-3">
              <img alt="wishlist blacksmith" src="assets/img/wishlist.svg" width="26">
              <span class="countc">{{service.FavouritesCountFromService}}</span>
            </a>
            <div class="cart-icon ml-2 ml-md-3 mr-3 mr-md-2" (click)="showCarrello=true">
              <span>
                <img alt="carrello blacksmith" src="assets/img/carrello.svg" width="35" height="30">
                <span class="countc">{{product?.length}}</span>
              </span>
              <div class="cart-hover" [ngClass]="{'showCarre': showCarrello && product.length!=0}">
                <div class="select-items">
                  <table>
                    <tbody>
                      <tr *ngFor="let item of product" (click)="goToProduct(item)">
                        <td class="si-pic">
                          <img loading="lazy" *ngIf="item.urlImages!=null" src="{{item.urlImages}}" alt="carrello"/>
                          <img loading="lazy" *ngIf="item.urlImages==null" src="assets/no-image.png" alt="carrello" />
                        </td>
                        <td class="si-text">
                          <div class="product-selected">
                            <p>{{item.Price | currency : "EUR" : "symbol" : "1.2-2" : "it" }} x {{item.qte}}</p>
                            <h6>{{item.Title}}</h6>
                          </div>
                        </td>
                        <!-- <td class="si-close" (click)="removeProduct(item)">
                          <img src="assets/img/delete.svg" alt="delete" width="24">
                        </td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="select-total" *ngIf="product.length!=0">
                  <span>{{'menu.m6' | translate}}</span>
                  <h5>{{TotalCarrello | currency : "EUR" : "symbol" : "1.2-2" : "it" }}</h5>
                </div>
                <div class="select-button">
                  <a routerLink="/carrello" class="btn btn-w">{{'menu.m7' | translate}}</a>
                </div>
              </div>
            </div>
          </div>
          <span class="line-v"></span>
          <div class="lingua">
            <div class="dropdown">
              <button class="btn p-0 dropdown-toggle" id="dropdownMenuButton" (click)="openlang=!openlang"
                aria-label="scelta lingua">
                <img alt="Italiano" src="assets/img/ita.svg" width="40" height="30"
                  *ngIf="translate.store.currentLang=='it'">
                <img alt="Inglese" src="assets/img/eng.svg" width="40" height="30"
                  *ngIf="translate.store.currentLang=='en'">
              </button>
              <div class="dropdown-menu" [ngClass]="{'show': openlang}" aria-labelledby="dropdownMenuButton">
                <b class="dropdown-item" (click)="changeLang('it')"><img src="assets/img/ita.svg" width="40" height="30"
                    alt="Italiano" loading="lazy"></b>
                <b class="dropdown-item" (click)="changeLang('en')"><img src="assets/img/eng.svg" width="40" height="30"
                    alt="Inglese" loading="lazy"></b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="main-marq">
      <marquee class="marq"  loop="">
        {{'menu.marq' | translate}}
      </marquee>
    </div> -->
    <div class="s-menu-bg d-none d-lg-block">
      <div class="container">
        <nav class="navbar navbar-expand-lg p-0">
          <ul class="navbar-nav justify-content-between align-items-center w-100">
            <a class="nav-link navlinkx dropdown-toggle dnm" id="navbarDropdownMenuLink"
              routerLink="{{service.currentLang=='en'?'/en/prodotti':'/prodotti'}}">
              {{'menu.all' | translate}}</a>

            <ng-container *ngIf="categoriesMenu.length==0">
              <li class="nav-item dropdown" *ngFor="let item of fakelist">
                <span class="nav-link navlinkx placeholder sml mb-0"></span>
              </li>
            </ng-container>

            <li class="nav-item dropdown" *ngFor="let item of categoriesMenu">
              <a class="nav-link navlinkx dropdown-toggle"
               routerLink="{{(service.currentLang === 'en' ? '/en/categoria/' : '/categoria/') + item.Id }}"
                id="navbarDropdownMenuLink">
                <ng-container *ngFor="let i of item.ListImages">
                  <img [src]="i.LinkImage" *ngIf="i.IsMainPicture==false" height="28" class="mr-2" alt="cat- {{item.Description}}">
                </ng-container>
                {{item.Description}}
              </a>
              <div class="dropdown-menu dropdownmenux" aria-labelledby="navbarDropdownMenuLink">
                <ul>
                  <li *ngFor="let soucat of item.ListGroups" class="dropdown">
                    <a class="dropdown-item navlinkx dropdown-toggle" id="navbarDropdownMenuLink2"
                      routerLink="{{(service.currentLang=='en'?'/en/categoria/':'/categoria/')+ soucat.Id}}">
                      {{soucat.Description}}
                    </a>
                    <div class="dropdown-menu dropdownmenux" aria-labelledby="navbarDropdownMenuLink2"
                      *ngIf="soucat.ListGroups.length!=0">
                      <ul>
                        <li *ngFor="let soucat2 of soucat.ListGroups">
                          <a class="dropdown-item navlinkx dropdown-toggle" id="navbarDropdownMenuLink3"
                            routerLink="{{(service.currentLang=='en'?'/en/categoria/':'/categoria/')+ soucat2.Id}}">
                            {{soucat2.Description}}</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</header>
<div class="main-carrello modal-carrello" *ngIf="modal==true" style="background-color: #f1f2ff">
  <div class="d-flex flex-wrap align-items-center justify-content-around mb-4">
    <div class="mb-2">
      <img class="imgmodelc" *ngIf="lastProduct[0].urlImages!=null" src="{{lastProduct[0].urlImages[0]}}" width="100"
        height="100">
      <img class="imgmodelc" *ngIf="lastProduct[0].urlImages==null" src="assets/no-image.png" width="100" height="100">
    </div>
    <div>
      <h5 class="text-success mb-3 ml-3">{{'menu.m8' | translate}}</h5>
      <h5 class="ml-3">Totale parziale carrello: <br> ({{product.length}} prodotti) <b>{{TotalCarrello | currency :
          "EUR" : "symbol" : "1.2-2" : "it" }}</b></h5>
    </div>
  </div>
  <div>
    <a (click)="closeModal()" class="btn btn-w-border mb-2">{{'menu.m9' | translate}}</a>
    <a (click)="closeModal()" routerLink="/carrello" class="btn btn-bg">{{'menu.m10' | translate}}</a>
  </div>
</div>
<div class="modal-carrello-bg" *ngIf="modal==true"></div>
