import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-result-success',
  templateUrl: './result-success.component.html',
  styleUrls: ['./result-success.component.css']
})
export class ResultSuccessComponent implements OnInit {

  constructor(private route: ActivatedRoute, private renderer2: Renderer2, @Inject(DOCUMENT) private _document) {
  }

  index = 0;
  bonifico;
  estero;
  ngOnInit(): void {
    this.bonifico = this.route.snapshot.paramMap.get('bonifico');
    this.estero = this.route.snapshot.paramMap.get('estero');


    //script purchase GA4
    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';
    s.src = 'assets/GA4_purchase.js';
    s.text = ``;
    this.renderer2.appendChild(this._document.body, s);


  }

}
