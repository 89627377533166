import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../services/service.service';

import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import * as moment from 'moment';
import { Meta, Title } from '@angular/platform-browser';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.css']
})
export class BrandComponent implements OnInit {

  constructor(private title: Title, private metaService: Meta, public service: ServiceService, private route: ActivatedRoute, @Inject(PLATFORM_ID) private platformId: Object,private router: Router) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
   }

  search = '';
  products: any = [];
  newproduct: any = [];
  loader: boolean = false;
  searched = '';
  orderPriceAsc: boolean = false;
  orderPriceDesc: boolean = false;
  brandSelected = 'null';
  Brands: any = [];
  BrandsName = '';
  showcarousel: boolean = false;

  ngOnInit(): void {
    this.brandSelected = this.route.snapshot.paramMap.get('id');
    this.BrandsName = this.route.snapshot.paramMap.get('name');
    //SEO
    this.title.setTitle('Blacksmith Italia - ' + this.BrandsName);
    this.metaService.addTag({ name: 'title', content: 'Blacksmith Italia - ' + this.BrandsName });
    this.metaService.addTag({ property: 'og:title', content: 'Blacksmith Italia - ' + this.BrandsName });
    this.metaService.addTag({ name: 'description', content: 'Rivendita di utensili e attrezzi per mascalcia e per la cura del cavallo: ferri da cavallo, forge, incudini, raspe, lime, chiodi per ferrare.' });
    this.metaService.addTag({ property: 'og:description', content: 'Rivendita di utensili e attrezzi per mascalcia e per la cura del cavallo: ferri da cavallo, forge, incudini, raspe, lime, chiodi per ferrare.' });
    // let imageBrand = JSON.parse(localStorage.getItem('B')).filter(x=> x.Id==this.brandSelected);
    // this.metaService.addTag({ property: 'image', content: imageBrand[0].UrlImage });
    // this.metaService.addTag({ property: 'og:image', content: imageBrand[0].UrlImage });
    this.getTotale();

    if (isPlatformBrowser(this.platformId)) {
      this.showcarousel = true;
    }
    setTimeout(() => {
      this.GetBrands();
    }, 150);
  }

  replaceSpacesWithDash(description: string): string {
    return description.replace(/\s+/g, '-');
  }

  fakelist:any[] = new Array(15);

  data: any = [];
  getTotale() {
    this.loader=true;
    this.service.GetTotalProductsWeb('', -1, -1, this.search, this.brandSelected, this.orderPriceDesc, this.orderPriceAsc, -1).pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      products => {
        this.data = products;
        this.totalProducts = this.data;
        this.fakelist = new Array(this.totalProducts);
        if ((this.totalProducts / this.pageSize) % 1 != 0) {
          this.pageLimit = Math.trunc(this.totalProducts / this.pageSize) + 1;
        } else {
          this.pageLimit = 1;
          this.pageIndex = 1;
        }
        this.changeSizePage(this.pageSize);
      });
  }


  GetProducts() {
    // this.products=[];
    this.service.getProducts('', 0, this.pageSize, this.search, this.brandSelected,null, this.orderPriceDesc, this.orderPriceAsc, -1).pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      products => {
        this.products = products;
        this.loader=false;
      });
  }


  pageIndex: number = 1;
  pageLimit: number = 0;
  pageSize: number = 15;
  Offset: number = 0;
  totalProducts: number = 0;
  productleft:number=0;

  nextPage() {
    this.newproduct=[];
    if (this.pageIndex < this.pageLimit) {
      this.pageIndex += 1;
      this.Offset = this.Offset + this.pageSize;
      this.productleft=this.productleft - this.pageSize;
      console.log(this.Offset)
      this.loader = true;
      this.service.getProducts('', this.Offset, this.pageSize, this.search, this.brandSelected,null, this.orderPriceDesc, this.orderPriceAsc, -1).pipe(
        catchError(error => {
          return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
        })
      ).subscribe(
        products => {
          this.newproduct=products;
          for (let i = 0; i < this.newproduct.length; i++) {
            this.products.push(this.newproduct[i]);
          }
          this.loader = false;
        });
    }
  }


  changeSizePage(i) {
    this.productleft=this.totalProducts - this.pageSize;
    this.pageSize = parseInt(i);
    this.pageIndex = 1;
    if ((this.totalProducts / this.pageSize) % 1 != 0) {
      this.pageLimit = Math.trunc(this.totalProducts / this.pageSize) + 1;
    }
    this.GetProducts();
    if (isPlatformBrowser(this.platformId)) {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
  }



  GetBrands() {
    var DateBrands = JSON.parse(localStorage.getItem("DB"));
    if (JSON.parse(localStorage.getItem("B")) != null) {
      let DateBrand=new Date(moment(DateBrands,'YYYY-MM-DD:hh:mm').toISOString());
      if (Math.round((new Date().getTime() - DateBrand.getTime()) / 50000) <= 10) {
        this.Brands = JSON.parse(localStorage.getItem('B'));
      } else {
        this.getGetBrandsFromServer();
      }
    } else {
    this.getGetBrandsFromServer();
    }
  }

  getGetBrandsFromServer() {
    this.service.GetBrands(0).pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      res => {
        this.Brands = res;
        if (this.Brands.length!=0) {
          localStorage.setItem('B', JSON.stringify(this.Brands));
          localStorage.setItem("DB", JSON.stringify(moment(new Date()).format('YYYY-MM-DD:HH:mm')));
        }
      })
  }


}

