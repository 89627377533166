<section class="bg-section pt-4" *ngIf="!disponible">
  <div class="container">
    <div class="main-product mt-4">
      <div class="text-center">
        <img src="assets/img/404.png" alt="not found" height="90">
        <p class="mt-4 mb-2">PRODOTTO</p>
        <h2>NON TROVATO</h2>
        <p>Il prodotto che stai cercando non è disponibile.</p>
      </div>
      <div class="separate my-4"></div>
      <div class="box">
        <h2 class="title">{{'Home.section2.title' | translate}}</h2>
        <!-- <img loading="lazy" class="d-block mt-4 mx-auto" width="160" height="160" *ngIf="Evidenza.length==0"
          src="assets/img/reload.gif" alt="loader horse"> -->
        <carousel [lightDOM]="'true'" [height]="389" [cellWidth]="290" [dots]="false" [arrows]="true"
          [arrowsOutside]="false" [loop]="true" class="mt-5 couselx" *ngIf="showcarousel">

          <ng-container *ngIf="Evidenza.length==0">
            <div class="carousel-cell pt-2" *ngFor="let item of fakelist">
              <a class="d-flex flex-column card-s" style="border:none !important">
                <div class="placeholderimg"></div>
                <div class="prod-desc bg-white">
                  <span class="placeholder sml col-4 mb-3"></span>
                  <span class="placeholder sml mb-1"></span>
                  <span class="placeholder sml"></span>
                  <div class="d-flex justify-content-between">
                    <span class="placeholder col-5"></span>
                    <span class="placeholder col-5"></span>
                  </div>
                </div>
              </a>
            </div>
          </ng-container>

          <div class="carousel-cell pt-2" *ngFor="let item of Evidenza">
            <a class="d-flex flex-column card-s mr-3 ml-1"
              routerLink="{{(service.currentLang=='en'?'/en':'') + '/prodotto/' +item.Id}}">
              <div class="ribbon" *ngIf="item.Discount!=null && item.Discount!=0">
                <span>{{item.Discount}}{{item.IsPercentage==true?'%':'€'}}
                  <small>{{ "CategorieDettagli.section1.discount" | translate}}</small></span>
              </div>
              <!-- <img  loading="lazy"  src="assets/img/b.png" class="imgb" alt="brand"> -->
              <img [lazyLoad]="'assets/img/new.svg'" class="imgnew" alt="new" *ngIf="item?.NewWeb==true">
              <img [lazyLoad]="item?.urlImages[0]" *ngIf="item.urlImages?.length!=0 && item.urlImages!=null"
                alt="{{item.Title}}">
              <img [lazyLoad]="'assets/no-image.png'"
                *ngIf="!item.urlImages || item.urlImages?.length==0 || item.urlImages==null" alt="blacksmith italia">
              <div class="prod-desc">
                <span class="cat">{{item.Code}}</span>
                <span class="desc">{{item.Title}}</span>
                <div class="mt-auto m-price">
                  <h5 *ngIf="!item.StopSellWeb">
                    <ng-container *ngIf="item.haveChildren"> {{ "CategorieDettagli.section1.da" | translate
                      }}</ng-container>
                    {{item.Price | currency : "EUR" : "symbol" : "1.2-2" : "it" }}
                    <small class="soldp" *ngIf="item.Discount!=null && item.Discount!=0">{{item.OriginalPrice | currency
                      :
                      "EUR" : "symbol" :
                      "1.2-2" : "it" }}</small>
                    <span class="ml-3 text-nowrap">{{"CategorieDettagli.section1.iva" | translate }}</span>
                  </h5>
                  <h5 *ngIf="item.StopSellWeb">
                    {{'ProdDet.section1.richiediInformazioni' | translate}}
                  </h5>
                </div>
              </div>
              <div class="shop-btns">
                <button>
                  +
                </button>
              </div>
            </a>
          </div>
        </carousel>
      </div>
    </div>
  </div>
</section>
<section class="bg-section pt-2" *ngIf="disponible">
  <div class="container">
    <div class="d-flex align-items-center menu-cat mb-2" (click)="indietro()">
      <img src="assets/img/arrow-l.svg" class="mx-2" alt="arrow">
      <p class="back mb-0">{{'ProdDet.section1.back' | translate}}</p>
    </div>
    <div class="main-product">
      <!-- <img class="d-block mt-4 mx-auto rld" width="160" height="160" *ngIf="product_selected.length==0"
        src="assets/img/reload.gif" alt="loader horse"> -->

      <ng-container *ngIf="product_selected.length==0">
        <div class="flex-product">
          <div class="flex-images">
            <div class="position-relative">
              <div class="image-box img-box-1 border-0">
                <div class="placeholderimg fulll"></div>
              </div>
              <div class="imgs-p mt-2">
                <span class="placeholder image-box br-16 border-0"></span>
                <span class="placeholder image-box br-16 border-0"></span>
              </div>
            </div>
          </div>
          <div class="flex-desc">
            <div class="product-details">
              <span class="placeholder mt-4"></span>
              <div class="border-x mb-3 mt-3 mt-lg-5">
                <span class="placeholder my-2"></span>
              </div>
              <div class="border-x position-relative">
                <div class="prezzo mt-3">
                  <span class="placeholder sml col-3 d-block"></span>
                  <span class="placeholder sml col-6"></span>
                </div>
                <span class="placeholder sml col-4"></span>
              </div>
            </div>
          </div>
          <div class="flex-paga">
            <span class="placeholder mt-4"></span>
            <div class="separate my-3"></div>
            <div class="d-flex justify-content-between">
              <span class="placeholder col-5"></span>
              <span class="placeholder col-5"></span>
            </div>
            <span class="placeholder bttn mt-5"></span>
            <span class="placeholder bttn"></span>
          </div>
        </div>
        <div class="separate my-4"></div>
        <div class="pt-1">
          <div class="row">
            <div class="col-lg-9">
              <ul class="nav nav-tabs" id="myTab" role="tablist" style="border-color: #dee2e6;">
                <li class="nav-item">
                  <a class="nav-link active" style="border-color: #dee2e6;"><span class="placeholder sml col-6"
                      style="width: 100px;"></span></a>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <span class="placeholder mt-4 col-4"></span>
                <span class="placeholder sml mt-4 col-11"></span>
                <span class="placeholder sml mt-3 col-7"></span>
              </div>

            </div>
            <div class="col-lg-3">
              <table class="standard-table font11 text-center" *ngIf="IdCat==166">
                <tbody>
                  <tr>
                    <th colspan="3" class="font10 blu lh12 text-center BG-azzurrino"><span
                        class="placeholder sml"></span></th>
                  </tr>
                  <tr>
                    <th class="font10 blu lh12  text-center"><span class="placeholder sml"></span> </th>
                    <th class="font10 blu lh12 text-center"><span class="placeholder sml"></span></th>
                    <th class="font10 blu lh12 text-center"><span class="placeholder sml"></span></th>
                  </tr>
                  <tr>
                    <td><span class="placeholder sml"></span></td>
                    <td><b><span class="placeholder sml"></span></b></td>
                    <td><span class="placeholder sml"></span></td>
                  </tr>
                  <tr>
                    <td><span class="placeholder sml"></span></td>
                    <td><b><span class="placeholder sml"></span></b></td>
                    <td><span class="placeholder sml"></span></td>
                  </tr>
                  <tr>
                    <td><span class="placeholder sml"></span></td>
                    <td><b><span class="placeholder sml"></span></b></td>
                    <td><span class="placeholder sml"></span></td>
                  </tr>
                  <tr>
                    <td><b><span class="placeholder sml"></span></b></td>
                    <td><b><span class="placeholder sml"></span></b></td>
                    <td><b><span class="placeholder sml"></span></b></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ng-container>



      <div *ngIf="product_selected.length!=0">
        <div class="flex-product">
          <div class="flex-images">
            <div class="position-relative">
              <div (mouseleave)="zoomin=false" class="image-box img-box-1">
                <img [src]="myImgSrc" class="w-100 h-100" *ngIf="myImgSrc!=null&&myImgSrc.length!=0" id="myimage"
                  onmousemove="zoomin=true" (mouseenter)="zoomin=true;imageZoom('myimage', 'myresult')"
                  alt="{{product_selected.Title}}">
                <img [src]="'assets/no-image.png'" class="w-100 h-100" *ngIf="myImgSrc==null||myImgSrc.length==0"
                  id="myimage" alt="blacksmith italia">
                <div id="myresult" class="img-zoom-result" [ngStyle]="{'visibility': zoomin==true?'visible':'hidden'}">
                </div>
              </div>
              <div class="imgs-p mt-2">
                <img *ngFor="let img of padre?.urlImages" src="{{img}}" class="image-box br-16" alt="blacksmith italia"
                  (mouseover)="myImgSrc = img">
              </div>
            </div>
          </div>
          <div class="flex-desc">
            <div class="product-details">
              <div class="d-flex flex-wrap w-100 align-items-center justify-content-between">
              </div>
              <h1>{{product_selected.Title}}</h1>
              <div class="border-x mb-3 mt-3 mt-lg-5">
                <h6 class="m-0 mb-1" *ngIf="product_selected.SupplierCodeArt!=null">{{'ProdDet.section1.Cf' |
                  translate}}:
                  {{product_selected.SupplierCodeArt}}</h6>
                <h6 class="m-0 my-1">Code: {{product_selected.Code}}</h6>
              </div>
              <div class="border-x position-relative" *ngIf="!product_selected.StopSellWeb">
                <div class="ribbon pd" *ngIf="product_selected.Discount!=null && product_selected.Discount!=0">
                  <span *ngIf="product_selected.listChildren==null || product_selected.listChildren.length==0">
                    {{product_selected.Discount}}{{product_selected.IsPercentage==true?'%':'€'}}
                    <small>{{ "CategorieDettagli.section1.discount" | translate}}</small>
                  </span>
                  <span *ngIf="product_selected.listChildren!=null && product_selected.listChildren.length!=0">
                    <small>{{"CategorieDettagli.section1.offerta" | translate}}</small>
                  </span>
                </div>
                <div class="prezzo">
                  <ng-container *ngIf="ListChildren.length > 0 && padre.Code==product_selected.Code">
                    {{'ProdDet.section1.partire' | translate}}
                  </ng-container>
                  <h5 style="font-weight: 500" class="mt-3 mt-md-1">{{product_selected.Price | currency : "EUR" :
                    "symbol" : "1.2-2" : "it"
                    }}
                    <small class="soldp mr-2"
                      *ngIf="product_selected.Discount!=null && product_selected.Discount!=0">{{product_selected.OriginalPrice
                      | currency : "EUR" : "symbol" :
                      "1.2-2" : "it" }}</small>
                    <small *ngIf="product_selected.UmNetWeight=='CP'">{{'ProdDet.section1.coppia' |
                      translate}}</small>
                  </h5>
                </div>
                <small>{{'ProdDet.section1.iva-sped' | translate}}</small>
              </div>
            </div>
          </div>


          <div class="flex-paga">
            <div class="d-flex flex-wrap justify-content-between">
              <img src="assets/img/new.svg" class="imgnew" alt="new" *ngIf="product_selected.NewWeb==true">
              <span></span>
              <ul class="d-flex mb-3 justify-content-between w-100 align-items-center">
                <li class="mr-auto">{{'Condividi' | translate}}</li>
                <li class="mr-2">
                  <a (click)="social('facebook')"><img src="assets/img/facebook.svg" alt="facebook" width="32"></a>
                </li>
                <li class="mr-2">
                  <a (click)="social('whatsapp')"><img src="assets/img/whatsapp.png" alt="whatsapp" width="32"></a>
                </li>
                <!-- <li class="mr-2">
                  <a (click)="social('pinterest')"><img src="assets/img/pinterest.svg" alt="pinterest" width="32"></a>
                </li>
                <li class="mr-2">
                  <a (click)="social('twitter')"><img src="assets/img/twitter.svg" alt="twitter" width="32"></a>
                </li>
                <li class="mr-2">
                  <a (click)="social('linkedin')"><img src="assets/img/linkedin.svg" alt="linkedin" width="32"></a>
                </li> -->
              </ul>
            </div>

            <ng-container *ngIf="filtriBkp.length!=0">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" *ngFor="let item of filtri;index as i">
                  <a class="nav-link" [class.active]="selectedtab==item.Description" id="{{item.Description}}-tab"
                    data-toggle="tab" href="#{{item.Description}}99" role="tab"
                    (click)="changeTabfiltri(item.Description)">{{item.Description | lowercase}}</a>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <ng-container *ngFor="let f of filtri;index as i">
                  <div class="tab-pane fade show" [class.active]="i == 0" id="{{f.Description}}99" role="tabpanel"
                    aria-labelledby="{{f.Description}}-tab">
                    <select class="mb-2" [ngClass]="{'font-b': ifSelected(item.Description)}"
                      *ngFor="let item of getOrderedfiltri(f.Description);index as i"
                      [ngModel]="getSelectedfromsearch(item.Description)"
                      (change)="selectFiltri(item.Description,$event.target.value,i)" [disabled]="i>filtred.length">
                      <option value="null">{{'ProdDet.section1.selMis' | translate}} {{item.Description | lowercase }}
                      </option>
                      <ng-container *ngFor="let v of item.values">
                        <option value="{{v}}">{{item.Description | lowercase}} <small>{{v}}</small>
                        </option>
                      </ng-container>
                    </select>
                  </div>
                </ng-container>
              </div>
            </ng-container>

            <div class="d-flex align-items-center mt-2 justify-content-between">
              <h6 class="mr-3 mb-2 bb">{{'ProdDet.section1.qnt' | translate}}</h6>
              <div class="btn-count-con mb-2">
                <button mat-button class="btn-count px-0" [disabled]="qte==1"
                  onclick="this.parentNode.querySelector('input[type=number]').stepDown()" (click)="qte>1?qte=qte-1:''">
                  -
                </button>
                <input class="t-count" min="1" value="1" name="qte" type="number" [(ngModel)]="qte">
                <button mat-button class="btn-count px-0"
                  onclick="this.parentNode.querySelector('input[type=number]').stepUp()" (click)="qte=qte+1">
                  +
                </button>
              </div>
            </div>

            <ng-container *ngIf="!product_selected.StopSellWeb">
              <button class="btn btn-bg px-3 my-3" style="max-width:360px" (click)="addToCart(product_selected)"
                [disabled]="openPriceChild==false && filtriBkp.length!=0">
                {{'ProdDet.section1.add-cart' | translate}}</button>

              <button class="btn btn-w-border px-3 my-3" style="max-width:360px"
                (click)="addTowishlist(product_selected.Idchild==undefined?product_selected.Id:product_selected.Idchild,product_selected.IsMyFavourite==false?true:false)"
                [disabled]="openPriceChild==false && filtriBkp.length!=0">
                {{(product_selected.IsMyFavourite==false?'ProdDet.section1.add-pref':'ProdDet.section1.remove-pref') |
                translate}}
                <img
                  [src]="product_selected.IsMyFavourite==false?'assets/img/wishlist.svg':'assets/img/wishlist-red.svg'"
                  [height]="product_selected.IsMyFavourite==false?18:22" class="ml-2" alt="wishlist">
              </button>
            </ng-container>
            <ng-container *ngIf="product_selected.StopSellWeb">
              <button class="btn btn-bg px-3 my-3" style="max-width:360px" (click)="modalform=true">
                {{'ProdDet.section1.richiediInformazioni' | translate}}</button>
            </ng-container>

            <div class="alert alert-danger" *ngIf="errorLogin">
              {{'ProdDet.section1.error-lista-login' | translate}}
            </div>
            <div class="alert alert-danger" *ngIf="ErrorFav==true && product_selected.IsMyFavourite==true">
              {{'ProdDet.section1.addwishlisterror' | translate}}
            </div>
            <div class="alert alert-success" *ngIf="msgsuccessFav==true && product_selected.IsMyFavourite==true">
              {{'ProdDet.section1.addwishlistsuccess' | translate}}
            </div>
            <div class="alert alert-danger" *ngIf="ErrorFav==true && product_selected.IsMyFavourite==false">
              {{"wishlist.remove-error" | translate}}
            </div>
            <div class="alert alert-success" *ngIf="msgsuccessFav==true && product_selected.IsMyFavourite==false">
              {{"wishlist.remove-success" | translate}}
            </div>


          </div>
        </div>
        <div class="separate my-4" *ngIf="product_selected.Description!=null || filtriBkp.length==2">
        </div>
        <div class="pt-1" *ngIf="product_selected.Description!=null || filtriBkp.length==2 || listLogisticChildren?.length!=0">
          <div class="row">
            <div class="col-lg-9">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" *ngIf="product_selected.Description!=null">
                  <a class="nav-link active" id="home-tab" data-toggle="tab" href="#desc" role="tab" (click)="clearBM()"
                    aria-controls="home" aria-selected="true"> {{'ProdDet.section1.descp' |
                    translate}}</a>
                </li>
                <li class="nav-item" *ngIf="filtriBkp.length==2&& product_selected.GroupID!=114">
                  <a class="nav-link" id="profile-tab" data-toggle="tab" href="#misure" role="tab"
                    aria-controls="profile" aria-selected="false">{{filtriBkp[0].Description | lowercase}} /
                    {{filtriBkp[1].Description | lowercase}}</a>
                </li>
                <li class="nav-item" *ngIf="listLogisticChildren?.length!=0">
                  <a class="nav-link" [ngClass]="{'active': product_selected.Description==null}" id="LogisticData-tab" data-toggle="tab" href="#LogisticData" role="tab"
                    aria-controls="logistic" aria-selected="true">{{'ProdDet.section1.Misure' |
                    translate}}</a>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="desc" role="tabpanel" aria-labelledby="home-tab"
                  *ngIf="product_selected.Description!=null">
                  <p class="tit mb-4">{{'ProdDet.section1.desc-prod' | translate}}</p>
                  <div style="color: #000!important;" [innerHTML]="product_selected.Description"></div>
                </div>
                <div class="tab-pane fade" id="misure" *ngIf="filtriBkp.length==2 && product_selected.GroupID!=114">
                  <h6 class="mb-4">{{'ProdDet.section1.dep' | translate}}</h6>
                  <div style="width:100%;overflow-x: auto;">
                    <table class="standard-table text-center">
                      <tbody>
                        <tr>
                          <th class="font10 blu lh12">{{filtriBkp[0].Description | lowercase}} /
                            {{filtriBkp[1].Description | lowercase}}</th>
                          <th class="font10 blu lh12" *ngFor="let misura of filtriBkp[1].values">{{misura}}</th>
                        </tr>
                        <tr *ngFor="let binda of filtriBkp[0].values">
                          <td>{{binda}}</td>
                          <td *ngFor="let misura of filtriBkp[1].values">
                            <img src="assets/img/check22.svg" width="25px" style="cursor:pointer" alt="check"
                              (click)="changeTabfiltri(filtriBkp[0].Description);selectFiltri(filtriBkp[0].Description,binda);selectFiltri(filtriBkp[1].Description, misura)"
                              *ngIf="check(misura,binda)==true">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="tab-pane fade" [ngClass]="{'active': product_selected.Description==null,'show': product_selected.Description==null}" id="LogisticData" aria-labelledby="LogisticData-tab">
                  <table class="standard-table font11 text-center">
                    <thead>
                      <tr>
                        <th>{{product_selected.SizeTypeDescription}}</th>
                        <th>{{'ProdDet.section1.Larghezza' | translate}}</th>
                        <th>{{'ProdDet.section1.lunghezza' | translate}}</th>
                        <th *ngIf="!ThicknessAreAllEmpty">{{'ProdDet.section1.Spessore' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of listLogisticChildren">
                        <td>{{item.Size}}</td>
                        <td>{{item.Width}}</td>
                        <td>{{item.Length}}</td>
                        <td *ngIf="!ThicknessAreAllEmpty">{{item.Thickness}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
            <div class="col-lg-3">
              <table class="standard-table font11 text-center" *ngIf="IdCat==166">
                <tbody>
                  <tr>
                    <th colspan="3" class="font10 blu lh12 text-center BG-azzurrino">{{'ProdDet.section1.comp' |
                      translate}}</th>
                  </tr>
                  <tr>
                    <th class="font10 blu lh12  text-center">{{'ProdDet.section1.it' | translate}} </th>
                    <th class="font10 blu lh12 text-center">{{'ProdDet.section1.ol' | translate}} </th>
                    <th class="font10 blu lh12 text-center">{{'ProdDet.section1.ted' | translate}} </th>
                  </tr>
                  <tr>
                    <td style="color: #ff000091">x</td>
                    <td><b>7x0</b></td>
                    <td style="color: #ff000091">x</td>
                  </tr>
                  <tr>
                    <td style="color: #ff000091">x</td>
                    <td><b>6x0</b></td>
                    <td style="color: #ff000091">x</td>
                  </tr>
                  <tr>
                    <td style="color: #ff000091">x</td>
                    <td><b>5x0</b></td>
                    <td style="color: #ff000091">x</td>
                  </tr>
                  <tr>
                    <td><b>1</b></td>
                    <td><b>4x0</b></td>
                    <td><b>2x0</b></td>
                  </tr>
                  <tr>
                    <td><b>2</b></td>
                    <td><b>3x0</b></td>
                    <td><b>0</b></td>
                  </tr>
                  <tr>
                    <td><b>3</b></td>
                    <td><b>2x0</b></td>
                    <td><b>1</b></td>
                  </tr>
                  <tr>
                    <td><b>4</b></td>
                    <td><b>0</b></td>
                    <td><b>2</b></td>
                  </tr>
                  <tr>
                    <td><b>5</b></td>
                    <td><b>1</b></td>
                    <td><b>3</b></td>
                  </tr>
                  <tr>
                    <td><b>6</b></td>
                    <td><b>2</b></td>
                    <td><b>4</b></td>
                  </tr>
                  <tr>
                    <td><b>7</b></td>
                    <td><b>3</b></td>
                    <td><b>5</b></td>
                  </tr>
                  <tr>
                    <td><b>8</b></td>
                    <td><b>4</b></td>
                    <td><b>6</b></td>
                  </tr>
                  <tr>
                    <td><b>9</b></td>
                    <td><b>5</b></td>
                    <td><b>7</b></td>
                  </tr>
                  <tr>
                    <td style="color: #ff000091">x</td>
                    <td><b>6</b></td>
                    <td style="color: #ff000091">x</td>
                  </tr>
                  <tr>
                    <td style="color: #ff000091">x</td>
                    <td><b>7</b></td>
                    <td style="color: #ff000091">x</td>
                  </tr>
                  <tr>
                    <td style="color: #ff000091">x</td>
                    <td><b>8</b></td>
                    <td style="color: #ff000091">x</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="bg-sec" *ngIf="loadProductSimili==true && disponible">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h4 class="title mb-5">{{'ProdDet.section1.prod-sim' | translate}}</h4>
        <!-- <img class="d-block mt-4 mx-auto rld" width="160" height="160" *ngIf="loadProductSimili==true"
          src="assets/img/reload.gif" alt="loader horse"> -->
        <carousel [lightDOM]="'true'" [height]="390" [cellWidth]="290" class="mt-4" *ngIf="showcarousel">
          <ng-container *ngIf="loadProductSimili==true">
            <div class="carousel-cell pt-2" *ngFor="let item of fakelist">
              <a class="d-flex flex-column card-s" style="border:none !important">
                <div class="placeholderimg"></div>
                <div class="prod-desc bg-white">
                  <span class="placeholder"></span>
                  <span class="placeholder sml"></span>
                  <div class="d-flex justify-content-between">
                    <span class="placeholder col-5"></span>
                    <span class="placeholder col-5"></span>
                  </div>
                </div>
              </a>
            </div>
          </ng-container>
        </carousel>
      </div>
    </div>
  </div>
</section>
<section class="bg-sec" *ngIf="product_similar.length!=0  && loadProductSimili==false && disponible">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h4 class="title mb-5">{{'ProdDet.section1.prod-sim' | translate}}</h4>
        <!-- <img class="d-block mt-4 mx-auto rld" width="160" height="160" *ngIf="loadProductSimili==true"
          src="assets/img/reload.gif" alt="loader horse"> -->
        <carousel [lightDOM]="'true'" [height]="390" [cellWidth]="290" class="mt-4" *ngIf="showcarousel">
          <ng-container *ngFor="let ps of product_similar">
            <div class="carousel-cell pt-2">
              <a class="d-flex flex-column card-s mr-3"
                routerLink="{{(service.currentLang=='en'?'/en':'') + '/prodotto/'+ ps.Id}}">
                <div class="ribbon" *ngIf="ps.Discount!=null && ps.Discount!=0">
                  <span>{{ps.Discount}}{{ps.IsPercentage==true?'%':'€'}}
                    <small>{{ "CategorieDettagli.section1.discount" | translate}}</small></span>
                </div>
                <!-- <img src="assets/img/b.png" class="imgb"> -->
                <img src="{{ps?.urlImages[0]}}" *ngIf="ps?.urlImages!=null && ps?.urlImages.length!=0"
                  alt="{{ps.Title}}">
                <img src="assets/no-image.png" *ngIf="ps?.urlImages==null || ps?.urlImages.length==0"
                  alt="blacksmith italia">
                <div class="px-3">
                  <span class="desc mx-2 my-3" style="height: 56px !important;">{{ps.Title}}</span>
                  <div class="m-price mb-3">
                    <h5 *ngIf="!ps.StopSellWeb">
                      <ng-container *ngIf="ps.haveChildren"> {{ "CategorieDettagli.section1.da" | translate
                        }}</ng-container>
                      {{ps.Price | currency : "EUR" : "symbol" : "1.2-2" : "it" }}
                      <small class="soldp" *ngIf="ps.Discount!=null && ps.Discount!=0">{{ps.OriginalPrice | currency :
                        "EUR" : "symbol" :
                        "1.2-2" : "it" }}</small>
                      <span class="ml-3 text-nowrap">{{"CategorieDettagli.section1.iva" | translate }}</span>
                    </h5>
                    <h5 *ngIf="ps.StopSellWeb">
                      {{'ProdDet.section1.richiediInformazioni' | translate}}
                    </h5>
                  </div>
                </div>
                <div class="shop-btns">
                  <button>
                    +
                  </button>
                </div>
              </a>
            </div>
          </ng-container>
        </carousel>
      </div>
    </div>
  </div>
</section>


<form class="main-carrello modal-carrello" *ngIf="modalform==true" style="background-color: #f1f2ff">
  <h6>{{'ProdDet.section1.richiediInformazioni'|translate}}</h6>
  <div class="d-flex mt-3">
    <input type="email" name="nome" placeholder="{{'Pagamento.section1.nome' | translate}}" class="mr-1"
      required="required" [(ngModel)]="nome">
    <input type="text" name="cognome" placeholder="{{'Pagamento.section1.cognome' | translate}}" class="ml-1"
      required="required" [(ngModel)]="cognome">
  </div>
  <div class="d-flex align-items-baseline">
    <div class="w-100">
      <input type="tel" name="email" placeholder="{{'Pagamento.section1.email' | translate}}" class="mr-1 mb-0"
        required="required" [(ngModel)]="email">
      <small *ngIf="validateEmail(email)==false && email!=''" class="text-danger ml-2 mb-2 d-block">inserire un
        indirizzo mail valido</small>
    </div>
    <input type="text" name="tel" placeholder="{{'Pagamento.section1.tel' | translate}}" class="ml-1"
      required="required" [(ngModel)]="tel">
  </div>
  <div class="row">
    <div class="col-lg-12">
      <textarea name="msg" placeholder="{{'Pagamento.section1.Latuarichiesta' | translate}}" required="required"
        [(ngModel)]="msg"></textarea>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-8 offset-lg-2">
      <button class="btn btn-bg px-3 mt-1 mb-2" type="submit" style="max-width:100%" (click)="SendMsg()">
        {{'ProdDet.section1.inviaMessaggio' | translate}}</button>
    </div>
    <div class="col-lg-12">
      <div class="alert alert-success mt-3 mb-0" role="alert" *ngIf="resultEmail.result==1">
        {{'ProdDet.section1.msgsuccess' | translate}}
      </div>
      <div class="alert alert-danger mt-3 mb-0" role="alert" *ngIf="resultEmail.result==0">
        {{'ProdDet.section1.msgerror' | translate}}
      </div>
    </div>
  </div>
</form>
<div class="modal-carrello-bg" *ngIf="modalform==true"
  (click)="modalform=false;resultEmail=[];nome='';cognome='';tel='';email='';msg='';"></div>


<div itemscope itemtype="http://schema.org/Product"
  style="position: absolute;bottom: 0;visibility: hidden;z-index: -10;">
  <span itemprop="name">{{ product_selected.Title }}</span>
  <img itemprop="image" src="{{ myImgSrc }}" />
  <!-- <span itemprop="description">{{ product.description }}</span> -->
  <div itemprop="offers" itemscope itemtype="http://schema.org/Offer">
    <span itemprop="priceCurrency">EUR</span>
    <span itemprop="price">{{ product_selected.Price }}</span>
    <link itemprop="availability" href="http://schema.org/InStock" />
    <span itemprop="availability">disponible</span>
  </div>
  <!-- Altri dati strutturati come recensioni, marchio, etc. -->
</div>
