import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../services/service.service';

import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css']
})
export class ResultComponent implements OnInit {

  constructor(private route: ActivatedRoute,private router: Router,private service:ServiceService,@Inject(PLATFORM_ID) private platformId: Object) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  search='';
  products:any=[];
  newproduct:any=[];
  loader:boolean=false;
  searched='';
  orderPriceAsc:boolean=false;
  orderPriceDesc:boolean=false;
  brandSelected='null';
  totalProducts: number = 0;

  ngOnInit(): void {
    this.search = this.route.snapshot.paramMap.get('search');
    this.GetProducts();
  }


  GetProducts(){
    this.loader=true;
    this.service.getProducts('', -1, -1, this.search, this.brandSelected,null, this.orderPriceDesc, this.orderPriceAsc,-1).pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      products => {
        this.products = products;
        this.totalProducts = this.products.length;
        if(this.totalProducts==1){
          if(this.products[0].Code!=this.search && this.products[0].haveChildren==true){
            this.service.childId=this.search;
          }
          this.router.navigateByUrl('/prodotto/' + this.products[0].Id);
        }
        this.loader=false;
      });
  }


}
