import { NgModule } from '@angular/core';
import { BrowserModule, TransferState } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SideNavService } from './services/side-nav.service';
import { MenuModule } from './menu/menu.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderModule } from './header/header.module';
import { FooterModule } from './footer/footer.module';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { MetatagsService } from './services/metatags.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader,TranslateService  } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateCacheModule, TranslateCacheSettings, TranslateCacheService } from 'ngx-translate-cache';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { translateBrowserLoaderFactory } from './services/translate-browser.loader';
import { BrandComponent } from './brand/brand.component';

import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { CategoryDetailsComponent } from './category-details/category-details.component';
import { BrandsComponent } from './brands/brands.component';
import { HomeComponent } from './home/home.component';
import { CategoriesComponent } from './categories/categories.component';
import { ProductsComponent } from './products/products.component';
import { ProductsDetailsComponent } from './products-details/products-details.component';
import { RegisterModule } from './register/register.module';
import { PagamentoModule } from './pagamento/pagamento.module';
import { ProfileModule } from './profile/profile.module';
import { AccountModule } from './account/account.module';
import { CarrelloModule } from './carrello/carrello.module';
import { ResultModule } from './result/result.module';
import { EducationalModule } from './educational/educational.module';
import { AziendaModule } from './azienda/azienda.module';
import { ContactModule } from './contact/contact.module';
import { NewsModule } from './news/news.module';
import { CondizioniModule } from './condizioni-di-spedizione/condizioni.module';
import { ModalitaModule } from './modalita-di-pagamento/modalita.module';
import { PrivacyModule } from './privacy/privacy.module';
import { SuccessModule } from './result-success/success.module';
import { ErrorModule } from './result-error/error.module';
import { CookieModule } from './cookie-policy/cookie.module';
import { ResoModule } from './reso-prodotti/reso.module';
import { ServizioModule } from './servizio-clienti/servizio.module';
import { TerminiModule } from './termini-condizioni/termini.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { WishlistComponent } from './wishlist/wishlist.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { ArticleComponent } from './article/article.component';
registerLocaleData(localeIt, 'it');

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export function TranslateCacheFactory(translateService, translateCacheSettings) {
  return new TranslateCacheService(translateService, translateCacheSettings);
}

@NgModule({
  declarations: [
    AppComponent,
    BrandsComponent,
    ProductsComponent,
    HomeComponent,
    CategoriesComponent,
    ProductsDetailsComponent,
    BrandComponent,
    CategoryDetailsComponent,
    WishlistComponent,
    ArticleComponent,
    SitemapComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    MatSidenavModule,
    MatProgressBarModule,
    MenuModule,
    BrowserAnimationsModule,
    CondizioniModule,
    HeaderModule,
    RegisterModule,
    ContactModule,
    SuccessModule,
    ServizioModule,
    TerminiModule,
    CookieModule,
    ErrorModule,
    ResoModule,
    NewsModule,
    AziendaModule,
    PrivacyModule,
    CarrelloModule,
    EducationalModule,
    ModalitaModule,
    ResultModule,
    FooterModule,
    AccountModule,
    PagamentoModule,
    ProfileModule,
    MatRadioModule,
    IvyCarouselModule,
    LazyLoadImageModule,
    TranslateModule.forRoot({
      // loader: {
      //     provide: TranslateLoader,
      //     useFactory: (createTranslateLoader),
      //     deps: [HttpClient]
      // }
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState]
      }
  }),
  TranslateCacheModule.forRoot({
    cacheService: {
      provide: TranslateCacheService,
      useFactory: (translateService, translateCacheSettings) => {
          return new TranslateCacheService(translateService, translateCacheSettings)
      },
      deps: [ TranslateService, TranslateCacheSettings ]
    }
  })
  ],
  providers: [SideNavService, MetatagsService,{provide: LocationStrategy, useClass: PathLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
