import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FooterRoutingModule } from './footer-routing.module';
import { FooterComponent } from './footer.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [
    FooterComponent
  ],
  imports: [
    TranslateModule,
    CommonModule,
    FooterRoutingModule,
    LazyLoadImageModule
  ],
  exports:[
    FooterComponent
  ]
})
export class FooterModule { }
