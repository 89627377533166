<section class="bg-sec bg-section" *ngIf="Groups.length==0">
  <div class="container">
    <span class="placeholder mb-5"></span>
    <div class="box">
      <span class="placeholder col-6 mb-3"></span>
      <span class="placeholder col-10 mb-3"></span>
      <span class="placeholder col-4 mb-4"></span>

      <carousel [lightDOM]="'true'" [height]="400" [cellWidth]="290" [dots]="false" [arrows]="true"
        [arrowsOutside]="false" [loop]="true" class="mt-5 couselx" *ngIf="showcarousel">
        <div class="carousel-cell pt-2" *ngFor="let item of fakelist">
          <a class="d-flex flex-column card-s" style="border:none !important">
            <div class="placeholderimg"></div>
            <div class="prod-desc bg-white">
              <span class="placeholder sml mt-1 mb-2"></span>
              <span class="placeholder" style="margin-bottom: 37px"></span>
              <div class="d-flex justify-content-between">
                <span class="placeholder col-5"></span>
                <span class="placeholder col-5"></span>
              </div>
            </div>
          </a>
        </div>
      </carousel>
    </div>
  </div>
</section>

<section *ngFor="let g of Groups;let i = index" class="bg-sec" [ngClass]="{'bg-section':isPrime(i)==false}">
  <div class="container">
    <h1 class="title-bg mb-5" *ngIf="i==0">{{ "Categorie.section1.title" | translate }}</h1>
    <div class="box">
      <div class="d-flex align-items-start flex-wrap flex-xl-nowrap">
        <div class="mr-0 mr-md-4">
          <h4 class="title">{{g.Description}}</h4>
          <p class="my-3" [innerHTML]="g.DescriptionWeb">
          </p>
        </div>
        <a class="btn btn-w mb-3 mb-md-5 mb-xl-0 ml-auto"
          routerLink="{{(service.currentLang === 'en' ? '/en/categoria/' : '/categoria/') + g.Id}}">
          {{ "Categorie.section1.title" | translate }}
          <img loading="lazy" src="assets/img/arrow-r.svg" width="10" class="ml-3" alt="arrow-r">
        </a>
      </div>
      <app-products [Id]="g.Id" [nameCatv]="g.Description"></app-products>
    </div>
  </div>
</section>
