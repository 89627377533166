<section class="bg-grey-page py-3 py-md-4" style="min-height: 75vh;">
  <div class="container">
    <h4 class="title mt-2 mb-4">{{ "wishlist.title" | translate }}</h4>
    <div class="row">
      <div class="col-md-12 col-lg-10 offset-lg-1">
        <div class="main-carrello">
          <div class="d-flex flex-wrap">
            <ng-container *ngIf="loading==false">
              <div *ngFor="let item of favorites; let i = index" class="carello-box">
                <div>
                  <img loading="lazy" *ngIf="item.urlImages!=null" src="{{item.urlImages[0]}}">
                  <img loading="lazy" *ngIf="item.urlImages==null" src="assets/no-image.png">
                </div>
                <!-- <i>{{item.Code}}</i> -->
                <div style="flex:1">
                  <h6 (click)="goToProduct(item)" class="cursor-pointer" style="text-decoration: underline">
                    {{item.Title}}</h6>
                  <div class="d-flex align-items-baseline flex-align-base">
                    <ng-containe *ngFor="let x of item.listExtraDatas">
                      <h5 class="s-tit mr-2"><b>{{x.Description}}</b> {{x.Value}}</h5>
                    </ng-containe>
                  </div>
                </div>

                <div class="ml-0 ml-md-3 mt-3 mt-md-0 position-relative ww">
                  <div class="d-flex justify-content-between justify-content-lg-center  align-items-center box-price">
                    <!-- {{ "Carrello.section1.text2" | translate }} -->
                    <div class="prezzo-c"><b class="mr-2">{{item.Price | currency : "EUR" : "symbol" : "1.2-2" : "it" }}</b>
                      <small class="soldp" *ngIf="item.Discount!=null && item.Discount!=0">{{item.OriginalPrice | currency : "EUR" : "symbol" :
                        "1.2-2" : "it" }}</small>
                    </div>
                    <!-- <div class="ml-2 bg-red" *ngIf="item.Discount!=null && item.Discount!=0"><span>-{{item.Discount}}{{item.IsPercentage==true?'%':'€'}}</span></div> -->
                  </div>
                  <div class="d-flex justify-content-between mt-2">
                    <button class="btn btn-w-border small mr-2" (click)="openAcquisto=item.Id">
                      <img src="assets/img/carrello.svg" class="delete" width="25px">
                      {{'ProdDet.section1.Acquista' | translate}}
                    </button>
                    <button class="btn btn-bg btndangerw small" (click)="removefavorites(item.Id)">
                      <img src="assets/img/delete.svg" class="delete" width="25px">
                      {{ 'Profile.indirizzi.rimuovi' | translate}}
                    </button>
                  </div>


                <div *ngIf="openAcquisto==item.Id" class="acq">
                  <div class="btn-count-con">
                    <button class="btn-count px-0 ml-1" [disabled]="qte==1"
                      (click)="qte=qte-1">-</button>
                    <input class="t-count mx-1" min="1" value="{{qte}}" [(ngModel)]="qte">
                    <button class="btn-count px-0" (click)="qte=qte+1">+</button>
                  </div>
                  <div class="d-flex justify-content-between mt-2">
                    <button class="btn btn-bg small mr-2" (click)="addToCarrello(item)">
                      {{'ProdDet.section1.Acquista' | translate}}
                    </button>
                    <button class="btn btn-bg btndangerw small" (click)="openAcquisto='';qte=1">
                      {{ 'Account.section1.annulla' | translate}}
                    </button>
                  </div>
                </div>
                </div>
              </div>
            </ng-container>
            <p *ngIf="(favorites.length==0 || favorites==null || favorites==undefined) && loading==false" class="my-4">{{
              "wishlist.no-products" | translate }}</p>
            <img loading="lazy" class="d-block my-4 mx-auto" width="120" height="120" *ngIf="loading==true"
              src="assets/img/reload.gif" alt="loader horse">
              <div class="separate" *ngIf="favorites.length!=0 && favorites!=null && favorites!=undefined"></div>
              <button class="btn btn-dangerx mt-2 ml-auto mb-3 mb-md-0" (click)="clearLista()"
                *ngIf="favorites.length!=0 && favorites!=null && favorites!=undefined">{{ "wishlist.clearwishlist" | translate }}</button>
          </div>
          <div class="alert alert-danger mt-2" *ngIf="Error==true">
            {{"wishlist.remove-error" | translate}}
          </div>
          <div class="alert alert-success mt-2" *ngIf="msgsuccess==true">
            {{"wishlist.remove-success" | translate}}
          </div>
          <div class="alert alert-success mt-2" *ngIf="sendtocarrello==true">
            {{"menu.m8" | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
