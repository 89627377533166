import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ModalitaRoutingModule } from './modalita-routing.module';
import { ModalitaDiPagamentoComponent } from './modalita-di-pagamento.component';

@NgModule({
  declarations: [
    ModalitaDiPagamentoComponent
  ],
  imports: [
    TranslateModule,
    CommonModule,
    ModalitaRoutingModule,
  ],
})
export class ModalitaModule { }
