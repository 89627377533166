<section class="bg-grey-page py-3 py-md-4" style="min-height: 75vh;">
  <div class="container">
    <h4 class="title mb-4">{{ "Carrello.section1.title" | translate }}</h4>
    <div class="row">
      <div class="col-lg-9">
        <div class="main-carrello">
          <div class="mb-4 d-flex flex-wrap align-items-start">
            <div *ngFor="let item of product; let i = index" class="carello-box">
              <div>
                <img loading="lazy" *ngIf="item.urlImages!=null" src="{{item.urlImages}}">
                <img loading="lazy" *ngIf="item.urlImages==null" src="assets/no-image.png">
              </div>
              <div class="product-carrello mx-0 mx-md-3" style="flex:1">
                <!-- <i>{{item.Code}}</i> -->
                <h6 (click)="goToProduct(item)" style="text-decoration: underline;" class="cursor-pointer">
                  {{item.Title}}</h6>
                <div class="d-flex align-items-baseline flex-align-base">
                  <ng-containe *ngFor="let x of item.listExtraDatas">
                    <h5 class="s-tit mr-2"><b>{{ x.Description }}</b> {{x.Value}}</h5>
                  </ng-containe>
                </div>
              </div>
              <div class="ww">
                <div class="d-flex flex-wrap my-2 ww justify-content-between">
                  <div class="btn-count-con mr-2">
                    <button class="btn-count px-0 ml-1" [disabled]="item.qte==1"
                      (click)="qte=item.qte-1;changeQte(item,-1)">-</button>
                    <input class="t-count mx-1" min="1" value="{{item.qte}}" [(ngModel)]="item.qte">
                    <button class="btn-count px-0" (click)="qte=item.qte+1;changeQte(item,+1)">+</button>
                  </div>
                  <button class="btn btn-bg btndangerw small" (click)="removeProduct(item)">
                    <img src="assets/img/delete.svg" class="delete" width="25px">
                    {{ 'Profile.section1.Elimina' | translate}}
                  </button>
                </div>
                <div class="box-price">
                  <!-- {{ "Carrello.section1.text2" | translate }} -->
                  <div class="prezzo-c">
                    <b>{{item.Price * item.qte | currency : "EUR" : "symbol" : "1.2-2" : "it" }}</b>
                    <small class="soldp ml-2" *ngIf="item.Discount!=null && item.Discount!=0">{{item.OriginalPrice* item.qte  |
                      currency : "EUR" : "symbol" :
                      "1.2-2" : "it" }}</small>
                  </div>
                </div>
              </div>
            </div>
            <p *ngIf="product.length==0 || product==null || product==undefined">{{ "Carrello.section1.no-prod" |
              translate }}</p>
          </div>
          <div class="separate"></div>
          <button class="btn btn-dangerx mt-2 ml-auto mb-3 mb-md-0" (click)="clearCarrello()"
            *ngIf="product.length!=0 && product!=null">{{ "Carrello.section1.svuota-carrello" | translate }}</button>
          <em>
            <h6>{{ "Carrello.section1.text3" | translate }}</h6>
          </em>
          <p class="mt-3">{{ "Carrello.section1.text4" | translate }}</p>
          <div class="d-flex mt-4">
            <img loading="lazy" src="assets/img/paypal.png" height="50" alt="paypal">
            <img loading="lazy" src="assets/img/visa.png" height="50" alt="visa">
            <img loading="lazy" src="assets/img/mastercard.png" height="50" alt="mastercard">
          </div>
        </div>
      </div>
      <div class="col-lg-3" *ngIf="product.length!=0">
        <div class="main-carrello tot">
          <div class="product-carrello">
            <h6 class="m-0"></h6>
            <div class="d-flex justify-content-between">
              <p>{{ "Carrello.section1.subtotal" | translate }}</p>
              <p>{{TotalCarrello + TotalSconte | currency : "EUR" : "symbol" : "1.2-2" : "it" }}</p>
            </div>
            <div class="d-flex justify-content-between" *ngIf="TotalSconte!=0">
              <p>{{ "Carrello.section1.subtotalsconto" | translate }}</p>
              <p class="text-success">-{{TotalSconte | currency : "EUR" : "symbol" : "1.2-2" : "it" }}</p>
            </div>
            <div class="separate mt-2"></div>
            <div class="d-flex justify-content-between">
              <p><b class="d-block mb-2 bolder">{{ "Carrello.section1.tot" | translate }}</b>{{ "Carrello.section1.iva"
                | translate }} </p>
              <p class="bolder">{{TotalCarrello | currency : "EUR" : "symbol" : "1.2-2" : "it" }}</p>
            </div>
            <button class="btn btn-bg mt-4" (click)="concludiOrdine()">{{ "Carrello.section1.proc" |
              translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
