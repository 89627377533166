import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ServizioRoutingModule } from './servizio-routing.module';
import { ServizioClientiComponent } from './servizio-clienti.component';

@NgModule({
  declarations: [
    ServizioClientiComponent
  ],
  imports: [
    TranslateModule,
    CommonModule,
    ServizioRoutingModule,
  ],
})
export class ServizioModule { }
