import { Component, Input, OnInit,OnDestroy, PLATFORM_ID,Inject } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {

  constructor(public service: ServiceService,@Inject(PLATFORM_ID) private platformId: Object) { }

  showcarousel: boolean = false;

  ngOnInit(): void {
    this.service.GroupsList.subscribe(data => this.Groups = data);
    if (isPlatformBrowser(this.platformId)) {
      this.showcarousel=true;
    }
  }

  Groups: any = [];

  isPrime(num) {
    if (num % 2 == 0) {
      return true
    } else {
      return false
    }
  }

  fakelist:any[] = new Array(9);

}
