
import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.css']
})
export class ArticleComponent implements OnInit {

  constructor(public service: ServiceService,private route: ActivatedRoute,private title: Title,private metaService: Meta) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.idBlog = params.get('id');
      if (this.idBlog!=null) {
        setTimeout(() => {
          this.GetBlogDetails();
        }, 500);
      }
    })
  }

  idBlog=null;

  detail:any=[];
  GetBlogDetails() {
    this.service.GetBlogDetails(this.idBlog).subscribe(
      res => {
        this.detail = res;
         //SEO
         this.title.setTitle(this.detail[0].Blog.Title);
         this.metaService.addTag({ name: 'title', content: this.detail[0].Blog.Title });
         this.metaService.addTag({ property: 'og:title', content: this.detail[0].Blog.Title });
         this.metaService.addTag({ name: 'description', content: this.detail[0].Blog.ShortDescription });
         this.metaService.addTag({ property: 'og:description', content: this.detail[0].Blog.ShortDescription });
         this.metaService.addTag({ name: 'image', content: this.detail[0].Blog.Base64 });
         this.metaService.addTag({ property: 'og:image', content: this.detail[0].Blog.Base64 });
         this.metaService.addTag({ property: 'og:url', content: this.url });
         // Fin SEO
      }
    )
  }

  url = window.location.href;

  social(a) {
    let pinterest = "http://pinterest.com/pin/create/button/" +
      "?url=" + this.url +
      "&media=" + this.detail[0].Blog.Base64 +
      "&description=" + this.detail[0].Blog.ShortDescription;
    let facebook = 'https://www.facebook.com/sharer/sharer.php?u=' + this.url;
    let twitter = 'https://twitter.com/share?text=' + this.detail[0].Blog.Title + '&url=' + this.url;

    let linkedin = 'https://www.linkedin.com/sharing/share-offsite/?url=' + this.url;
    let b = '';
    if (a === 'pinterest') {
      b = pinterest;
    }
    if (a === 'twitter') {
      b = twitter;
    }
    if (a === 'facebook') {
      b = facebook;
    }
    if (a === 'linkedin') {
      b = linkedin;
    }
    let params = `width=500,height=400,left=100,top=100`;
    window.open(b, a, params)
  }

  indietro() {
    window.history.back()
  }

}
