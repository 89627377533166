<mat-sidenav-container class="example-container" fullscreen [hasBackdrop]="false">
  <mat-sidenav #sidenav mode="over" class="example-sidenav">
    <app-menu (click)="sidenav.toggle()"></app-menu>
  </mat-sidenav>
  <mat-sidenav-content class="example-sidenav-content">
    <!-- <div class="avviso">
      <img src="assets/img/bgFerie.png" alt="" width="325" class="mr-3">
      <marquee>{{'avviso.1' | translate}} {{'avviso.2' | translate}} {{'avviso.3' | translate}}</marquee>
    </div> -->
    <app-header></app-header>
    <div [@myAnimation]="o.isActivated ? o.activatedRoute : ''" class="main">
      <mat-progress-bar *ngIf="loading" color="primary" mode="indeterminate" class="progress-bar-loader">
      </mat-progress-bar>
      <router-outlet #o="outlet"></router-outlet>
    </div>
    <app-footer></app-footer>
    <a href="https://api.whatsapp.com/send?phone=393341567169" target="_blank" id="whapp" title="Whatsapp" class="wtsp">
      <img src="assets/img/whatsapp.png" alt="wtsp blòackmsith" width="43">
    </a>
  </mat-sidenav-content>
</mat-sidenav-container>

