<section class="bg-section-grey" style="min-height: 75vh">
  <div class="container">
    <div class="row">
      <div class="col-lg-3">
        <ul class="list-group">
          <li class="list-group-item active" routerLink="/termini-condizioni">
            {{ "CondizioniSped.menu.m1" | translate }}
          </li>
          <li class="list-group-item" routerLink="/modalita-di-pagamento">
            {{ "CondizioniSped.menu.m2" | translate }}
          </li>
          <li class="list-group-item" routerLink="/condizioni-di-spedizione">
            {{ "CondizioniSped.menu.m3" | translate }}
          </li>
          <li class="list-group-item" routerLink="/reso-prodotti">
            {{ "CondizioniSped.menu.m4" | translate }}
          </li>
        </ul>
      </div>
      <div class="col-lg-9">
        <div class="main-carrello">
          <h1 class="title mb-4">{{ "CondizioniSped.menu.m1" | translate }}</h1>
            <h4 class="blu">{{ "TerminiCond.section1.h41" | translate }}</h4>
            <p>
              {{ "TerminiCond.section1.text" | translate }}
            </p>
            <h4 class="blu">{{ "TerminiCond.section1.h42" | translate }}</h4>
            <p>
              {{ "TerminiCond.section1.text2" | translate }}
            </p>
            <h4 class="blu">{{ "TerminiCond.section1.h43" | translate }}</h4>
            <p>
              {{ "TerminiCond.section1.text3" | translate }}
            </p>
            <h4 class="blu">{{ "TerminiCond.section1.h44" | translate }}</h4>
            <p> {{ "TerminiCond.section1.text4" | translate }}</p>
            <h4 class="blu">{{ "TerminiCond.section1.h45" | translate }}</h4>
            <p>
              {{ "TerminiCond.section1.text5" | translate }}
            </p>

        </div>
      </div>
    </div>
  </div>
</section>
