
<!-- <img  loading="lazy"  class="d-block mt-4 mx-auto" width="160" height="160" *ngIf="products.length==0" src="assets/img/reload.gif" alt="loader horse"> -->

<carousel [lightDOM]="'true'" [height]="400" [cellWidth]="290" [dots]="false" [arrows]="true" [arrowsOutside]="false"
  [loop]="true" class="mt-5 couselx" *ngIf="showcarousel">

  <ng-container *ngIf="products.length==0">
    <div class="carousel-cell pt-2" *ngFor="let item of fakelist">
      <a class="d-flex flex-column card-s" style="border:none !important">
        <div class="placeholderimg"></div>
        <div class="prod-desc bg-white">
          <span class="placeholder sml mt-1 mb-2"></span>
          <span class="placeholder" style="margin-bottom: 37px"></span>
          <div class="d-flex justify-content-between">
            <span class="placeholder col-5"></span>
            <span class="placeholder col-5"></span>
          </div>
        </div>
      </a>
    </div>
  </ng-container>

  <div class="carousel-cell pt-2" *ngFor="let item of products">
    <a class="d-flex flex-column card-s mr-3"
      routerLink="{{(service.currentLang=='en'?'/en':'') + '/prodotto/' + item.Id}}" routerLinkActive="active" ariaCurrentWhenActive="page">
      <div class="ribbon" *ngIf="item.Discount!=null && item.Discount!=0"><span>{{item.Discount}}{{item.IsPercentage==true?'%':'€'}}
        <small>{{ "CategorieDettagli.section1.discount" | translate}}</small></span></div>
      <!-- <img  loading="lazy"  src="assets/img/b.png" class="imgb"> -->
      <img src="assets/img/new.svg" class="imgnew" alt="new" *ngIf="item?.NewWeb==true">
      <img loading="lazy" src="{{item?.urlImages[0]}}" *ngIf="item.urlImages?.length!=0 && item.urlImages!=null" alt="{{item.Title}}">
      <img loading="lazy" src="assets/no-image.png" *ngIf="!item.urlImages || item.urlImages?.length==0 || item.urlImages==null" alt="blacksmith italia">
      <div class="prod-desc">
        <span class="cat">{{item.Code}}</span>
        <span class="desc">{{item.Title}}</span>
        <div class="mt-auto m-price">
          <h5 *ngIf="!item.StopSellWeb">{{'CategorieDettagli.section1.da' | translate}}
             {{item.Price | currency : "EUR" : "symbol" : "1.2-2" : "it" }}
             <small class="soldp" *ngIf="item.Discount!=null && item.Discount!=0">{{item.OriginalPrice | currency : "EUR" : "symbol" :
              "1.2-2" : "it" }}</small>
             <span class="ml-3 text-nowrap">{{'CategorieDettagli.section1.iva' | translate}}</span></h5>
             <h5 *ngIf="item.StopSellWeb">
              {{'ProdDet.section1.richiediInformazioni' | translate}}
            </h5>
        </div>
      </div>
      <div class="shop-btns">
        <button>
          +
        </button>
      </div>
    </a>
  </div>
</carousel>
