import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CondizioniDiSpedizioneComponent } from './condizioni-di-spedizione.component';
import { CondizioniRoutingModule } from './condizioni-routing.module';

@NgModule({
  declarations: [
    CondizioniDiSpedizioneComponent
  ],
  imports: [
    TranslateModule,
    CommonModule,
    CondizioniRoutingModule,
  ],
})
export class CondizioniModule { }
