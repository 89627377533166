import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CarrelloRoutingModule } from './carrello-routing.module';
import { CarrelloComponent } from './carrello.component';

@NgModule({
  declarations: [
    CarrelloComponent
  ],
  imports: [
    TranslateModule,
    CommonModule,
    CarrelloRoutingModule,
  ],
})
export class CarrelloModule { }
