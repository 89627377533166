import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { PagamentoComponent } from './pagamento.component';
import { PagamentoRoutingModule } from './pagamento-routing.module';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatStepperModule} from '@angular/material/stepper';
import { IvyCarouselModule } from 'angular-responsive-carousel';

@NgModule({
  declarations: [
    PagamentoComponent
  ],
  imports: [
    TranslateModule,
    CommonModule,
    PagamentoRoutingModule,
    NgxIntlTelInputModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatCheckboxModule,
    MatStepperModule,
    IvyCarouselModule,
  ],
})
export class PagamentoModule { }
