<section style="min-height: 75vh;padding: 30px 0;">
  <div class="container" style="max-width: 1400px;">
    <div class="row">
      <!-- detail news  -->
      <div class="col-lg-12">
        <h1 class="title mb-4">News</h1>
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center menu-cat mb-2" routerLink="/news" style="cursor:pointer">
            <img src="assets/img/arrow-l.svg" class="mr-2" alt="arrow">
            <p class="back mb-0">{{'ProdDet.section1.back' | translate}}</p>
          </div>
          <ul class="d-flex mb-3 justify-content-end">
            <li class="mr-2">
              <a (click)="social('facebook')"><img src="assets/img/facebook.svg" alt="facebook" width="32"></a>
            </li>
            <!-- <li class="mr-2">
              <a (click)="social('pinterest')"><img src="assets/img/pinterest.svg" alt="pinterest" width="32"></a>
            </li> -->
            <li class="mr-2">
              <a (click)="social('twitter')"><img src="assets/img/twitter.svg" alt="twitter" width="32"></a>
            </li>
            <li class="mr-2">
              <a (click)="social('linkedin')"><img src="assets/img/linkedin.svg" alt="linkedin" width="32"></a>
            </li>
          </ul>
        </div>
        <div class="row">
          <div class="col-lg-12 blog">
            <div class="row">
              <div class="col-lg-6">
                <h5 class="font-weight-normal my-4">{{detail[0]?.Blog.DtInsert | date}}</h5>
                <h2 class="mb-3" style="font-weight: 600;">{{detail[0]?.Blog.Title}}</h2>
                <p> {{detail[0]?.Blog.ShortDescription}}</p>
              </div>
              <div class="col-lg-6">
                <img src="{{detail[0]?.Blog.Base64}}" class="img-fluid img-blog my-3">
              </div>
            </div>

            <p class="my-4" [innerHTML]="detail[0]?.Blog.Body"></p>
            <div *ngIf="detail[0]?.Attachments.length!=0">
              <div *ngFor="let item of detail[0]?.Attachments">
                <img [src]="item.Base64" [alt]="item.Caption"
                  *ngIf="item.FileExt=='.jpg' ||item.FileExt=='.jpeg' ||item.FileExt=='.png' ">
              </div>
            </div>
            <div *ngIf="detail[0]?.Attachments.length!=0">
              <ng-container *ngFor="let item of detail[0]?.Attachments">
                <div class="d-flex attach align-items-center my-2"
                  *ngIf="item.FileExt!='.jpg' && item.FileExt!='.jpeg' &&item.FileExt!='.png' ">
                  <a href="{{item.Link}}" target="_blank">{{item.Caption}}</a>
                  <span>{{item.Link}}</span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <!-- <div class="d-flex flex-wrap justify-content-center justify-content-md-between align-items-center main-f mt-3"
          *ngIf="detail[0]?.Blog.IdNext!=0">
          <button class="btn btn-w w-auto p-0 mr-auto" *ngIf="detail[0]?.Blog.IdPrevious"
            routerLink="/news/{{detail[0]?.Blog.IdPrevious}}"> <img loading="lazy" src="assets/img/arrow-l.svg"
              alt="arrow" height="18" width="19" class="my-0 m-2"></button>
          <button class="btn btn-w w-auto p-0 ml-auto" *ngIf="detail[0]?.Blog.IdNext"
            routerLink="/news/{{detail[0]?.Blog.IdNext}}"><img loading="lazy" src="assets/img/arrow-r.svg" height="18"
              width="19" class="my-0 m-2"></button>
        </div> -->
      </div>
    </div>
  </div>
</section>
