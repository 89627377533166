import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


  name = '';
  email = '';
  adress = '';
  tel = '';
  citta = '';
  provincia = '';
  nazione = '';
  cat = '';
  msg = '';

  startSending: boolean = false;
  success: boolean = false;
  start: boolean = false;

  ClientEmail = 'info@blacksmithitalia.it';

  sendEmailWithCode() {
    this.start = true;

    if (this.name != '' && this.email != '' && this.adress != '' && this.tel != '' && this.citta != '' && this.provincia != '' && this.nazione != '' && this.cat != '' && this.msg != '') {
      this.startSending = true;
      let to = "Client <" + this.ClientEmail + ">";
      fetch('https://api.smtp2go.com/v3/email/send', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "api_key": "api-BF1DFABC6F1211ED8224F23C91BBF4A0",
          "to": [to],
          "sender": "<info@blacksmithitalia.it>",
          "subject": "Richiesta informazioni da pagina CONTATTI",
          // "text_body": "<b>Nome & cognome:</b>" + this.name + "<br>Email:" + this.email,
          "html_body": "<b>Nome & cognome:</b>" + this.name
            + "<br><b>Email</b>:" + this.email
            + "<br><b>Telefono</b>:" + this.tel
            + "<br><b>Indirizzo</b>:" + this.adress
            + "<br><b>Città</b>:" + this.citta
            + "<br><b>Provincia</b>:" + this.provincia
            + "<br><b>Nazione</b>:" + this.nazione
            + "<br><b>Categoria di interesse</b>:" + this.cat
            + "<br>" + this.msg

        })
      }).then(function (response) {
        return response.json();
      })
        .then((data) => {
          console.log(data);
          setTimeout(() => {
            this.startSending = false;
            if (data.data.succeeded == 1) {
              this.success = true;
            }
          }, 500)
        }
        );
    }else{
      console.log('compila ...');
    }
  }

}
