<section style="min-height: 75vh;padding: 30px 0;">
  <div class="container">
    <div class="row">
      <!-- news -->
      <div class="col-lg-12" *ngIf="idBlog==null">
        <h1 class="title mb-4">News</h1>
        <div class="d-flex flex-wrap align-items-center main-f mb-3"
          (mouseleave)="searchValue==''?searchActivated=false:''">
          <span class="result mxw mr-auto my-3 my-md-0 ml-0 ml-md-2"><b>{{totalblogs}}</b> {{ "CategorieDettagli.section1.result" | translate
            }} </span>

          <div class="container-search mr-0 mr-md-3 d-none my-3 d-xl-block" (click)="searchActivated=true"
            [ngClass]="{'active': searchActivated==true}">
            <input type="text" placeholder="Search..." [(ngModel)]="searchValue" (keyup.enter)="GetTotalblogs()">
            <span class="search" (click)="GetTotalblogs();"></span>
          </div>

          <select class="w-auto mb-0 mr-0 mr-md-2 mb-3 my-lg-0" [(ngModel)]="blogCat" (change)="GetTotalblogs()">
            <option value="">{{"news.allcategories" | translate}}</option>
            <option value="{{item.Id}}" *ngFor="let item of Categoriesblogs">{{item.Description}}</option>
          </select>

          <select class="w-auto mb-0 mr-0 mr-md-2 mb-3 my-lg-0" [(ngModel)]="yearSelected"
            (ngModelChange)="GetTotalblogs()">
            <option value="">{{"news.allyears" | translate}}</option>
            <option value="{{currentYear}}">{{currentYear}}</option>
            <option value="{{currentYear - 1}}">{{currentYear - 1}}</option>
          </select>

          <select class="w-auto mb-0 mr-0 mr-md-2 mb-3 my-lg-0" [(ngModel)]="dateFiltred"
            (ngModelChange)="GetTotalblogs()">
            <option value="asc">{{ "news.DateCRESCENTE" | translate }}</option>
            <option value="desc">{{ "news.DateDECRESCENTE" | translate }}</option>
          </select>
        </div>
        <div class="d-flex flex-wrap align-items-start">
          <article class="mr-3 mb-3" *ngFor="let blog of Listnews">
            <img src="{{blog.Base64}}" class="img-fluid" *ngIf="blog.Base64!=null">
            <img src="assets/no-image.png" class="img-fluid" *ngIf="blog.Base64==null">
            <div class="post-content">
              <h5 class="datee">{{blog.DtInsert | date}}</h5>
              <h2>{{blog.Title}}</h2>
              <p> {{blog.ShortDescription}}</p>
              <div class="d-flex mt-3 justify-content-between align-items-center">
                <button routerLink="/news/{{blog.Id}}">{{'news.leggitutto' | translate}}</button>
                <!-- <a href=""><img src="assets/img/share.svg" alt="share" style="width: 25px"></a> -->
              </div>
            </div>
            <div class="clearfix"></div>
          </article>
        </div>
        <div class="d-flex flex-wrap justify-content-center justify-content-md-between align-items-center main-f mt-3"
          *ngIf="Listnews.length!=0 && pageLimit>1">
          <select [(ngModel)]="pageSize" (change)="changeSizePage(pageSize)" style="width: 216px !important"
            class="mb-0 mr-2 mr-md-4">
            <option value="8">{{'CategorieDettagli.section1.elvis' | translate}} 8</option>
            <option value="16" *ngIf="16<totalblogs">{{'CategorieDettagli.section1.elvis' | translate}} 16</option>
            <option value="24" *ngIf="24<totalblogs">{{'CategorieDettagli.section1.elvis' | translate}} 24</option>
            <option value="32" *ngIf="32<totalblogs">{{'CategorieDettagli.section1.elvis' | translate}} 32</option>
            <option value="500">{{ "CategorieDettagli.section1.tutti" | translate }}</option>
          </select>
          <div class="pagination mt-2 mt-lg-0">
            <button class="btn btn-w w-auto p-0 ml-0 ml-md-4" (click)="previousPage()"> <img loading="lazy"
                src="assets/img/arrow-l.svg" alt="arrow" height="18" width="19" class="my-0 m-2"></button>
            <span><b>{{ "CategorieDettagli.section1.pag" | translate }}</b> {{pageIndex}} di {{pageLimit}}</span>
            <button class="btn btn-w w-auto p-0 ml-2" (click)="nextPage()"><img loading="lazy"
                src="assets/img/arrow-r.svg" height="18" width="19" class="my-0 m-2"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
