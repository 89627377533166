<div class="result-page">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 offset-lg-3">
        <div class="main-carrello">
          <h4 class="title">{{ "ResultOrder.section1.titlesuccess" | translate }}</h4>

          <p class="my-4" *ngIf="estero=='false'">{{ "ResultOrder.section1.textsuccess" | translate }}</p>
          <p *ngIf="bonifico=='true' && estero=='false'">{{"ResultOrder.section1.textOrder" | translate}}<br><br>
            <b style="font-weight: 500 !important;">
              BCC di MILANO Società Cooperativa - ITALIA<br>
              IBAN:IT79J0845353450000000200925<br>
              BIC: ICRAITRRCL0<br>
              Beneficiario: BLACKSMITH SRL
            </b>
          </p>

          <p *ngIf="estero=='true'" style="color: red">
            {{"ResultOrder.section1.textEstero1" | translate}}<br>
            {{"ResultOrder.section1.textEstero2" | translate}}<br>
            {{"ResultOrder.section1.textEstero3" | translate}}
          </p>

          <a class="btn btn-bg" routerLink="/">{{ "ResultOrder.section1.btn" | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</div>

