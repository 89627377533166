import { Component, Input, OnInit,OnDestroy, PLATFORM_ID,Inject } from '@angular/core';
import { ServiceService } from '../services/service.service';

import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent {
  constructor(public service: ServiceService,@Inject(PLATFORM_ID) private platformId: Object) { }

  resquest;

  showcarousel: boolean = false;

  @Input()
  get Id(): number { return this.IDCategory; }
  set Id(Id: number) {
    if (isPlatformBrowser(this.platformId)) {
      this.showcarousel=true;
    }
    this.IDCategory = Id;
    this.resquest = this.service.getProducts(this.IDCategory,0,12,'','null',null,false,false,0).pipe(
      catchError(error => {
        return this.service.LogErrors(error.name + ': ' + error.message + ' (' + error.url + ')');
      })
    ).subscribe(
      products => {
        this.products = products;
      });
  }

  @Input()
  get nameCatv(): string { return this.nameCat; }
  set nameCatv(nameCatv: string) {
    this.nameCat = nameCatv;
  }

  fakelist:any[] = new Array(12);

  IDCategory = 0;
  nameCat;
  products:any=[];


  ngOnDestroy(){
    this.resquest?.unsubscribe();
  }

}
