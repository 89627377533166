<section>
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <h1 class="title">{{'Contact.section1.title' | translate}}</h1>
        <p class="my-4"><b>{{'Contact.section1.text' | translate}}</b></p>
        <p class="mb-3">{{'Contact.section1.text2' | translate}}</p>
        <p>
          <b class="list-a">
            <a href="tel:+390354820791">Tel.: <b>+39 035/4820791</b></a>
            <a href="tel:+390354191647">Fax: +39 035/4191647</a>
            <a href="mailto:info@blacksmithitalia.it">Mail: info@blacksmithitalia.it </a>
            <a href="mailto:blackh@pec.blacksmithsnc.com">PEC: blackh@pec.blacksmithsnc.com</a>
          </b>
        </p>
      </div>
      <div class="col-lg-6">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11167.911124648354!2d9.6118375!3d45.5909914!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x293e959ea9a01a92!2sBlacksmith%20S.R.L.!5e0!3m2!1sit!2sit!4v1669367747814!5m2!1sit!2sit"
          width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  </div>
</section>

<section class="bg-sec">
  <div class="container">
    <div class="row"  *ngIf="startSending==false && success==false">
      <div class="col-lg-12  text-center">
        <h3 class="title  text-center">{{'Contact.section2.title' | translate}}</h3>
        <p class="mt-4"><b>{{'Contact.section2.text' | translate}}</b></p>
      </div>
      <div class="col-lg-8 offset-lg-2 mt-4">
        <div class="row">
          <div class="col-lg-6">
            <label>{{'Contact.section2.nome-cognome' | translate}}</label>
            <input type="text" placeholder="{{'Contact.section2.nome-cognome' | translate}}" [(ngModel)]="name" [ngClass]="{'req': name==''&&start==true}">
          </div>
          <div class="col-lg-6">
            <label>{{'Contact.section2.email' | translate}}</label>
            <input type="text" placeholder="{{'Contact.section2.email' | translate}}" [(ngModel)]="email" [ngClass]="{'req': email==''&&start==true}">
          </div>
          <div class="col-lg-6">
            <label>{{'Contact.section2.Telefono' | translate}}</label>
            <input type="text" placeholder="{{'Contact.section2.Telefono' | translate}}" [(ngModel)]="tel" [ngClass]="{'req': tel==''&&start==true}">
          </div>
          <div class="col-lg-6">
            <label>{{'Contact.section2.Indirizzo' | translate}}</label>
            <input type="text" placeholder="{{'Contact.section2.Indirizzo' | translate}}" [(ngModel)]="adress" [ngClass]="{'req': adress==''&&start==true}">
          </div>
          <div class="col-lg-6">
            <label>{{'Contact.section2.Città' | translate}}</label>
            <input type="text" placeholder="{{'Contact.section2.Città' | translate}}" [(ngModel)]="citta" [ngClass]="{'req': citta==''&&start==true}">
          </div>
          <div class="col-lg-6">
            <label>{{'Contact.section2.Provincia' | translate}}</label>
            <input type="text" placeholder="{{'Contact.section2.Provincia' | translate}}" [(ngModel)]="provincia" [ngClass]="{'req': provincia==''&&start==true}">
          </div>
          <div class="col-lg-6">
            <label>{{'Contact.section2.Nazione' | translate}}</label>
            <input type="text" placeholder="{{'Contact.section2.Nazione' | translate}}" [(ngModel)]="nazione" [ngClass]="{'req': nazione==''&&start==true}">
          </div>
          <div class="col-lg-6">
            <label>{{'Contact.section2.Categoriadiinteresse' | translate}}</label>
            <select [(ngModel)]="cat" placeholder="{{'Contact.section2.Categoriadiinteresse' | translate}}"  [ngClass]="{'req': cat==''&&start==true}">
              <option value="">{{'Contact.section2.Categoriadiinteresse' | translate}}</option>
              <option value="Attrezzatura"> {{'Contact.section2.Attrezzatura' | translate}} </option>
              <option value="Chiodi per ferrare"> {{'Contact.section2.Chiodi' | translate}}</option>
              <option value="Comfort">{{'Contact.section2.Comfort' | translate}}  </option>
              <option value="Cura dello zoccolo">{{'Contact.section2.Cura' | translate}}  </option>
              <option value="Ferri da cavallo"> {{'Contact.section2.Ferri' | translate}} </option>
              <option value="Macchine &amp; utensili">{{'Contact.section2.Macchine' | translate}}</option>
              <option value="Raspe Lime &amp; Accessori"> {{'Contact.section2.Raspe' | translate}} </option>
              <option value="Sicurezza">{{'Contact.section2.Sicurezza' | translate}}   </option>
            </select>
          </div>
          <div class="col-lg-12">
            <label>Scrivi qui la richiesta</label>
            <textarea name="" id="" cols="30" rows="4" placeholder="Scrivi qui la richiesta" [ngClass]="{'req': msg==''&&start==true}"
              [(ngModel)]="msg"></textarea>
          </div>
          <div class="col-lg-12">
            <button class="btn btn-bg mt-4 mx-auto mb-5" style="max-width: 480px" (click)="sendEmailWithCode()">{{'Contact.section2.richiesta' | translate}}  </button>
          </div>
        </div>
      </div>
    </div>
    <img  class="d-block mt-4 mx-auto" width="160" height="160" *ngIf="startSending" src="assets/img/reload.gif" alt="loader horse">
    <div class="row" *ngIf="success==true">
      <div class="card-s col-lg-8 offset-lg-2 mt-5 bg-white p-5 text-center">
        <h3 class="title text-center">{{'Contact.result.title' | translate}}</h3>
        <p class="text-fake-black my-4 col-lg-10 offset-lg-1"><b>{{'Contact.result.text' | translate}}</b></p>
        <a routerLink="/home" class="btn btn-bg">{{'Contact.result.backhome' | translate}}</a>
      </div>
    </div>
  </div>
</section>
