<section class="bg-section-grey" style="min-height: 75vh">
  <div class="container">
    <div class="row">
      <div class="col-lg-3">
        <ul class="list-group">
          <li class="list-group-item" routerLink="/termini-condizioni">{{'CondizioniSped.menu.m1' | translate}}</li>
          <li class="list-group-item" routerLink="/modalita-di-pagamento"> {{'CondizioniSped.menu.m2' | translate}}</li>
          <li class="list-group-item active" routerLink="/condizioni-di-spedizione"> {{'CondizioniSped.menu.m3' | translate}}</li>
          <li class="list-group-item" routerLink="/reso-prodotti">{{'CondizioniSped.menu.m4' | translate}} </li>
        </ul>
      </div>
      <div class="col-lg-9">
        <div class="main-carrello">
          <h1 class="title mb-4">{{'CondizioniSped.condsped.title' | translate}}</h1>

          <p>
            {{'CondizioniSped.condsped.text' | translate}}<br>
            {{'CondizioniSped.condsped.text2' | translate}}<br>
            {{'CondizioniSped.condsped.text3' | translate}}<br>
            {{'CondizioniSped.condsped.text4' | translate}}<br><br>
            {{'CondizioniSped.condsped.text5' | translate}}<br><br>
            {{'CondizioniSped.condsped.text6' | translate}}
          </p>

        </div>
      </div>
    </div>
  </div>
</section>
