import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CookiePolicyComponent } from './cookie-policy.component';
import { CookieRoutingModule } from './cookie-routing.module';

@NgModule({
  declarations: [
    CookiePolicyComponent
  ],
  imports: [
    TranslateModule,
    CommonModule,
    CookieRoutingModule,
  ],
})
export class CookieModule { }
