import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ResoRoutingModule } from './reso-routing.module';
import { ResoProdottiComponent } from './reso-prodotti.component';

@NgModule({
  declarations: [
    ResoProdottiComponent
  ],
  imports: [
    TranslateModule,
    CommonModule,
    ResoRoutingModule,
  ],
})
export class ResoModule { }
