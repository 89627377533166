import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-condizioni-di-spedizione',
  templateUrl: './condizioni-di-spedizione.component.html',
  styleUrls: ['./condizioni-di-spedizione.component.css']
})
export class CondizioniDiSpedizioneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
